import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allAuthSources: [] as any,
  AuthenticationSource: [] as any,
  ShopifyNonPlusConfiguration: {
    AuthenticationSource: [] as any,
    BasicSettings: [] as any,
    PolicySettings: [] as any,
    UserGroupSettings: [] as any,
  },
  ShopifyPlusConfiguration: {
    AuthenticationSource: [] as any,
    BasicSettings: [] as any,
    PolicySettings: [] as any,
    GroupSettings: [] as any,
    requiredFieldMessage: '',
  },
  AtlassianConfiguration: {
    AuthenticationSource: [] as any,
    BasicSettings: [] as any,
    PolicySettings: [] as any,
    GroupSettings: [] as any,
    requiredFieldMessage: '',
  },
  Office365Configuration: {
    AuthenticationSource: [] as any,
    BasicSettings: [] as any,
    PolicySettings: [] as any,
    GroupSettings: [] as any,
    requiredFieldMessage: '',
  },
  BasicSettings: [] as any,
  PolicySettings: [] as any,
  GroupSettings: [] as any,
  requiredFieldMessage: '',
};

export const CreateAuthSource: any = createSlice({
  name: 'Create Authentication Source',
  initialState,
  reducers: {
    addAuthSource: (state, action) => {
      const details = {
        data: action.payload,
      };
      state.AuthenticationSource = details;
    },
    allAuthSource: (state, action) => {
      state.allAuthSources = action.payload;
    },
    updateGoogleBasicSettingsStore: (state, action) => {
      const data = action.payload;
      state.BasicSettings = data;
    },
    updateGooglePolicySettingsStore: (state, action) => {
      const data = action.payload;
      state.PolicySettings = data;
    },
    updateGoogleGroupSettingsStore: (state, action) => {
      const data = action.payload;
      state.GroupSettings = data;
    },
    updateOffice365AuthenticationSource: (state, action) => {
      const data = action.payload;
      state.Office365Configuration.AuthenticationSource = data;
    },
    updateshopifyNonPlusConfiguration: (state, action) => {
      const configuration = action.payload;
      if (configuration.type === 'authentication-source') {
        state.ShopifyNonPlusConfiguration.AuthenticationSource = configuration.data;
      } else if (configuration.type === 'basic-settings') {
        state.ShopifyNonPlusConfiguration.BasicSettings = configuration.data;
      } else if (configuration.type === 'policy-settings') {
        state.ShopifyNonPlusConfiguration.PolicySettings = configuration.data;
      } else if (configuration.type === 'user-group-settings') {
        state.ShopifyNonPlusConfiguration.UserGroupSettings = configuration.data;
      }
    },
    updateshopifyPlusConfiguration: (state, action) => {
      const configuration = action.payload;
      if (configuration.type === 'authentication-source') {
        state.ShopifyPlusConfiguration.AuthenticationSource = configuration.data;
      } else if (configuration.type === 'basic-settings') {
        state.ShopifyPlusConfiguration.BasicSettings = configuration.data;
      } else if (configuration.type === 'policy-settings') {
        state.ShopifyPlusConfiguration.PolicySettings = configuration.data;
      } else if (configuration.type === 'group-settings') {
        state.ShopifyPlusConfiguration.GroupSettings = configuration.data;
      }
    },

    updateAtlassianConfiguration: (state, action) => {
      const configuration = action.payload;
      if (configuration.type === 'authentication-source') {
        state.AtlassianConfiguration.AuthenticationSource = configuration.data;
      } else if (configuration.type === 'basic-settings') {
        state.AtlassianConfiguration.BasicSettings = configuration.data;
      } else if (configuration.type === 'policy-settings') {
        state.AtlassianConfiguration.PolicySettings = configuration.data;
      } else if (configuration.type === 'group-settings') {
        state.AtlassianConfiguration.GroupSettings = configuration.data;
      }
    },
    updateOffice365Configuration: (state, action) => {
      const configuration = action.payload;
      if (configuration.type === 'authentication-source') {
        state.Office365Configuration.AuthenticationSource = configuration.data;
      } else if (configuration.type === 'basic-settings') {
        state.Office365Configuration.BasicSettings = configuration.data;
      } else if (configuration.type === 'policy-settings') {
        state.Office365Configuration.PolicySettings = configuration.data;
      } else if (configuration.type === 'group-settings') {
        state.Office365Configuration.GroupSettings = configuration.data;
      }
    },
  },
});

export const {
  addAuthSource,
  allAuthSource,
  updateGoogleBasicSettingsStore,
  updateGooglePolicySettingsStore,
  updateGoogleGroupSettingsStore,
  updateOffice365AuthenticationSource,
  updateshopifyNonPlusConfiguration,
  updateshopifyPlusConfiguration,
  updateAtlassianConfiguration,
  updateOffice365Configuration,
} = CreateAuthSource.actions;

export default CreateAuthSource.reducer;
