import React from 'react';
import { Box, Typography } from '@mui/material';

interface CircularIconProps{
  number: number;
  step: number;
}
function CircularIcon({ number, step }:CircularIconProps) {
  return (
    <Box display="flex" width="1.5rem" height="1.5rem" sx={{ backgroundColor: step === number ? '#4F46F3' : '#D3D3D3' }} borderRadius="50%" alignItems="center" justifyContent="center">
      <Typography variant="subtitle2" color="white">
        {number}
      </Typography>
    </Box>
  );
}

export default CircularIcon;
