import React, { useEffect } from 'react';
import {
  Box, Typography, TextField, MenuItem, Divider,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { getCookie } from '../../../utils/utility';
import ErrorMessage from '../../../components/ErrorMessage';
import RenderAtlassianApps from '../../../components/CASBApp/Atlassian/RenderAtlassianApps';
import { updateAtlassianConfiguration } from '../../../store/features/CreateAuthSourceSlice';

interface ManageGroupProps {
  errorMessage: string;
}

interface GroupSettingsFormProps {
  errorMessage: string;
  groupSettings: any;
  setGroupSettings: any;
  allPolicies: any;
}
export function GroupSettingsForm({
  errorMessage,
  allPolicies,
  groupSettings,
  setGroupSettings,
}: GroupSettingsFormProps) {
  const dispatch = useDispatch();
  const handleGroupSettings = (event: any) => {
    const { name, value } = event.target;
    setGroupSettings((prevValue: any) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleAppRestriction = (event: SelectChangeEvent) => {
    setGroupSettings((prevValue: any) => ({
      ...prevValue,
      groupPolicy: event.target.value,
    }));
  };

  useEffect(() => {
    function updateGroupDetails() {
      dispatch(
        updateAtlassianConfiguration({
          type: 'group-settings',
          data: groupSettings,
        }),
      );
    }
    updateGroupDetails();
  }, [groupSettings]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="60%"
      px={6}
      py={3}
      gap="1rem"
    >
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <TextField
        id="outlined-basic"
        size="small"
        label="Group Name"
        name="groupName"
        variant="outlined"
        sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
        inputProps={{
          style: {
            fontSize: '15px',
          },
        }}
        onChange={handleGroupSettings}
        value={groupSettings.groupName || ''}
      />
      <TextField
        id="outlined-basic"
        size="small"
        label="Group Description"
        name="groupDescription"
        variant="outlined"
        sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
        inputProps={{
          style: {
            fontSize: '15px',
          },
        }}
        onChange={handleGroupSettings}
        value={groupSettings.groupDescription || ''}
      />
      <FormControl>
        <Typography variant="caption">
          Please select Atlassian Plus Policy
        </Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          fullWidth
          sx={{ height: '2.2rem' }}
          onChange={handleAppRestriction}
          value={groupSettings?.groupPolicy}
        >
          {allPolicies?.map((source: any) => (
            <MenuItem value={source?._id}>{source?.identifier}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

function ManageGroup({ errorMessage }: ManageGroupProps) {
  const token = getCookie('token');
  const [allPolicies, setAllPolicies] = React.useState([]);
  const [groupSettings, setGroupSettings] = React.useState({
    groupName: null,
    groupDescription: null,
    groupPolicy: '',
    allowedApps: [] as string[],
    restrictedApps: [] as string[],
    blockApps: [] as string[],
    atlassianAppPolicy: [] as any[],
    granular_restriction: {
      confluence: {
        spaces: {
          parentAccess: true,
          list: [] as any,
        },
      },
      jira: {
        projects: {
          parentAccess: true,
          boards: [] as any,
        },
      },
    },
  });

  useEffect(() => {
    async function getAllPolicies() {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-policies`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((response: any) => {
          if (response && response.data && response.data.PolicyData) {
            setAllPolicies(response.data.PolicyData);
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_ALL_POLICIES_ERROR);
        });
    }
    getAllPolicies();
  }, []);

  return (
    <Box height="67vh" sx={{ overflowY: 'scroll', overflowX: 'hidden' }}>
      <Box display="flex" flexDirection="row">
        <Box width="40%" display="flex" py={6} px={6} flexDirection="column">
          <Typography variant="h5">Group</Typography>
          <br />
          <Typography variant="subtitle2" color="#56526B">
            Group Settings for Atlassian Plus Proxy
          </Typography>
        </Box>
        <GroupSettingsForm
          allPolicies={allPolicies}
          groupSettings={groupSettings}
          setGroupSettings={setGroupSettings}
          errorMessage={errorMessage}
        />
      </Box>
      <Divider />
      <Box px={2} height="100%">
        <RenderAtlassianApps
          allPolicies={allPolicies}
          groupSettings={groupSettings}
          setGroupSettings={setGroupSettings}
        />
      </Box>
    </Box>
  );
}

export default ManageGroup;
