import React from 'react';
import { useDispatch } from 'react-redux';
import AuthenticationSourceCommon from '../../../components/CASBApp/AuthenticationSource';
import { updateshopifyPlusConfiguration } from '../../../store/features/CreateAuthSourceSlice';

interface ShopifyPlusSourceProps {
  errorMessage: string;
}

function ShopifyPlusAuthenticationSource({
  errorMessage,
}: ShopifyPlusSourceProps) {
  const dispatch = useDispatch();
  return (
    <AuthenticationSourceCommon
      errorMessage={errorMessage}
      updateConfiguration={(config: any) => dispatch(
        updateshopifyPlusConfiguration({
          type: 'authentication-source',
          data: config,
        }),
      )}
      appType={process.env.REACT_APP_SHOPIFY_PLUS_KEY}
    />
  );
}

export default ShopifyPlusAuthenticationSource;
