import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box, Typography, TextField, FormControl,
} from '@mui/material';
import {
  getDraftProxyApplication,
  isValidURL,
  isValidDomain,
} from '../../../utils/utility';
import ErrorMessage from '../../../components/ErrorMessage';
import { updateGoogleBasicSettingsStore } from '../../../store/features/CreateAuthSourceSlice';
import '../index.css';

interface BasicSettingsType {
  organizationName: null | string;
  organizationDomain: null | string;
  attributeKey: null | string;
  nameAttributeKey: null | string;
  googleACSUrl: null | string;
  googleEntityUrl: null | string;
}

interface BasicSettingsProps {
  errorMessage: string;
  setBasicSettingsError: any;
}

function BasicSettings({
  errorMessage,
  setBasicSettingsError,
}: BasicSettingsProps) {
  const dispatch = useDispatch();
  const InputFields = [
    'Organization Name*',
    'Organization Domain*',
    'Group Attribute Key*',
    'Name Attribute Key*',
    'Google ACS URL*',
    'Google Entity URL*',
  ];
  const InputFieldsName = [
    'organizationName',
    'organizationDomain',
    'attributeKey',
    'nameAttributeKey',
    'googleACSUrl',
    'googleEntityUrl',
  ];
  const [basicSettings, setBasicSettings] = useState<BasicSettingsType>({
    organizationName: null,
    organizationDomain: null,
    attributeKey: null,
    nameAttributeKey: null,
    googleACSUrl: null,
    googleEntityUrl: null,
  });
  const [inputErrorMessage, setInputErrorMessage] = useState<BasicSettingsType>(
    {
      organizationName: null,
      organizationDomain: null,
      attributeKey: null,
      nameAttributeKey: null,
      googleACSUrl: null,
      googleEntityUrl: null,
    },
  );
  const handleBasicSettingsInput = (event: any) => {
    var { value } = event.target;
    event.preventDefault();
    setBasicSettingsError('');
    const { name } = event.target;
    value = value?.trim();
    switch (name) {
      case 'organizationDomain':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          organizationDomain: !isValidDomain(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      case 'googleACSUrl':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          googleACSUrl: !isValidURL(value)
            ? String(process.env.REACT_APP_INVALID_URL)
            : null,
        }));
        break;
      case 'googleEntityUrl':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          googleEntityUrl: !isValidURL(value)
            ? String(process.env.REACT_APP_INVALID_URL)
            : null,
        }));
        break;
      default:
        break;
    }
    setBasicSettings((prevValue: BasicSettingsType) => ({
      ...prevValue,
      [name]: value,
    }));
  };
  useEffect(() => {
    async function getDraftedGoogleConfiguration() {
      setBasicSettingsError('');
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_GOOGLE_KEY,
      );
      setBasicSettings({
        organizationName: DraftApplication?.success?.identifier,
        organizationDomain: DraftApplication?.success?.organization_domain,
        attributeKey: DraftApplication?.success?.attribute_key,
        nameAttributeKey: DraftApplication?.success?.name_attribute_key,
        googleACSUrl: DraftApplication?.success?.acs_url,
        googleEntityUrl: DraftApplication?.success?.entity_id_url,
      });
    }
    getDraftedGoogleConfiguration();
  }, []);
  useEffect(() => {
    function updateAuthenticationSourceDetails() {
      dispatch(updateGoogleBasicSettingsStore(basicSettings));
    }
    updateAuthenticationSourceDetails();
  }, [basicSettings]);
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        height="67vh"
        sx={{ overflow: 'scroll' }}
      >
        <Box width="40%" display="flex" py={6} px={6} flexDirection="column">
          <Typography variant="h5">Basic Settings</Typography>
          <br />
          <Typography variant="subtitle2" color="#56526B">
            Configure Google App Basic Settings
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="60%"
          px={6}
          py={5}
          gap="1.5rem"
        >
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {InputFields.map((value, index) => (
            <FormControl>
              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                label={value}
                sx={{
                  backgroundColor:
                    inputErrorMessage[
                      InputFieldsName[index] as keyof BasicSettingsType
                    ] === null
                      ? '#F6F6F6'
                      : '#ff8d0024',
                  border: 'none !important',
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                name={InputFieldsName[index]}
                value={
                  basicSettings[
                    InputFieldsName[index] as keyof BasicSettingsType
                  ] || ''
                }
                onChange={handleBasicSettingsInput}
              />
              <Typography variant="caption" color="red">
                {
                  inputErrorMessage[
                    InputFieldsName[index] as keyof BasicSettingsType
                  ]
                }
              </Typography>
            </FormControl>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default BasicSettings;
