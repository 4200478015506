import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box, Modal, Typography, Button, Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from '../../../utils/utility';
import { GroupSettingsForm } from '../../../pages/Atlassian/components/ManageGroup';
import RenderAtlassianApps from './RenderAtlassianApps';

interface AddGroupModalProps {
  open: boolean;
  setOpen: Function;
  setReloadGroup: Function;
  selectedGroup: any;
  appName: string;
}
function AddGroupModal({
  open,
  setOpen,
  setReloadGroup,
  selectedGroup,
  appName,
}: AddGroupModalProps) {
  const GroupSettingConfiguration = useSelector(
    (state: any) => state.AtlassianConfiguration.GroupSettings,
  );
  const [groupSettingError, setGroupSettingError] = useState('');
  const EmptyFieldError = 'Please fill all required fields.';
  const [groupSettings, setGroupSettings] = React.useState({
    groupName: null,
    groupDescription: null,
    groupPolicy: '',
    allowedApps: [] as string[],
    restrictedApps: [] as string[],
    blockApps: [] as string[],
    atlassianAppPolicy: [] as any[],
    granular_restriction: {
      confluence: {
        spaces: {
          parentAccess: true,
          list: [] as any,
        },
      },
      jira: {
        projects: {
          parentAccess: true,
          boards: [] as any,
        },
      },
    },
  });
  const token = getCookie('token');
  const [allPolicies, setAllPolicies] = React.useState([]);
  useEffect(() => {
    function getAllPolicies() {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-policies`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((response: any) => {
          if (response && response.data && response.data.PolicyData) {
            setAllPolicies(response.data.PolicyData);
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_ALL_POLICIES_ERROR);
        });
    }
    getAllPolicies();
  }, []);

  useEffect(() => {
    function updateSelectedGroup() {
      if (selectedGroup?.length !== 0) {
        console.log(selectedGroup);
        setGroupSettings({
          groupName: selectedGroup?.identifier,
          groupDescription: selectedGroup?.description,
          groupPolicy: selectedGroup?.policy_id,
          allowedApps: selectedGroup?.app_allowed_list,
          restrictedApps: selectedGroup?.app_restriction_list,
          blockApps: selectedGroup?.app_blocked_list,
          atlassianAppPolicy: selectedGroup?.app_custom_restriction_list,
          granular_restriction: {
            confluence: {
              spaces: {
                parentAccess:
                  selectedGroup?.granular_restriction?.confluence?.space_access
                    ?.parent_access,
                list:
                  selectedGroup?.granular_restriction?.confluence?.space_access?.list,
              },
            },
            jira: {
              projects: {
                parentAccess:
                  selectedGroup?.granular_restriction?.jira?.project_access
                    ?.parent_access,
                boards:
                  selectedGroup?.granular_restriction?.jira?.project_access?.boards,
              },
            },
          },
        });
      }
    }
    updateSelectedGroup();
  }, [selectedGroup]);

  useEffect(() => {
    async function getAllAtlassianApps() {
      if (appName !== process.env.REACT_APP_ATLASSIAN_KEY) {
        return;
      }

      const axiosConfig = {
        method: 'GET',
        url: `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-atlassian-apps`,
        params: {
          token,
        },
      };
      axios(axiosConfig)
        .then((response: any) => {
          if (response && response.data && response.data.atlassianApps) {
            const appList: string[] = [];
            response.data.atlassianApps.forEach((app: any) => {
              appList.push(app.appKey);
            });
            setGroupSettings({
              groupName: null,
              groupDescription: null,
              groupPolicy: '',
              allowedApps: [],
              restrictedApps: appList,
              blockApps: [],
              atlassianAppPolicy: [],
              granular_restriction: {
                confluence: {
                  spaces: {
                    parentAccess: true,
                    list: [] as any,
                  },
                },
                jira: {
                  projects: {
                    parentAccess: true,
                    boards: [] as any,
                  },
                },
              },
            });
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_ATLASSIAN_APPS_ERROR);
        });
    }
    getAllAtlassianApps();
  }, []);

  const handleClose = () => {
    setGroupSettings({
      groupName: null,
      groupDescription: null,
      groupPolicy: '',
      allowedApps: [],
      restrictedApps: [],
      blockApps: [],
      atlassianAppPolicy: [],
      granular_restriction: {
        confluence: {
          spaces: {
            parentAccess: true,
            list: [] as any,
          },
        },
        jira: {
          projects: {
            parentAccess: true,
            boards: [] as any,
          },
        },
      },
    });
    setGroupSettingError('');
    setOpen(false);
  };
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 705,
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: 'white',
    color: '#5F636D',
  };
  const updateGroupSetting = async (type: string) => {
    setGroupSettingError('');
    let isConfigInvalid = !GroupSettingConfiguration
      || !GroupSettingConfiguration.groupName
      || !GroupSettingConfiguration.groupDescription
      || !GroupSettingConfiguration.groupPolicy;
    if (appName === process.env.REACT_APP_ATLASSIAN_KEY) {
      const isEmptyNameOrAccessInConfluence = GroupSettingConfiguration.granular_restriction.confluence.spaces.list.some(
        (item: any) => !item.name,
      );
      const isEmptyNameOrAccessInJira = GroupSettingConfiguration.granular_restriction.jira.projects.boards.some(
        (item: any) => !item.name,
      );

      isConfigInvalid = isConfigInvalid
        || !GroupSettingConfiguration.blockApps
        || !GroupSettingConfiguration.allowedApps
        || !GroupSettingConfiguration.restrictedApps
        || isEmptyNameOrAccessInConfluence
        || isEmptyNameOrAccessInJira;

      if (isEmptyNameOrAccessInConfluence || isEmptyNameOrAccessInJira) {
        toast.error('Empty Fields found');
      }
    }

    if (isConfigInvalid) {
      setGroupSettingError(EmptyFieldError);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-group-settings`,
          {
            group_details: GroupSettingConfiguration,
            app: process.env.REACT_APP_ATLASSIAN_KEY,
            _id: selectedGroup?._id,
            type,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          setGroupSettings({
            groupName: null,
            groupDescription: null,
            groupPolicy: '',
            allowedApps: [],
            restrictedApps: [],
            blockApps: [],
            atlassianAppPolicy: [],
            granular_restriction: {
              confluence: {
                spaces: {
                  parentAccess: true,
                  list: [] as any,
                },
              },
              jira: {
                projects: {
                  parentAccess: true,
                  boards: [] as any,
                },
              },
            },
          });
          setOpen(false);
          setReloadGroup(true);
        })
        .catch((error) => {
          const errorMessage = error?.response?.data || error?.response?.data.message;
          setGroupSettingError(errorMessage);
        });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          px="20px"
          py="10px"
          borderBottom="1px solid #D9D9D9"
        >
          <Typography variant="h6">Atlassian Group Settings</Typography>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </Box>
        {groupSettingError && (
          <Typography variant="caption" px="20px" py="10px" color="red">
            {groupSettingError}
          </Typography>
        )}
        <Box display="flex" flexDirection="column" width="100%">
          <GroupSettingsForm
            allPolicies={allPolicies}
            groupSettings={groupSettings}
            setGroupSettings={setGroupSettings}
            errorMessage=""
          />
          {appName === process.env.REACT_APP_ATLASSIAN_KEY && (
            <Box>
              <Divider />
              <Box height={380} sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                <RenderAtlassianApps
                  allPolicies={allPolicies}
                  groupSettings={groupSettings}
                  setGroupSettings={setGroupSettings}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          width="100%"
          px="20px"
          py="10px"
          bottom="0"
          position="absolute"
          sx={{ gap: '2rem' }}
        >
          {selectedGroup.length === 0 ? (
            <Button
              sx={{
                backgroundColor: 'green',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'green',
                  color: 'white',
                },
              }}
              onClick={() => updateGroupSetting('save')}
            >
              Save
            </Button>
          ) : (
            <Button
              sx={{
                backgroundColor: 'green',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'green',
                  color: 'white',
                },
              }}
              onClick={() => updateGroupSetting('update')}
            >
              Update
            </Button>
          )}

          <Button
            sx={{
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                backgroundColor: 'red',
                color: 'white',
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddGroupModal;
