import React from 'react';
import axios from 'axios';
import {
  Box, Modal, Typography, Button,
} from '@mui/material';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { getCookie } from '../utils/utility';

interface DeletePromptModalProps {
  open: boolean;
  setOpen: Function;
  identifier: string;
}

function DeletePromptModal({
  open,
  setOpen,
  identifier,
}: DeletePromptModalProps) {
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    color: '#5F636D',
  };
  const deleteAuthenticationSource = async () => {
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/delete-authentication-source`,
        {
          identifier,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        setOpen(false);
        toast.error('Deleted Authentication Source');
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_DELETE_AUTHENTICATION_SOURCE_ERROR);
      });
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          sx={{ backgroundColor: 'white' }}
          px="20px"
          py="10px"
        >
          <Typography variant="h6">Delete Authentication Source</Typography>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          p="20px"
          width="100%"
          sx={{ backgroundColor: 'white' }}
          gap="1rem"
        >
          <Typography variant="subtitle2">
            Are you sure you want to delete selected Authentication Source?
          </Typography>
          <Box display="flex" gap="1rem">
            <Button
              sx={{ color: 'white', backgroundColor: 'red', width: 100 }}
              onClick={deleteAuthenticationSource}
            >
              Delete
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default DeletePromptModal;
