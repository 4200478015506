import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Divider,
  Grid,
  Paper,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
// import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import SearchIcon from '@mui/icons-material/Search';
import { getCookie } from '../../../utils/utility';
import ErrorMessage from '../../../components/ErrorMessage';
import { updateGoogleGroupSettingsStore } from '../../../store/features/CreateAuthSourceSlice';
import '../index.css';

interface ManageGroupProps {
  errorMessage: string;
}

interface GroupSettingsFormProps {
  errorMessage: string;
  groupSettings: any;
  setGroupSettings: any;
  allPolicies: any;
  selectedGroup: any;
}

interface GoogleAppsProps {
  groupSettings: any;
  setGroupSettings: any;
  allPolicies: any;
}

const Item = styled(Paper)(({ theme }: any) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

export function GroupSettingsForm({
  errorMessage,
  allPolicies,
  groupSettings,
  setGroupSettings,
  selectedGroup,
}: GroupSettingsFormProps) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const handleGroupSettings = (event: any) => {
    var value = '';
    event.preventDefault();
    const { name } = event.target;
    value = event.target.value;
    value = name === 'groupName' ? value?.trim() : value;
    setGroupSettings((prevValue: any) => ({
      ...prevValue,
      [name]: value,
    }));
  };
  const handlePolicySelection = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setGroupSettings((prevValue: any) => ({
      ...prevValue,
      groupPolicy: value,
    }));
  };
  useEffect(() => {
    async function GroupSettings() {
      if (selectedGroup.length !== 0) {
        setGroupSettings({
          groupName: selectedGroup?.identifier,
          groupDescription: selectedGroup?.description,
          groupPolicy: selectedGroup?.policy_id,
          allowedApps: selectedGroup?.app_allowed_list,
          restrictedApps: selectedGroup?.app_restriction_list,
          blockApps: selectedGroup?.app_blocked_list,
          googleAppPolicy: selectedGroup?.app_custom_restriction_list,
        });
      } else {
        const token = await getCookie('token');
        const axiosConfig = {
          method: 'GET',
          url: `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-google-apps`,
          params: {
            token,
          },
        };
        axios(axiosConfig)
          .then((response: any) => {
            if (response && response.data && response.data.googleApps) {
              const appList: string[] = [];
              response.data.googleApps.forEach((app: any) => {
                appList.push(app.appKey);
              });
              setGroupSettings({
                groupName: null,
                groupDescription: null,
                groupPolicy: '',
                allowedApps: [],
                restrictedApps: appList,
                blockApps: [],
                googleAppPolicy: [],
              });
            }
          })
          .catch(() => {
            toast.error(process.env.REACT_APP_GET_GOOGLE_APPS_ERROR);
          });
      }
      setLoading(false);
    }
    GroupSettings();
  }, [JSON.stringify(selectedGroup)]);
  useEffect(() => {
    function updateGroupDetails() {
      dispatch(updateGoogleGroupSettingsStore(groupSettings));
    }
    updateGroupDetails();
  }, [groupSettings]);

  if (loading) {
    return (
      <Box
        height="40rem"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box className="loader" />
      </Box>
    );
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="60%"
      px={6}
      py={3}
      gap="1rem"
    >
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <Box display="flex" flexDirection="column">
        <TextField
          id="outlined-basic"
          size="small"
          name="groupName"
          label="Name"
          variant="outlined"
          sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
          inputProps={{
            style: {
              fontSize: '15px',
            },
            shrink: groupSettings.groupName || '',
          }}
          onChange={handleGroupSettings}
          value={groupSettings.groupName || ''}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <TextField
          id="outlined-basic"
          size="small"
          name="groupDescription"
          label="Description"
          variant="outlined"
          sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
          inputProps={{
            style: {
              fontSize: '15px',
            },
            shrink: groupSettings.groupDescription || '',
          }}
          onChange={handleGroupSettings}
          value={groupSettings.groupDescription || ''}
        />
      </Box>

      <FormControl>
        <Typography variant="caption">Please select Policy</Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          fullWidth
          sx={{ height: '2.2rem' }}
          onChange={handlePolicySelection}
          value={groupSettings.groupPolicy}
        >
          {allPolicies?.map((source: any) => (
            <MenuItem value={source?._id}>{source?.identifier}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export const RenderGoogleApps = React.memo(
  ({ groupSettings, setGroupSettings, allPolicies }: GoogleAppsProps) => {
    const dispatch = useDispatch();
    const [googleApps, setGoogleApps] = React.useState([]);
    useEffect(() => {
      async function getAllGoogleApps() {
        const token = await getCookie('token');
        const axiosConfig = {
          method: 'GET',
          url: `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-google-apps`,
          params: {
            token,
          },
        };
        axios(axiosConfig)
          .then((response: any) => {
            if (response && response?.data && response?.data?.googleApps) {
              setGoogleApps(response.data.googleApps);
            }
          })
          .catch(() => {
            toast.error(process.env.REACT_APP_GET_GOOGLE_APPS_ERROR);
          });
      }
      getAllGoogleApps();
    }, []);
    const handleGoogleAppPolicySettings = (event: any) => {
      var _id = '';
      const { name, value } = event.target;
      allPolicies.forEach((data: any) => {
        if (data.identifier === value) {
          _id = data._id;
        }
      });
      setGroupSettings((prevValue: any) => ({
        ...prevValue,
        googleAppPolicy: {
          ...prevValue.googleAppPolicy,
          [name]: _id,
        },
      }));
    };
    const handleRestrictionType = (event: any) => {
      var RestrictionApp: string[] = [];
      var AllowedApp: string[] = [];
      var DisableApp: string[] = [];
      const { name, value } = event.target;
      const Settings = groupSettings;
      Settings?.restrictedApps.forEach((app: any) => {
        RestrictionApp?.push(app);
      });
      Settings?.allowedApps.forEach((app: any) => {
        AllowedApp?.push(app);
      });
      Settings?.blockApps.forEach((app: any) => {
        DisableApp?.push(app);
      });

      if (AllowedApp?.includes(name)) {
        AllowedApp = AllowedApp?.filter((v: string) => v !== name);
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          allowedApps: AllowedApp,
        }));
      }

      if (RestrictionApp?.includes(name)) {
        RestrictionApp = RestrictionApp?.filter((v: string) => v !== name);
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          restrictedApps: RestrictionApp,
        }));
      }

      if (DisableApp.includes(name)) {
        DisableApp = DisableApp.filter((v: string) => v !== name);
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          blockApps: DisableApp,
        }));
      }

      const newGoogleAppPolicy = {
        ...groupSettings?.googleAppPolicy,
      };
      delete newGoogleAppPolicy?.[name];
      setGroupSettings((prevValue: any) => ({
        ...prevValue,
        googleAppPolicy: newGoogleAppPolicy,
      }));

      if (value === 'AppRestriction') {
        RestrictionApp.push(name);
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          restrictedApps: RestrictionApp,
        }));
      } else if (value === 'DisableApp') {
        DisableApp.push(name);
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          blockApps: DisableApp,
        }));
      } else if (value === 'NoAppRestricition') {
        AllowedApp.push(name);
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          allowedApps: AllowedApp,
        }));
      } else {
        let defaultPolicyID = '';
        allPolicies.forEach((element: any) => {
          if (element.identifier === 'default') {
            defaultPolicyID = element._id;
          }
        });
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          googleAppPolicy: {
            ...newGoogleAppPolicy,
            [name]: defaultPolicyID,
          },
        }));
      }
    };

    useEffect(() => {
      function updateGroupDetails() {
        dispatch(updateGoogleGroupSettingsStore(groupSettings));
      }
      updateGroupDetails();
    }, [groupSettings]);
    return (
      <>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2" px={6} py={3}>
            Application Permissions
          </Typography>
          {/* <TextField
            id="input-with-icon-textfield"
            size="small"
            variant="outlined"
            sx={{ marginRight: '3rem' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          /> */}
        </Box>
        <Grid container spacing={4} px={6}>
          {googleApps.map((app: any) => (
            <Grid item xs={4}>
              <Item>
                <Box
                  display="flex"
                  alignItems="center"
                  py={2}
                  px={2}
                  sx={{ gap: '1rem', minHeight: '2.5rem' }}
                >
                  <img
                    src={`${process.env.REACT_APP_NODE_BACKEND_URL}/api${app.imageSrc}`}
                    alt="google"
                    width="40"
                  />
                  <Typography variant="subtitle1">{app.name}</Typography>
                </Box>
                <Divider />
                <Box py={2} px={2} sx={{ gap: '0.75rem' }}>
                  <FormControl sx={{ gap: '0.5rem', width: '100%' }}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name={app.appKey}
                      onChange={handleRestrictionType}
                      value={
                        (groupSettings?.allowedApps?.includes(app.appKey)
                          ? 'NoAppRestricition'
                          : groupSettings?.restrictedApps?.includes(app.appKey)
                            ? 'AppRestriction'
                            : groupSettings?.blockApps?.includes(app.appKey)
                              ? 'DisableApp'
                              : 'CustomRestriction') || 'NoAppRestricition'
                      }
                    >
                      <FormControlLabel
                        value="AppRestriction"
                        control={<Radio />}
                        label="App Restriction"
                      />
                      <FormControlLabel
                        value="NoAppRestricition"
                        control={<Radio />}
                        label="No App Restriction"
                      />
                      <FormControlLabel
                        value="DisableApp"
                        control={<Radio />}
                        label="Disable App"
                      />
                      <FormControlLabel
                        value="CustomRestriction"
                        control={<Radio />}
                        label="Custom Restriction"
                      />
                      <Box
                        display="flex"
                        flexDirection="row"
                        width="100%"
                        alignItems="center"
                        gap="0.5rem"
                        justifyContent="space-between"
                      >
                        <Typography variant="caption" align="left">
                          Select Policy
                        </Typography>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          size="small"
                          sx={{ height: '2rem', width: '70%' }}
                          name={app.appKey}
                          onChange={handleGoogleAppPolicySettings}
                          disabled={
                            groupSettings.allowedApps.includes(app.appKey)
                            || groupSettings.restrictedApps.includes(app.appKey)
                            || groupSettings.blockApps.includes(app.appKey)
                          }
                          value={(() => {
                            let selectedIdentifier = '';
                            allPolicies?.forEach((data: any) => {
                              if (
                                data?._id
                                === groupSettings?.googleAppPolicy?.[app.appKey]
                              ) {
                                selectedIdentifier = data.identifier;
                              }
                            });
                            return selectedIdentifier;
                          })()}
                          defaultValue="default"
                        >
                          {allPolicies.map((source: any) => (
                            <MenuItem
                              key={source.identifier}
                              value={source.identifier}
                            >
                              {source.identifier}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Item>
            </Grid>
          ))}
        </Grid>
      </>
    );
  },
);

function ManageGroup({ errorMessage }: ManageGroupProps) {
  const dispatch = useDispatch();
  const [groupSettings, setGroupSettings] = React.useState({
    groupName: null,
    groupDescription: null,
    groupPolicy: '',
    allowedApps: [] as string[],
    restrictedApps: [] as string[],
    blockApps: [] as string[],
    googleAppPolicy: [] as any[],
  });
  useEffect(() => {
    async function getAllGoogleApps() {
      const token = await getCookie('token');
      const axiosConfig = {
        method: 'GET',
        url: `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-google-apps`,
        params: {
          token,
        },
      };
      axios(axiosConfig)
        .then((response: any) => {
          if (response && response.data && response.data.googleApps) {
            const appList: string[] = [];
            response.data.googleApps.forEach((app: any) => {
              appList.push(app.appKey);
            });
            setGroupSettings({
              groupName: null,
              groupDescription: null,
              groupPolicy: '',
              allowedApps: [],
              restrictedApps: appList,
              blockApps: [],
              googleAppPolicy: [],
            });
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_GOOGLE_APPS_ERROR);
        });
    }
    getAllGoogleApps();
  }, []);
  const token = getCookie('token');
  const [allPolicies, setAllPolicies] = React.useState([]);
  useEffect(() => {
    function getAllPolicies() {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-policies`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((response: any) => {
          if (response && response.data && response.data.PolicyData) {
            setAllPolicies(response.data.PolicyData);
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_ALL_POLICIES_ERROR);
        });
    }
    getAllPolicies();
  }, []);
  useEffect(() => {
    function updateGroupDetails() {
      dispatch(updateGoogleGroupSettingsStore(groupSettings));
    }
    updateGroupDetails();
  }, [groupSettings]);
  return (
    <Box height="67vh" sx={{ overflow: 'scroll' }}>
      <Box display="flex" flexDirection="row">
        <Box width="40%" display="flex" py={6} px={6} flexDirection="column">
          <Typography variant="h5">Group</Typography>
          <br />
          <Typography variant="subtitle2" color="#56526B">
            Group Settings for Google Proxy
          </Typography>
        </Box>
        <GroupSettingsForm
          allPolicies={allPolicies}
          groupSettings={groupSettings}
          setGroupSettings={setGroupSettings}
          errorMessage={errorMessage}
          selectedGroup={[]}
        />
      </Box>
      <Divider />
      <Box px={2} height="100%">
        <RenderGoogleApps
          allPolicies={allPolicies}
          groupSettings={groupSettings}
          setGroupSettings={setGroupSettings}
        />
      </Box>
    </Box>
  );
}

export default ManageGroup;
