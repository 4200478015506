import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Box, Typography, TextField, FormControl, Switch,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useDispatch } from 'react-redux';
import { updateshopifyNonPlusConfiguration } from '../../../store/features/CreateAuthSourceSlice';
import {
  getDraftProxyApplication,
  isValidDomain,
} from '../../../utils/utility';

interface BasicSettingsType {
  identifier: null | string;
  organization_domain: null | string;
  attribute_key: null | string;
  enableCASB: boolean;
  type: string;
}

interface BasicSettingsProps {
  errorMessage: string;
  setError: Function;
}

function BasicSettings({ errorMessage, setError }: BasicSettingsProps) {
  const InputFields = [
    'App Name*',
    'My Shopify Domain*',
    'Attribute Key*',
  ];
  const dispatch = useDispatch();
  const InputFieldsName = [
    'identifier',
    'organization_domain',
    'attribute_key',
  ];
  const [basicSettings, setBasicSettings] = useState<BasicSettingsType>({
    identifier: null,
    organization_domain: null,
    attribute_key: null,
    enableCASB: false,
    type: '',
  });
  const [inputErrorMessage, setInputErrorMessage] = useState<BasicSettingsType>(
    {
      identifier: null,
      organization_domain: null,
      attribute_key: null,
      enableCASB: false,
      type: '',
    },
  );
  const handleBasicSettingsInput = (event: any) => {
    var value = '';
    event.preventDefault();
    const { name } = event.target;
    value = event.target.value;
    value = value?.trim();
    switch (name) {
      case 'organization_domain':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          organization_domain: !isValidDomain(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      default:
        break;
    }
    setBasicSettings((prevValue: BasicSettingsType) => ({
      ...prevValue,
      [name]: value,
    }));
  };
  useEffect(() => {
    async function getDraftedConfiguration() {
      setError('');
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
      );
      setBasicSettings({
        identifier: DraftApplication?.success?.identifier,
        organization_domain: DraftApplication?.success?.organization_domain,
        attribute_key: DraftApplication?.success?.attribute_key,
        enableCASB: DraftApplication?.success?.features.casb,
        type:
          DraftApplication?.success?.features.multi_staff === true
            ? 'multistaff'
            : '',
      });
    }
    getDraftedConfiguration();
  }, []);
  useEffect(() => {
    function updateAuthenticationSource() {
      dispatch(
        updateshopifyNonPlusConfiguration({
          type: 'basic-settings',
          data: basicSettings,
        }),
      );
    }
    updateAuthenticationSource();
  }, [basicSettings]);
  const handleEnableCASBChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBasicSettings((prevSettings) => ({
      ...prevSettings,
      enableCASB: event.target.checked,
    }));
  };
  const handleEnableMultistaffChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setBasicSettings((prevSettings) => ({
      ...prevSettings,
      type: event.target.checked ? 'multistaff' : '',
    }));
  };
  return (
    <Box height="67vh" sx={{ overflow: 'scroll' }}>
      <Box display="flex" flexDirection="row">
        <Box width="40%" display="flex" py={6} px={6} flexDirection="column">
          <Typography variant="h5">Basic Settings</Typography>
          <br />
          <Typography variant="subtitle2" color="#56526B">
            Configure Settings for Shopify NonPlus.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="60%"
          px={6}
          py={5}
          gap="1.5rem"
        >
          {errorMessage && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                color: 'white',
                backgroundColor: '#E6353D',
                borderRadius: '0.23rem',
                py: '0.5rem',
                px: '0.25rem',
              }}
            >
              <ErrorOutlineIcon />
              <Typography sx={{ ml: '0.25rem' }} variant="subtitle2">
                {errorMessage}
              </Typography>
            </Box>
          )}
          {InputFields.map((value, index) => (
            <FormControl>
              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                label={value}
                sx={{
                  backgroundColor:
                    inputErrorMessage[
                      InputFieldsName[index] as keyof BasicSettingsType
                    ] === null
                      ? '#F6F6F6'
                      : '#ff8d0024',
                  border: 'none !important',
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                name={InputFieldsName[index]}
                value={
                  basicSettings[
                    InputFieldsName[index] as keyof BasicSettingsType
                  ] || ''
                }
                onChange={handleBasicSettingsInput}
              />
              <Typography variant="caption" color="red">
                {
                  inputErrorMessage[
                    InputFieldsName[index] as keyof BasicSettingsType
                  ]
                }
              </Typography>
            </FormControl>
          ))}
          <Box display="flex" alignItems="center" gap="1rem">
            <Typography variant="subtitle2">Enable CASB</Typography>
            <Switch
              checked={basicSettings.enableCASB}
              onChange={handleEnableCASBChange}
            />
            <Typography variant="subtitle2">Enable Multistaff</Typography>
            <Switch
              checked={basicSettings.type === 'multistaff'}
              onChange={handleEnableMultistaffChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BasicSettings;
