import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  FormControl,
} from '@mui/material';
import Select from '@mui/material/Select';
import axios from 'axios';
import { toast } from 'react-toastify';
import SAMLIDPMetadata from '../../../components/SAMLIDPMetadata';
import {
  getCookie,
  getDraftProxyApplication,
  isValidDomain,
  isValidURL,
  getUser,
  testSAMLConnection,
} from '../../../utils/utility';
import ErrorMessage from '../../../components/ErrorMessage';

interface IShopifyPlusApplication {
  identifier: string;
  organization_domain: string;
  attribute_key: string;
  acs_url: string;
  entity_id_url: string;
  authentication_saml_idp_metadata: object;
  authentication_id: string;
  name_id_format: string;
}
const initState: IShopifyPlusApplication = {
  identifier: '',
  organization_domain: '',
  attribute_key: '',
  acs_url: '',
  entity_id_url: '',
  authentication_saml_idp_metadata: [],
  authentication_id: '',
  name_id_format: '',
};

interface BasicSettingsType {
  shopifyPlusStoreName: null | string;
  shopifyPlusDomain: null | string;
  attributeKey: null | string;
  shopifyPlusACSUrl: null | string;
  shopifyPlusEntityUrl: null | string;
}

function BasicSettings() {
  const [openIDPMetadata, setOpenIDPMetadata] = useState<boolean>(false);
  const [allAuthenticationSources, setAllAuthenticationSources] = useState<
    any[]
  >([]);
  const [
    selectedAuthenticationSource,
    setSelectedAuthenticationSource,
  ] = useState('');

  /**
   * It gives the list/dropdown of all the allNameIdFormat
   */
  const [allNameIdFormat] = useState<any[]>([
    'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified',
    'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress',
    'urn:oasis:names:tc:SAML:2.0:nameid-format:persistent',
    'urn:oasis:names:tc:SAML:2.0:nameid-format:transient',
  ]);

  const [basicSettingsError] = useState('');
  const [
    shopifyPlusApplication,
    setShopifyPlusApplication,
  ] = useState<IShopifyPlusApplication>(initState);

  const InputFields = [
    'Shopify Store Name*',
    'Shopify Domain*',
    'Group Attribute Key*',
    'Shopify ACS URL*',
    'Shopify Entity URL*',
  ];

  const InputFieldsName = [
    'shopifyPlusStoreName',
    'shopifyPlusDomain',
    'attributeKey',
    'shopifyPlusACSUrl',
    'shopifyPlusEntityUrl',
  ];
  const [inputErrorMessage, setInputErrorMessage] = useState<BasicSettingsType>(
    {
      shopifyPlusStoreName: null,
      shopifyPlusDomain: null,
      attributeKey: null,
      shopifyPlusACSUrl: null,
      shopifyPlusEntityUrl: null,
    },
  );
  useEffect(() => {
    async function getAuthenticationSources() {
      const token = await getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-configured-authentication-source`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          if (res.data && res.data.authentication_source) {
            setAllAuthenticationSources(res.data.authentication_source);
          }
        })
        .catch(() => {
          toast.error(
            process.env.REACT_APP_GET_CONFIGURED_AUTHENTICATION_SOURCE_ERROR,
          );
        });
    }
    getAuthenticationSources();
  }, []);
  useEffect(() => {
    async function getAuthenticationSource() {
      allAuthenticationSources.forEach((source) => {
        if (source._id === shopifyPlusApplication?.authentication_id) {
          setSelectedAuthenticationSource(source?.identifier);
        }
      });
    }
    getAuthenticationSource();
  }, [allAuthenticationSources, shopifyPlusApplication]);
  useEffect(() => {
    async function getDraftedShopifyPlusConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_SHOPIFY_PLUS_KEY,
      );
      setShopifyPlusApplication(DraftApplication?.success);
    }
    getDraftedShopifyPlusConfiguration();
  }, []);

  const handleBasicSettingsInput = (event: any) => {
    var value = '';
    event.preventDefault();
    const { name } = event.target;
    value = event.target.value;
    value = value?.trim();
    switch (name) {
      case 'shopifyPlusDomain':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          shopifyPlusDomain: !isValidDomain(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      case 'shopifyPlusACSUrl':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          shopifyPlusACSUrl: !isValidURL(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      case 'shopifyPlusEntityUrl':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          shopifyPlusEntityUrl: !isValidURL(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      default:
        break;
    }
    if (name === 'shopifyPlusStoreName') {
      setShopifyPlusApplication((prevValue: any) => ({
        ...prevValue,
        identifier: value,
      }));
    } else if (name === 'shopifyPlusDomain') {
      setShopifyPlusApplication((prevValue: any) => ({
        ...prevValue,
        organization_domain: value,
      }));
    } else if (name === 'attributeKey') {
      setShopifyPlusApplication((prevValue: any) => ({
        ...prevValue,
        attribute_key: value,
      }));
    } else if (name === 'shopifyPlusACSUrl') {
      setShopifyPlusApplication((prevValue: any) => ({
        ...prevValue,
        acs_url: value,
      }));
    } else if (name === 'shopifyPlusEntityUrl') {
      setShopifyPlusApplication((prevValue: any) => ({
        ...prevValue,
        entity_id_url: value,
      }));
    } else if (name === 'authenticationSource') {
      allAuthenticationSources.forEach((source) => {
        if (source.identifier === value) {
          setSelectedAuthenticationSource(source.identifier);
          setShopifyPlusApplication((prevValue: any) => ({
            ...prevValue,
            authentication_id: source._id,
          }));
        }
      });
    } else if (name === 'nameIdFormat') {
      setShopifyPlusApplication((prevValue: any) => ({
        ...prevValue,
        name_id_format: value,
      }));
    }
  };

  const updateShopifyPlusApp = async () => {
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
        {
          identifier: shopifyPlusApplication.identifier,
          organization_domain: shopifyPlusApplication.organization_domain,
          authentication_id: shopifyPlusApplication.authentication_id,
          acs_url: shopifyPlusApplication.acs_url,
          entity_id_url: shopifyPlusApplication.entity_id_url,
          attribute_key: shopifyPlusApplication.attribute_key,
          name_id_format: shopifyPlusApplication?.name_id_format,
          app: process.env.REACT_APP_SHOPIFY_PLUS_KEY,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        toast.success('Updated Configuration.');
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_UPDATE_PROXY_CONFIG_ERROR);
      });
  };
  const handleShowMetadataURL = async () => {
    const user = await getUser();
    window.open(
      `${process.env.REACT_APP_NODE_BACKEND_URL}/saml/${user.customer_id}/${user.current_active_tenant_id}/metadata?app_name=${process.env.REACT_APP_SHOPIFY_PLUS_KEY}`,
      '_blank',
    );
  };
  return (
    <Box width="100%" height="70vh" overflow="scroll">
      <Box
        display="flex"
        flexDirection="column"
        py={2}
        sx={{ gap: '1.5rem' }}
        width="60%"
      >
        {basicSettingsError && <ErrorMessage message={basicSettingsError} />}
        <Box>
          <Typography variant="subtitle2" color="initial">
            Authentication Source
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            name="authenticationSource"
            sx={{ width: '100%', height: '2.2rem' }}
            value={selectedAuthenticationSource}
            onChange={handleBasicSettingsInput}
          >
            {allAuthenticationSources.map((source) => (
              <MenuItem value={source.identifier}>{source.identifier}</MenuItem>
            ))}
          </Select>
        </Box>
        {InputFields.map((value, index) => (
          <FormControl>
            <TextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              label={value}
              sx={{
                backgroundColor:
                  inputErrorMessage[
                    InputFieldsName[index] as keyof BasicSettingsType
                  ] === null
                    ? '#F6F6F6'
                    : '#ff8d0024',
                border: 'none !important',
              }}
              inputProps={{
                style: {
                  fontSize: '15px',
                },
              }}
              onChange={handleBasicSettingsInput}
              name={InputFieldsName[index]}
              value={
                InputFieldsName[index] === 'shopifyPlusStoreName'
                  ? shopifyPlusApplication.identifier
                  : InputFieldsName[index] === 'shopifyPlusDomain'
                    ? shopifyPlusApplication.organization_domain
                    : InputFieldsName[index] === 'attributeKey'
                      ? shopifyPlusApplication.attribute_key
                      : InputFieldsName[index] === 'shopifyPlusACSUrl'
                        ? shopifyPlusApplication.acs_url
                        : InputFieldsName[index] === 'shopifyPlusEntityUrl'
                          ? shopifyPlusApplication.entity_id_url
                          : ''
              }
            />
            <Typography variant="caption" color="red">
              {
                inputErrorMessage[
                  InputFieldsName[index] as keyof BasicSettingsType
                ]
              }
            </Typography>
          </FormControl>
        ))}
        <Box display="flex" flexDirection="column">
          <Typography variant="caption">Select Name ID Format</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            sx={{ width: '30rem', height: '2.2rem' }}
            name="nameIdFormat"
            onChange={handleBasicSettingsInput}
            value={shopifyPlusApplication.name_id_format || ''}
          >
            {allNameIdFormat?.map((source: string) => (
              <MenuItem key={source} value={source}>
                {source}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box display="flex">
          <Button
            sx={{
              color: 'white',
              backgroundColor: '#2D3748',
              marginRight: '2rem',
              '&:hover': {
                backgroundColor: '#2D3748',
                color: 'white',
              },
              width: '5rem',
            }}
            onClick={updateShopifyPlusApp}
          >
            Save
          </Button>
          <Box display="flex" alignItems="center">
            <SAMLIDPMetadata
              open={openIDPMetadata}
              setOpen={setOpenIDPMetadata}
              IDPMetadata={
                shopifyPlusApplication.authentication_saml_idp_metadata
              }
              appName={process.env.REACT_APP_SHOPIFY_PLUS_KEY}
            />
            <Button
              sx={{ fontSize: '15px', backgroundColor: 'lightblue', px: 3 }}
              onClick={handleShowMetadataURL}
            >
              Metadata URL
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              sx={{ fontSize: '15px', backgroundColor: 'lightblue', px: 3 }}
              onClick={() => setOpenIDPMetadata(true)}
            >
              View IDP Metadata
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              sx={{ fontSize: '15px', backgroundColor: 'lightblue', px: 3 }}
              onClick={() => testSAMLConnection(
                shopifyPlusApplication?.authentication_id,
                String(process.env.REACT_APP_SHOPIFY_PLUS_KEY),
              )}
            >
              Test Connection
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BasicSettings;
