import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ShopifyPlusLogo from '../../assets/images/shopify-plus-logo.png';
import MainLayout from '../../components/MainLayout';
import BasicSettings from './Components/EditBasicSettings';
import GroupSettings from './Components/GroupSettings';
import { getDraftProxyApplication } from '../../utils/utility';

function EditShopifyPlusConfiguration() {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const identifierValue = urlParams.has('identifier')
    ? urlParams.get('identifier')
    : '';
  useEffect(() => {
    async function getDraftedShopifyPlusConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_SHOPIFY_PLUS_KEY,
      );
      if (
        !DraftApplication?.success?.authentication_id
        && !DraftApplication?.success?.identifier
      ) {
        navigate('/configure/casb/shopifyplus');
      }
    }
    getDraftedShopifyPlusConfiguration();
  }, []);

  useEffect(() => {
    if (identifierValue === 'policy') {
      setStep(2);
    }
  }, []);

  return (
    <MainLayout>
      <Box overflow="hidden" borderLeft="1px solid #d3d3d3">
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          height="7rem"
          borderBottom="1px solid #D9D9D9"
        >
          <ToastContainer />
          <Box
            display="flex"
            marginLeft="7rem"
            sx={{ gap: '2rem' }}
            alignItems="center"
          >
            <img src={ShopifyPlusLogo} height="40" alt="google-logo" />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" px={5} height="100%">
          <Box
            display="flex"
            px={1}
            pt={1}
            borderBottom="1px solid #D9D9D9"
            width="100%"
          >
            <Button
              sx={{
                width: '9rem',
                color: 'black',
                borderBottom: step === 1 ? '3px solid #000000' : '',
                borderRadius: '0px !important',
              }}
              onClick={() => {
                setStep(1);
              }}
            >
              Basic Settings
            </Button>
            <Button
              sx={{
                width: '6rem',
                color: 'black',
                borderBottom: step === 2 ? '3px solid #000000' : '',
                borderRadius: '0px !important',
              }}
              onClick={() => {
                setStep(2);
              }}
            >
              Groups
            </Button>
          </Box>
          {step === 1 && <BasicSettings />}
          {step === 2 && <GroupSettings />}
        </Box>
      </Box>
    </MainLayout>
  );
}

export default EditShopifyPlusConfiguration;
