import React from 'react';
import { useDispatch } from 'react-redux';
import AuthenticationSourceCommon from '../../../components/CASBApp/AuthenticationSource';
import { updateAtlassianConfiguration } from '../../../store/features/CreateAuthSourceSlice';

interface AtlassianSourceProps {
  errorMessage: string;
}

function AtlassianAuthenticationSource({
  errorMessage,
}: AtlassianSourceProps) {
  const dispatch = useDispatch();
  return (
    <AuthenticationSourceCommon
      errorMessage={errorMessage}
      updateConfiguration={(config: any) => dispatch(
        updateAtlassianConfiguration({
          type: 'authentication-source',
          data: config,
        }),
      )}
      appType={process.env.REACT_APP_ATLASSIAN_KEY}
    />
  );
}

export default AtlassianAuthenticationSource;
