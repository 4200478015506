import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getCookie, getUser } from '../../utils/utility';
import { allAuthSource } from '../../store/features/CreateAuthSourceSlice';
import MainLayout from '../../components/MainLayout';
import SPMetadataModal from '../../components/SPMetadataModal';
import DeletePromptModal from '../../components/DeletePromptModal';

const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function AuthenticationSource() {
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  const [openSPMetadataPrompt, setOpenSPMetadataPrompt] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authenticationSources, setAuthenticationSources] = React.useState<
    any[]
  >([]);
  const token = getCookie('token');
  const [user, setUser] = useState<any>();
  const [deleteRow, setDeleteRow] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(6);
  const totalPages = Math.ceil((authenticationSources?.length || 0) / rowsPerPage);
  const handleEditAuthenticationSource = (element: any) => {
    navigate(`/edit/authentication-source/?identifier=${element}`);
  };
  const handleDeleteAuthenticationSource = (element: any) => {
    setDeleteRow(element);
    setOpenDeletePrompt(true);
  };
  useEffect(() => {
    function getAuthenticationSources() {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-configured-authentication-source`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          if (res.data && res.data.authentication_source) {
            setAuthenticationSources(res.data.authentication_source);
            dispatch(allAuthSource(res.data.authentication_source));
          }
        })
        .catch(() => {
          toast.error(
            process.env.REACT_APP_GET_CONFIGURED_AUTHENTICATION_SOURCE_ERROR,
          );
        });
    }
    getAuthenticationSources();
  }, [openDeletePrompt]);
  useEffect(() => {
    const fetchData = async () => {
      const userDetails = await getUser();
      setUser(userDetails);
    };

    fetchData();
  }, []);
  const handleShowMetadata = () => {
    setOpenSPMetadataPrompt(true);
  };
  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const paginatedData = authenticationSources?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <MainLayout>
      <Box overflow="hidden">
        <ToastContainer />
        <Box display="flex" px="2rem" py="1rem" justifyContent="space-between">
          <Box>
            <Typography variant="h5">Authentication Source</Typography>
          </Box>
          <Box>
            <Button
              sx={{
                backgroundColor: '#2D3748',
                color: '#ffffff',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#2D3748',
                  color: '#ffffff',
                },
              }}
              href="/add/authentication-source"
            >
              <AddIcon />
              Add New Authentication Source
            </Button>
          </Box>
        </Box>
        <Box>
          <TableContainer component={Paper} sx={{ padding: '2rem', height: '75vh', overflow: 'auto' }}>
            <Table sx={{ width: '95%' }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Identifier</StyledTableCell>
                  <StyledTableCell align="center">
                    Authentication Type
                  </StyledTableCell>
                  <StyledTableCell align="right">Metadata URL</StyledTableCell>
                  <StyledTableCell align="right">
                    View IDP Metadata
                  </StyledTableCell>
                  <StyledTableCell align="right" marginRight="2rem">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component="th" scope="row">
                      {row?.identifier}
                    </StyledTableCell>
                    <StyledTableCell align="center">SAML</StyledTableCell>
                    <SPMetadataModal
                      open={openSPMetadataPrompt}
                      setOpen={setOpenSPMetadataPrompt}
                      SpMetadata={row}
                    />
                    <StyledTableCell align="right">
                      <Link
                        to={`${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${user.customer_id}/${user.current_active_tenant_id}/${row?._id}/metadata`}
                        target="_blank"
                        sx={{ textDecoration: 'none !important' }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row-reverse"
                          alignItems="center"
                          gap="0.5rem"
                        >
                          <LaunchIcon />
                          Metadata URL
                        </Box>
                      </Link>
                    </StyledTableCell>

                    <StyledTableCell
                      align="right"
                      onClick={() => handleShowMetadata()}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ cursor: 'pointer', color: '#2D3748' }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row-reverse"
                          alignItems="center"
                          gap="0.5rem"
                        >
                          <VisibilityIcon />
                          View Metadata
                        </Box>
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="right" key={row?.identifier} display="flex" alignItems="center">
                      <Button
                        onClick={() => handleEditAuthenticationSource(row?.identifier)}
                      >
                        <EditIcon sx={{ color: 'blue' }} />
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        onClick={() => handleDeleteAuthenticationSource(row?.identifier)}
                      >
                        <DeleteIcon sx={{ color: 'red' }} />
                      </Button>
                    </StyledTableCell>
                    <DeletePromptModal
                      open={openDeletePrompt}
                      setOpen={setOpenDeletePrompt}
                      identifier={deleteRow}
                    />
                  </StyledTableRow>
                ))}
                {authenticationSources?.length === 0 && (
                  <StyledTableRow>
                    <StyledTableCell align="center" />
                    <StyledTableCell align="center" />
                    <StyledTableCell align="right">
                      No Configurations
                    </StyledTableCell>
                    <StyledTableCell align="center" />
                    <StyledTableCell align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem',
                marginRight: '5rem',
              }}
            >
              <Button onClick={handlePrevPage} disabled={page === 0}>
                Previous
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={page === totalPages - 1}
              >
                Next
              </Button>
            </div>
          </TableContainer>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default AuthenticationSource;
