import React, { useEffect, useState, ChangeEvent } from 'react';
import {
  Box,
  Button,
  MenuItem,
  Typography,
  TextField,
  FormControl,
  Switch,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  getCookie,
  getDraftProxyApplication,
  isValidDomain,
  testSAMLConnection,
  getUser,
} from '../../../utils/utility';

interface BasicSettingsType {
  identifier: null | string;
  organization_domain: null | string;
  attribute_key: null | string;
  enableCASB: boolean;
  type: string;
}

function BasicSettings() {
  const [
    selectedAuthenticationSource,
    setSelectedAuthenticationSource,
  ] = useState<any>({});
  const [draftApplication, setDraftApplication] = useState<any>([]);
  const [allAuthenticationSources, setAllAuthenticationSources] = useState<
    any[]
  >([]);
  const InputFields = ['App Name*', 'My Shopify Domain*', 'Attribute Key*'];
  const InputFieldsName = [
    'identifier',
    'organization_domain',
    'attribute_key',
  ];
  const [basicSettings, setBasicSettings] = useState<BasicSettingsType>({
    identifier: null,
    organization_domain: null,
    attribute_key: null,
    enableCASB: false,
    type: '',
  });
  const [inputErrorMessage, setInputErrorMessage] = useState<BasicSettingsType>(
    {
      identifier: null,
      organization_domain: null,
      attribute_key: null,
      enableCASB: false,
      type: '',
    },
  );
  const [extensionURL, setExtensionURL] = useState('');
  useEffect(() => {
    async function getAuthenticationSources() {
      const token = await getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-configured-authentication-source`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          if (res.data && res.data.authentication_source) {
            setAllAuthenticationSources(res.data.authentication_source);
          }
        })
        .catch(() => {
          toast.error(
            process.env.REACT_APP_GET_CONFIGURED_AUTHENTICATION_SOURCE_ERROR,
          );
        });
    }
    getAuthenticationSources();
  }, []);
  useEffect(() => {
    async function getDraftedConfiguration() {
      const user = await getUser();
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
      );
      setDraftApplication(DraftApplication?.success);
      setBasicSettings({
        identifier: DraftApplication?.success?.identifier,
        organization_domain: DraftApplication?.success?.organization_domain,
        attribute_key: DraftApplication?.success?.attribute_key,
        enableCASB: DraftApplication?.success?.features?.casb,
        type:
          DraftApplication?.success?.features?.multi_staff === true
            ? 'multistaff'
            : '',
      });
      setExtensionURL(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${user.customer_id}/${user.current_active_tenant_id}/${DraftApplication?.success?.authentication_id}/samllogin?app_name=shopify_non_plus&RelayState=shopify_non_plus`,
      );
    }
    getDraftedConfiguration();
  }, []);
  useEffect(() => {
    async function getAuthenticationSource() {
      allAuthenticationSources.forEach((source) => {
        if (source._id === draftApplication?.authentication_id) {
          setSelectedAuthenticationSource(source);
        }
      });
    }
    getAuthenticationSource();
  }, [allAuthenticationSources, basicSettings]);

  const updateAuthenticationSource = async (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    allAuthenticationSources.forEach((source) => {
      if (source.identifier === value) {
        setSelectedAuthenticationSource(source);
      }
    });
  };
  const updateShopifyApp = async () => {
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
        {
          authentication_id: selectedAuthenticationSource._id,
          identifier: basicSettings.identifier,
          organization_domain: basicSettings.organization_domain,
          attribute_key: basicSettings.attribute_key,
          casb: basicSettings.enableCASB,
          type: basicSettings.type,
          app: process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        toast.success('Updated Configuration.');
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_UPDATE_PROXY_CONFIG_ERROR);
      });
  };
  const handleBasicSettingsInput = (event: any) => {
    var value = '';
    event.preventDefault();
    const { name } = event.target;
    value = event.target.value;
    value = value?.trim();
    switch (name) {
      case 'organization_domain':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          organization_domain: !isValidDomain(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      default:
        break;
    }
    setBasicSettings((prevValue: BasicSettingsType) => ({
      ...prevValue,
      [name]: value,
    }));
  };
  const handleEnableCASBChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBasicSettings((prevSettings) => ({
      ...prevSettings,
      enableCASB: event.target.checked,
    }));
  };
  const handleEnableMultistaffChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setBasicSettings((prevSettings) => ({
      ...prevSettings,
      type: event.target.checked ? 'multistaff' : '',
    }));
  };
  return (
    <Box width="100%" height="69vh" overflow="scroll">
      <Box
        display="flex"
        flexDirection="column"
        pb={4}
        px={5}
        sx={{ gap: '1.5rem' }}
        width="60%"
      >
        <ToastContainer />
        <Box>
          <Typography variant="caption">Authentication Source</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            name="authenticationSource"
            sx={{ width: '100%', height: '2.2rem' }}
            onChange={updateAuthenticationSource}
            defaultValue=""
            value={selectedAuthenticationSource.identifier || ''}
          >
            {allAuthenticationSources?.map((source: any) => (
              <MenuItem value={source.identifier}>{source.identifier}</MenuItem>
            ))}
          </Select>
        </Box>

        <TextField
          id="outlined-basic"
          size="small"
          variant="outlined"
          label="Extension URL"
          name="extension-url"
          value={extensionURL}
          InputProps={{
            endAdornment: (
              <CopyToClipboard text={extensionURL}>
                <ContentCopyIcon
                  sx={{
                    backgroundColor: 'white !important',
                    padding: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={() => toast.success('URL Copied')}
                />
              </CopyToClipboard>
            ),
          }}
          disabled
        />

        {InputFields.map((value, index) => (
          <FormControl>
            <TextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              label={value}
              sx={{
                backgroundColor:
                  inputErrorMessage[
                    InputFieldsName[index] as keyof BasicSettingsType
                  ] === null
                    ? '#F6F6F6'
                    : '#ff8d0024',
                border: 'none !important',
              }}
              inputProps={{
                style: {
                  fontSize: '15px',
                },
              }}
              name={InputFieldsName[index]}
              value={
                basicSettings[
                  InputFieldsName[index] as keyof BasicSettingsType
                ] || ''
              }
              onChange={handleBasicSettingsInput}
            />
            <Typography variant="caption" color="red">
              {
                inputErrorMessage[
                  InputFieldsName[index] as keyof BasicSettingsType
                ]
              }
            </Typography>
          </FormControl>
        ))}
        <Box>
          <Box display="flex" alignItems="center" gap="10rem">
            <Typography variant="subtitle2">Enable CASB</Typography>
            <Switch
              checked={basicSettings.enableCASB}
              onChange={handleEnableCASBChange}
            />
          </Box>
          <Box display="flex" alignItems="center" gap="10rem">
            <Typography variant="subtitle2">Mutistaff</Typography>
            <Switch
              checked={basicSettings.type === 'multistaff'}
              onChange={handleEnableMultistaffChange}
            />
          </Box>
        </Box>

        <Box display="flex">
          <Button
            sx={{
              color: 'white',
              backgroundColor: '#2D3748',
              marginRight: '2rem',
              '&:hover': {
                backgroundColor: '#2D3748',
                color: 'white',
              },
              width: '5rem',
            }}
            onClick={updateShopifyApp}
          >
            Save
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            sx={{ fontSize: '15px', backgroundColor: 'lightblue', px: 3 }}
            onClick={() => testSAMLConnection(
              draftApplication?.authentication_id,
              String(process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY),
            )}
          >
            Test Connection
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default BasicSettings;
