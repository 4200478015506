import React, { useEffect, useState } from 'react';
import {
  Box, Button, Divider, Paper,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
// import InputAdornment from '@mui/material/InputAdornment';
// import SearchIcon from '@mui/icons-material/Search';
import AddGroupModal from '../../../components/AddGroupModalGoogle';
import { getCookie } from '../../../utils/utility';
import '../index.css';

function GroupSettings() {
  const [allGroups, setAllGroups] = useState<any[]>([]);
  const [openNewGroup, setOpenNewGroup] = useState(false);
  const [reloadGroup, setReloadGroup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<any[]>([]);
  const [allPolicies, setAllPolicies] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const totalPages = Math.ceil((allGroups?.length || 0) / rowsPerPage);

  useEffect(() => {
    async function getAllPolicies() {
      const token = await getCookie('token');
      await axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-policies`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((response: any) => {
          if (response && response.data && response.data.PolicyData) {
            setAllPolicies(response.data.PolicyData);
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_ALL_POLICIES_ERROR);
        });
    }
    async function getAllGroups() {
      const token = await getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-groups`,
          {
            app: process.env.REACT_APP_GOOGLE_KEY,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((response: any) => {
          if (response && response.data && response.data.GroupData) {
            setAllGroups(response.data.GroupData);
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_ALL_GROUPS_ERROR);
        });
      setReloadGroup(false);
    }
    getAllPolicies();
    getAllGroups();
  }, [reloadGroup]);
  const deleteGroupSettings = async (identifierID: string) => {
    if (identifierID === 'default') {
      return;
    }
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/delete-group-settings`,
        {
          identifierID,
          app: process.env.REACT_APP_GOOGLE_KEY,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        setReloadGroup(true);
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_DELETE_GROUP_ERROR);
      });
  };
  const editGroupSettings = async (identifier: string) => {
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-groups`,
        {
          app: process.env.REACT_APP_GOOGLE_KEY,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((response: any) => {
        if (response && response.data && response.data.GroupData) {
          response?.data?.GroupData.forEach((group: any) => {
            if (group?.identifier === identifier) {
              setSelectedGroup(group);
              setOpenNewGroup(true);
            }
          });
        }
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_GET_ALL_GROUPS_ERROR);
      });
  };
  const openNewGroupModal = () => {
    setSelectedGroup([]);
    setOpenNewGroup(true);
  };

  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const paginatedData = allGroups?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      my={5}
      marginLeft={5}
      border="1px solid #D9D9D9"
      borderRadius="3px"
      width="95%"
    >
      <Box display="flex" width="100%" p={3}>
        {/* <TextField
          id="input-with-icon-textfield"
          size="small"
          variant="outlined"
          sx={{ marginRight: '3rem' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        /> */}
        <Button onClick={openNewGroupModal}>Add New Group</Button>
        <AddGroupModal
          appName={String(process.env.REACT_APP_GOOGLE_KEY)}
          open={openNewGroup}
          setOpen={setOpenNewGroup}
          setReloadGroup={setReloadGroup}
          selectedGroup={selectedGroup}
        />
      </Box>
      <Divider />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" size="small">
                Group Name
              </TableCell>
              <TableCell align="left" size="small">
                Group Description
              </TableCell>
              <TableCell align="left" size="small">
                Policy
              </TableCell>
              <TableCell align="left" size="small">
                Edit
              </TableCell>
              <TableCell align="left" size="small">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData?.map((row) => (
              <TableRow key={row.identifier}>
                <TableCell component="th" scope="row" size="small">
                  {row.identifier}
                </TableCell>
                <TableCell align="left" size="small">
                  {row.description}
                </TableCell>
                <TableCell align="left" size="small">
                  {
                    allPolicies.find((policy) => policy._id === row.policy_id)
                      ?.identifier
                  }
                </TableCell>
                <TableCell align="left" size="small">
                  {row.identifier !== 'default' ? (
                    <EditIcon
                      sx={{
                        color: 'blue',
                        pointerEvents:
                          row.identifier === 'default' ? 'none' : 'auto',
                      }}
                      onClick={() => {
                        if (row.identifier !== 'default') {
                          editGroupSettings(row.identifier);
                        }
                      }}
                    />
                  ) : (
                    <EditIcon
                      sx={{
                        color: 'gray',
                        pointerEvents:
                          row.identifier === 'default' ? 'none' : 'auto',
                      }}
                    />
                  )}
                </TableCell>
                <TableCell align="left" size="small">
                  {row.identifier !== 'default' ? (
                    <Button onClick={() => deleteGroupSettings(row._id)}>
                      <DeleteIcon sx={{ color: 'red' }} />
                    </Button>
                  ) : (
                    <Button>
                      <DeleteIcon sx={{ color: 'gray' }} />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
            marginRight: '5rem',
          }}
        >
          <Button onClick={handlePrevPage} disabled={page === 0}>
            Previous
          </Button>
          <Button onClick={handleNextPage} disabled={page === totalPages - 1}>
            Next
          </Button>
        </div>
      </TableContainer>
    </Box>
  );
}

export default GroupSettings;
