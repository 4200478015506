import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  generateMetaData,
  addAuthenticationSource,
  updateAuthenticationSource,
} from '../../../utils/utility';
import {
  lightBackgroundButtonStyles,
  darkBackgroundButtonStyles,
} from '../../../styles/button';
import SPMetadataModal from '../../../components/SPMetadataModal';
import ErrorMessage from '../../../components/ErrorMessage';
import MainLayout from '../../../components/MainLayout';
import ImportIDPMetadata from '../../../components/ImportIDPMetadata';
import {
  downloadXML,
  generateAndOpenMetadata,
} from '../../../utils/SAMLAuthentication';

function AddAuthenticationSourceView() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [spMetadata, setSpMetadata] = React.useState<Object | null>(null);
  const [importIDPMetadata, setImportIDPMetadata] = React.useState({
    open: false,
  });
  const [
    authenticationSourceDetails,
    setAuthenticationSourceDetails,
  ] = useState({
    authenticationName: '',
    issuer: '',
    loginURL: '',
    logoutURL: '',
    bindingType: 'HttpRedirect',
    X509Certificate: '',
    _id: '',
  });

  const handleAuthenticationSource = (event: any) => {
    var { value } = event.target;
    event.preventDefault();
    const { name } = event.target;
    value = value?.trim();
    setAuthenticationSourceDetails((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleGenerateMetaData = async () => {
    setErrorMessage('');
    generateMetaData(authenticationSourceDetails)
      .then((data) => {
        // Handle success
        setOpen(true);
        setAuthenticationSourceDetails((prevDetails) => ({
          ...prevDetails,
          authenticationName: (data as any).success.sp_metadata.identifier,
          bindingType: 'HttpRedirect',
          _id: (data as any).success.sp_metadata._id,
        }));
        setSpMetadata((data as any).success.sp_metadata);
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  const handleUpdateAuthenticationSource = () => {
    setErrorMessage('');
    if (spMetadata) {
      updateAuthenticationSource(authenticationSourceDetails)
        .then(() => {
          navigate('/configure/authentication-source');
        })
        .catch((error: any) => {
          setErrorMessage(error?.message);
        });
    } else {
      addAuthenticationSource(authenticationSourceDetails)
        .then(() => {
          navigate('/configure/authentication-source');
        })
        .catch((error: any) => {
          setErrorMessage(error?.message);
        });
    }
  };

  const uploadMetaIDPdata = async () => {
    setImportIDPMetadata((prevState) => ({ ...prevState, open: true }));
  };

  const generateAndOpenMetadataHandler = async () => {
    await generateAndOpenMetadata({
      authenticationSourceDetails,
      setAuthenticationSourceDetails,
      setSpMetadata,
    });
  };

  const downloadXMLHandler = async () => {
    await downloadXML({
      authenticationSourceDetails,
      setAuthenticationSourceDetails,
      spMetadata,
      setSpMetadata,
    });
  };

  const isScreenSmall = useMediaQuery((mediaQueryTheme: any) => mediaQueryTheme.breakpoints.down('sm'));

  return (
    <MainLayout>
      <Box display="flex" flexDirection="column" overflow="hidden">
        <ToastContainer />
        <Box
          display="flex"
          width="100%"
          pl={7}
          pt={5}
          pb={3}
          borderBottom="1px solid lightgray"
        >
          <Typography variant="h6">Add Authentication - SAML</Typography>
        </Box>
        <Box display="flex" py={2}>
          <Box
            pl={7}
            pr={3}
            py={1}
            display="flex"
            flexDirection="column"
            width="60%"
            alignItems="center"
            gap="0.75rem"
            height={isScreenSmall ? 'calc(100vh - 200px)' : '65vh'}
            overflow="auto"
          >
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <TextField
              required
              fullWidth
              id="outlined-basic"
              size="small"
              name="authenticationName"
              label="Authentication Name"
              variant="outlined"
              value={authenticationSourceDetails.authenticationName || ''}
              sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
              onChange={handleAuthenticationSource}
              disabled={spMetadata !== null}
            />
            <TextField
              required
              fullWidth
              id="outlined-basic"
              size="small"
              name="issuer"
              label="IdP Entity ID or Issuer"
              variant="outlined"
              value={authenticationSourceDetails.issuer || ''}
              sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
              disabled={
                !authenticationSourceDetails.authenticationName && !spMetadata
              }
              onChange={handleAuthenticationSource}
            />
            <TextField
              fullWidth
              required
              id="outlined-basic"
              size="small"
              label="SAML Login URL"
              name="loginURL"
              variant="outlined"
              value={authenticationSourceDetails.loginURL}
              sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
              disabled={
                !authenticationSourceDetails.authenticationName && !spMetadata
              }
              onChange={handleAuthenticationSource}
            />
            <TextField
              fullWidth
              required
              id="outlined-basic"
              size="small"
              label="SAML Logout URL"
              name="logoutURL"
              variant="outlined"
              sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
              disabled={
                !authenticationSourceDetails.authenticationName && !spMetadata
              }
              value={authenticationSourceDetails.logoutURL || ''}
              onChange={handleAuthenticationSource}
            />
            <Box width="100%">
              <Typography variant="caption">
                Select Binding Type for SSO Request:
              </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="bindingType"
                value={authenticationSourceDetails.bindingType || ''}
                onChange={handleAuthenticationSource}
              >
                <FormControlLabel
                  disabled={
                    !authenticationSourceDetails.authenticationName
                    && !spMetadata
                  }
                  value="HttpRedirect"
                  control={<Radio />}
                  label="HTTP-Redirect Binding"
                  sx={{ fontSize: '13px' }}
                />
                <FormControlLabel
                  value="HttpPost"
                  disabled={
                    !authenticationSourceDetails.authenticationName
                    && !spMetadata
                  }
                  control={<Radio />}
                  label="HTTP-Post Binding"
                  sx={{ fontSize: '15px' }}
                />
              </RadioGroup>
            </Box>
            <Box width="100%">
              <Typography variant="caption">X.509 Certificate</Typography>
              <TextField
                required
                fullWidth
                multiline
                name="X509Certificate"
                rows={7}
                maxRows={7}
                value={authenticationSourceDetails.X509Certificate || ''}
                disabled={
                  !authenticationSourceDetails.authenticationName && !spMetadata
                }
                onChange={handleAuthenticationSource}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            px={3}
            width="40%"
            flexDirection="column"
            gap="1rem"
          >
            <Box display="flex" flexDirection="column" gap="1rem">
              <Box display="flex" flexDirection="column" gap="1rem">
                <Typography>SP Metadata</Typography>
                <Box display="flex" flexDirection="column" gap="1rem">
                  <Box display="flex" gap="1rem">
                    {spMetadata === null && (
                      <Button
                        onClick={handleGenerateMetaData}
                        disabled={
                          !(authenticationSourceDetails as any)
                            .authenticationName
                        }
                        startIcon={<SettingsOutlinedIcon />}
                        sx={lightBackgroundButtonStyles}
                      >
                        Generate Metadata
                      </Button>
                    )}
                    {spMetadata !== null && (
                      <Button
                        onClick={() => setOpen(true)}
                        disabled={
                          !(authenticationSourceDetails as any)
                            .authenticationName
                        }
                        startIcon={<VisibilityIcon />}
                        sx={lightBackgroundButtonStyles}
                      >
                        View Metadata
                      </Button>
                    )}
                    <Button
                      onClick={generateAndOpenMetadataHandler}
                      disabled={
                        !(authenticationSourceDetails as any).authenticationName
                      }
                      startIcon={<InsertLinkIcon />}
                      sx={lightBackgroundButtonStyles}
                    >
                      Metadata URL
                    </Button>
                  </Box>
                  <Button
                    onClick={downloadXMLHandler}
                    disabled={
                      !(authenticationSourceDetails as any).authenticationName
                    }
                    startIcon={<FileDownloadOutlinedIcon />}
                    sx={lightBackgroundButtonStyles}
                  >
                    Download Metadata
                  </Button>
                </Box>
              </Box>
              <Divider />
              <Typography>IDP Metadata</Typography>
              <Button
                onClick={uploadMetaIDPdata}
                sx={{ ...darkBackgroundButtonStyles, width: '12rem' }}
              >
                <FileUploadOutlinedIcon />
                &nbsp; Upload IDP Metadata
              </Button>
              <ImportIDPMetadata
                open={importIDPMetadata.open}
                setOpen={setImportIDPMetadata}
                authenticationSource={authenticationSourceDetails}
                setAuthenticationSource={setAuthenticationSourceDetails}
              />
              <Divider />
            </Box>
            <SPMetadataModal
              open={open}
              setOpen={setOpen}
              SpMetadata={spMetadata}
            />
          </Box>
        </Box>
        <Box
          position="absolute"
          bottom="0"
          width="80%"
          display="flex"
          justifyContent="flex-end"
          bgcolor="background.paper"
          borderTop="1px solid lightgray"
          p={1.5}
        >
          <Box display="flex" mr={2}>
            <Button
              sx={{ ...lightBackgroundButtonStyles, width: '5rem' }}
              href="/configure/authentication-source"
            >
              Cancel
            </Button>
          </Box>
          <Box display="flex">
            <Button
              sx={darkBackgroundButtonStyles}
              onClick={handleUpdateAuthenticationSource}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default AddAuthenticationSourceView;
