import React from 'react';
import {
  Box, Typography, TextField, Button, Modal,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface SPMetadataModalProps {
  open: boolean;
  setOpen: Function;
  SpMetadata: any;
}

function SPMetadataField({
  label, value, copyText, onClick,
}: any) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
    >
      <Box width="20%" px={4}>
        <Typography variant="subtitle2">{label}</Typography>
      </Box>
      <Box width="80%">
        <TextField
          disabled
          variant="outlined"
          size="small"
          fullWidth
          value={value}
          InputProps={{
            endAdornment: (
              <CopyToClipboard text={copyText}>
                <ContentCopyIcon
                  sx={{
                    backgroundColor: 'white !important',
                    padding: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={onClick}
                />
              </CopyToClipboard>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

function SPMetadataModal({ open, setOpen, SpMetadata }: SPMetadataModalProps) {
  const handleClose = () => setOpen(false);

  const CopiedToClipBoard = () => {
    toast('Copied to Clipboard');
  };

  const handleDownloadSAMLCertificate = () => {
    if (!SpMetadata?.saml?.sp_metadata?.sp_x_509_certificate?.trim()) return;

    const blob = new Blob([SpMetadata.saml.sp_metadata.sp_x_509_certificate], {
      type: 'application/x-x509-ca-cert',
    });
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'saml-sp-certificate.crt';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 1100,
          color: '#5F636D',
        }}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          sx={{ backgroundColor: 'white' }}
          p="20px"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="1rem"
          >
            <Typography variant="h6" color="#5F636D">
              Service Provider Metadata:
            </Typography>
          </Box>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          p="20px"
          width="100%"
          sx={{ backgroundColor: 'white' }}
          gap="1rem"
        >
          {[
            {
              label: 'SP Entity ID or Issuer:',
              value: SpMetadata?.saml?.sp_metadata?.entity_id || '',
              copyText: SpMetadata?.saml?.sp_metadata?.entity_id,
            },
            {
              label: 'Audience URI:',
              value: SpMetadata?.saml?.sp_metadata?.entity_id || '',
              copyText: SpMetadata?.saml?.sp_metadata?.entity_id,
            },
            {
              label: 'ACS URL:',
              value: SpMetadata?.saml?.saml_urls?.acs_url || '',
              copyText: SpMetadata?.saml?.saml_urls?.acs_url,
            },
            {
              label: 'Single Logout URL:',
              value: SpMetadata?.saml?.saml_urls?.logout_acs_url || '',
              copyText: SpMetadata?.saml?.saml_urls?.logout_acs_url,
            },
          ].map(({
            label, value, copyText,
          }) => (
            <SPMetadataField
              key={label}
              label={label}
              value={value}
              copyText={copyText}
              onClick={CopiedToClipBoard}
            />
          ))}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box width="20%" px={4}>
              <Typography variant="subtitle2">Signing Certificate:</Typography>
            </Box>
            <Box width="80%">
              <TextField
                disabled
                fullWidth
                multiline
                rows={10}
                maxRows={10}
                value={
                  SpMetadata?.saml?.sp_metadata?.sp_x_509_certificate || ''
                }
                InputProps={{
                  endAdornment: (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2rem"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <DownloadIcon onClick={handleDownloadSAMLCertificate} />
                      <CopyToClipboard
                        text={
                          SpMetadata?.saml?.sp_metadata?.sp_x_509_certificate
                        }
                        onCopy={CopiedToClipBoard}
                      >
                        <ContentCopyIcon
                          sx={{
                            backgroundColor: 'white !important',
                            padding: '5px',
                            cursor: 'pointer',
                          }}
                        />
                      </CopyToClipboard>
                    </Box>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default SPMetadataModal;
