import React from 'react';
import {
  Box, Typography, Button, Modal, TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GoogleLogo from '../assets/images/google-logo.png';
import ShopifyPlusLogo from '../assets/images/shopify-plus.png';
import AtlassianLogo from '../assets/images/atlassian-logo.png';

interface SAMLIDPMetadataProps {
  open: boolean;
  setOpen: Function;
  IDPMetadata: any;
  appName: any;
}

function SAMLIDPMetadata({
  open,
  setOpen,
  IDPMetadata,
  appName,
}: SAMLIDPMetadataProps) {
  const CopiedToClipBoard = () => {
    toast('Copied to Clipboard');
  };
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    color: '#5F636D',
  };

  const handleDownloadSAMLCertificate = () => {
    if ((IDPMetadata as any)?.x_509_certificate?.trim() === '') {
      return;
    }
    const blob = new Blob([(IDPMetadata as any)?.x_509_certificate], {
      type: 'application/x-x509-ca-cert',
    });
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'saml-certificate.crt';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };

  function renderMetadataIDPContent() {
    switch (appName) {
      case process.env.REACT_APP_GOOGLE_KEY: {
        return (
          <>
            <Typography variant="h6" color="#5F636D">
              Google-IdP Metadata
            </Typography>
            <Typography variant="body2" color="#5F636D">
              To check out the IDP metadata for this application.
            </Typography>
          </>
        );
      }
      case process.env.REACT_APP_SHOPIFY_PLUS_KEY: {
        return (
          <>
            <Typography variant="h6" color="#5F636D">
              Shopify Plus-IdP Metadata
            </Typography>
            <Typography variant="body2" color="#5F636D">
              To check out the IDP metadata for this application.
            </Typography>
          </>
        );
      }
      case process.env.REACT_APP_ATLASSIAN_KEY: {
        return (
          <>
            <Typography variant="h6" color="#5F636D">
              Atlassian-IdP Metadata
            </Typography>
            <Typography variant="body2" color="#5F636D">
              To check out the IDP metadata for this application.
            </Typography>
          </>
        );
      }
      default: {
        return null;
      }
    }
  }

  function renderMetadataIDPImage() {
    switch (appName) {
      case process.env.REACT_APP_GOOGLE_KEY: {
        return (
          <img
            src={GoogleLogo}
            alt="google-logo"
            width={40}
            height={40}
            style={{
              marginLeft: '2rem',
            }}
          />
        );
      }
      case process.env.REACT_APP_SHOPIFY_PLUS_KEY: {
        return (
          <img
            src={ShopifyPlusLogo}
            alt="google-logo"
            width={40}
            height={40}
            style={{
              marginLeft: '2rem',
            }}
          />
        );
      }
      case process.env.REACT_APP_ATLASSIAN_KEY: {
        return (
          <img
            src={AtlassianLogo}
            alt="google-logo"
            width={60}
            height={50}
            style={{
              marginLeft: '2rem',
            }}
          />
        );
      }
      default:
        return null;
    }
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          sx={{ backgroundColor: 'white' }}
          p="20px"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="1rem"
          >
            {renderMetadataIDPImage()}
            <Box>{renderMetadataIDPContent()}</Box>
          </Box>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          p="20px"
          width="100%"
          sx={{ backgroundColor: 'white' }}
          gap="1rem"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box width="20%" px={4}>
              <Typography variant="subtitle2">
                IdP Entity ID or Issuer
              </Typography>
            </Box>
            <Box width="80%">
              <TextField
                disabled
                variant="outlined"
                size="small"
                fullWidth
                value={(IDPMetadata as any)?.entity_id || ''}
                InputProps={{
                  endAdornment: (
                    <CopyToClipboard text={(IDPMetadata as any)?.entity_id}>
                      <ContentCopyIcon
                        sx={{
                          backgroundColor: 'white !important',
                          padding: '5px',
                        }}
                        onClick={CopiedToClipBoard}
                      />
                    </CopyToClipboard>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box width="20%" px={4}>
              <Typography variant="subtitle2">SAML Login URL</Typography>
            </Box>
            <Box width="80%">
              <TextField
                disabled
                variant="outlined"
                size="small"
                fullWidth
                value={(IDPMetadata as any)?.login_url || ''}
                InputProps={{
                  endAdornment: (
                    <CopyToClipboard text={(IDPMetadata as any)?.login_url}>
                      <ContentCopyIcon
                        sx={{
                          backgroundColor: 'white !important',
                          padding: '5px',
                        }}
                        onClick={CopiedToClipBoard}
                      />
                    </CopyToClipboard>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box width="20%" px={4}>
              <Typography variant="subtitle2">X.509 Certificate</Typography>
            </Box>
            <Box width="80%">
              <TextField
                disabled
                fullWidth
                multiline
                rows={10}
                maxRows={10}
                value={(IDPMetadata as any)?.x_509_certificate}
                InputProps={{
                  endAdornment: (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="2rem"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <DownloadIcon onClick={handleDownloadSAMLCertificate} />
                      <CopyToClipboard
                        text={(IDPMetadata as any)?.x_509_certificate}
                      >
                        <ContentCopyIcon
                          sx={{
                            backgroundColor: 'white !important',
                            padding: '5px',
                          }}
                          onClick={CopiedToClipBoard}
                        />
                      </CopyToClipboard>
                    </Box>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
        <br />
        <br />
      </Box>
    </Modal>
  );
}

export default SAMLIDPMetadata;
