import React from 'react';
import { Outlet } from 'react-router-dom';
import ProtectedRouteforDashboard from '../ProtectedRoutes/ProtectedRouteforDashboard';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

function ProtectedLayout() {
  return (
    <ProtectedRouteforDashboard>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </ProtectedRouteforDashboard>
  );
}

export default ProtectedLayout;
