import React from 'react';
import { Box, Typography } from '@mui/material';

function CircularTick() {
  return (
    <Box
      display="flex"
      width="1.5rem"
      height="1.5rem"
      sx={{ backgroundColor: 'green' }}
      borderRadius="50%"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="subtitle2" color="white">
        ✓
      </Typography>
    </Box>
  );
}
export default CircularTick;
