import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import LoginForm from './pages/Login/LoginForm';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import SignupForm from './pages/Signup/SignupForm';
import './index.css';
import Dashboard from './pages/Dashboard/Dashboard';
import GoogleConfiguration from './pages/GoogleConfiguration';
import AuthenticationSource from './pages/AuthenticationSource/AuthenticationSource';
import AddAuthenticationSourceView from './pages/AuthenticationSource/components/AddAuthenticationSource';
import EditAuthenticationSource from './pages/AuthenticationSource/components/EditAuthenticationSource';
import EditGoogleConfiguration from './pages/EditGoogleConfiguration/index';
import EditShopifyPlus from './pages/EditShopifyPlus/index';
import EditShopifyNonPlus from './pages/EditShopifyNonPlus/index';
import EditAtlassian from './pages/EditAtlassian/index';
import ProxyTable from './pages/Proxy/index';
import AddProxy from './pages/Proxy/Components/AddProxy';
import AddTenantView from './pages/Tenant/Components/AddTenantView';
import ShopifyNonPlus from './pages/ShopifyNonPlus/index';
import ShopifyPlus from './pages/ShopifyPlus/index';
import Atlassian from './pages/Atlassian/index';
import PolicyView from './pages/PolicyView/index';
import TenantSettings from './pages/TenantSettings/index';
import PublicLayout from './Router/PublicLayout';
import ProtectedLayout from './Router/ProtectedLayout';
import Office365 from './pages/Office365/Office365';
import EditOffice365 from './pages/EditOffice365CConfiguration/EditOffice365';
import './assets/css/app.css';

const publicRoutes = [
  {
    path: '/login',
    element: <LoginForm />,
  },
  {
    path: '/',
    element: <LoginForm />,
  },
  {
    path: '/getting-started',
    element: <SignupForm />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
];

const protectedRoutes = [
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/configure/authentication-source',
    element: <AuthenticationSource />,
  },
  {
    path: '/configure/casb/google',
    element: <GoogleConfiguration />,
  },
  {
    path: '/configure/casb/shopify',
    element: <ShopifyNonPlus />,
  },
  {
    path: '/configure/casb/atlassian',
    element: <Atlassian />,
  },
  {
    path: '/add/authentication-source',
    element: <AddAuthenticationSourceView />,
  },
  {
    path: '/edit/authentication-source',
    element: <EditAuthenticationSource />,
  },
  {
    path: '/edit/casb/google',
    element: <EditGoogleConfiguration />,
  },
  {
    path: '/edit/casb/shopify',
    element: <EditShopifyNonPlus />,
  },
  {
    path: '/configure/casb/shopifyplus',
    element: <ShopifyPlus />,
  },
  {
    path: '/edit/casb/shopifyplus',
    element: <EditShopifyPlus />,
  },
  {
    path: '/edit/casb/atlassian',
    element: <EditAtlassian />,
  },
  {
    path: '/configure/proxy',
    element: <ProxyTable />,
  },
  {
    path: '/add/proxy',
    element: <AddProxy />,
  },
  {
    path: '/edit/proxy',
    element: <AddProxy />,
  },
  {
    path: '/manage-policy',
    element: <PolicyView />,
  },
  {
    path: '/add/tenant',
    element: <AddTenantView />,
  },
  {
    path: '/settings',
    element: <TenantSettings />,
  },
  {
    path: '/configure/casb/office365',
    element: <Office365 />,
  },
  {
    path: '/edit/casb/office365',
    element: <EditOffice365 />,
  },
];

const router = createBrowserRouter([
  {
    element: <PublicLayout />,
    children: publicRoutes,
  },
  {
    element: <ProtectedLayout />,
    children: protectedRoutes,
  },
]);

export default router;
