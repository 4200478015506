import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Select,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { lightBackgroundButtonStyles } from '../../../styles/button';
import { getCookie, getDraftProxyApplication } from '../../../utils/utility';
import AddGroupModalCommon from '../../AddGroupModalCommon';

interface iUserOnboarding {
  userDetails: any;
  setUserDetails: Function;
}

interface UserDetails {
  group_name: string;
  username: string;
  password: string;
}

const ITEMS_PER_PAGE = 3;

function UserOnboarding({ userDetails, setUserDetails }: iUserOnboarding) {
  const [selectedGroup, setSelectedGroup] = React.useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNewGroup, setOpenNewGroup] = React.useState(false);
  const [reloadGroup, setReloadGroup] = useState(false);
  const openNewGroupModal = () => {
    setSelectedGroup([]);
    setOpenNewGroup(true);
  };
  const [allGroups, setAllGroups] = useState<any[]>([]);
  const [reload] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(allGroups.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const removeUserDetailsField = (index: number) => {
    setUserDetails((prevSettings: any) => {
      const newUserDetails = [...prevSettings.userdetails];
      newUserDetails.splice(index, 1);
      return {
        ...prevSettings,
        userdetails: newUserDetails,
      };
    });
  };
  useEffect(() => {
    async function getAllPolicies() {
      const token = await getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-groups`,
          {
            app: process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((response: any) => {
          if (response && response.data && response.data.GroupData) {
            setAllGroups(response.data.GroupData);
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_ALL_GROUPS_ERROR);
        });
    }
    getAllPolicies();
  }, [reloadGroup]);
  useEffect(() => {
    async function getDraftedConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
      );
      const users = DraftApplication?.success?.users || [];
      setUserDetails({
        type:
          DraftApplication?.success?.features?.sso === true
            ? 'sso'
            : 'multistaff',
        userdetails: users,
        enableCASB: DraftApplication?.success?.features?.casb,
      });
    }
    getDraftedConfiguration();
  }, [reload]);
  const customTableCell = {
    padding: '0 !important',
    paddingY: '8px !important',
  };
  const handleInputChange = (
    index: number,
    field: keyof typeof userDetails.userdetails[0],
    value: string,
  ) => {
    value = value?.trim();
    setUserDetails((prevSettings: any) => {
      const newUserDetails: UserDetails[] = [...prevSettings.userdetails];
      newUserDetails[index] = {
        ...newUserDetails[index],
        [field]: value,
      };
      return {
        ...prevSettings,
        userdetails: newUserDetails,
      };
    });
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const addUserDetailsField = () => {
    setUserDetails((prevSettings: any) => ({
      ...prevSettings,
      userdetails: [
        ...prevSettings.userdetails,
        { group_name: 'default', username: '', password: '' },
      ],
    }));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editGroupSettings = async (identifier: string) => {
    setReloadGroup(false);
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-groups`,
        {
          app: process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((response: any) => {
        if (response && response.data && response.data.GroupData) {
          response?.data?.GroupData.forEach((group: any) => {
            if (group?.identifier === identifier) {
              setSelectedGroup(group);
              setOpenNewGroup(true);
              setAnchorEl(null);
            }
          });
        }
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_GET_ALL_GROUPS_ERROR);
      });
  };
  const deleteGroupSettings = async (identifierID: string) => {
    setReloadGroup(false);
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/delete-group-settings`,
        {
          identifierID,
          app: process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        setReloadGroup(true);
        setAnchorEl(null);
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_DELETE_GROUP_ERROR);
      });
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box display="flex" flexDirection="row">
      <Box
        width="40%"
        display="flex"
        pt={6}
        pb={3}
        pl={6}
        pr={3}
        flexDirection="column"
        borderRight="1px solid #D9D9D9"
      >
        <Typography variant="h6">
          Add Groups and Manage User Configuration
        </Typography>
        <Typography variant="caption" color="#56526B" mt={0.5}>
          Add Groups to configure IAM Users
        </Typography>
        <ToastContainer />
        <Box display="flex" width="100%" justifyContent="space-between" mt={2}>
          <TextField
            id="input-with-icon-textfield"
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              width: '15rem',
            }}
          />
          <Button
            sx={{ ...lightBackgroundButtonStyles, width: '5rem' }}
            onClick={openNewGroupModal}
          >
            + Add
          </Button>
        </Box>
        <TableContainer sx={{ marginTop: '1rem' }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow sx={{ background: '#F4F2FF', color: '#6E6893' }}>
                <TableCell>Group Name</TableCell>
                <TableCell>Group Description</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allGroups.slice(startIndex, endIndex).map((element) => (
                <TableRow key={element._id}>
                  <TableCell>{element.identifier}</TableCell>
                  <TableCell>{element.description}</TableCell>
                  <TableCell align="right">
                    {element.identifier === 'default' ? (
                      <MoreVertIcon sx={{ cursor: 'not-allowed' }} />
                    ) : (
                      <MoreVertIcon
                        onClick={handleClick}
                        sx={{ cursor: 'pointer' }}
                      />
                    )}
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => editGroupSettings(element.identifier)}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => deleteGroupSettings(element._id)}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                sx={{ padding: '0 !important' }}
              >
                Previous
              </Button>
              &nbsp;&nbsp;
              <Typography variant="caption">
                Page
                {currentPage}
                of
                {totalPages}
              </Typography>
              <Button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                sx={{ padding: '0 !important' }}
              >
                Next
              </Button>
            </Box>
          </Table>
        </TableContainer>
      </Box>
      <AddGroupModalCommon
        appName={process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY}
        open={openNewGroup}
        setOpen={setOpenNewGroup}
        setReloadGroup={setReloadGroup}
        selectedGroup={selectedGroup}
        title=" Add Groups or Users from the IAM configuration"
      />
      <Box
        display="flex"
        flexDirection="column"
        width="50%"
        px={3}
        py={3}
        gap="1rem"
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="h6">Shopify Staff User Mapping</Typography>
            <Typography variant="caption" color="#56526B">
              Please Shopify Staff User Details
            </Typography>
          </Box>
          <Box>
            <Button
              sx={lightBackgroundButtonStyles}
              onClick={addUserDetailsField}
            >
              + Add Staff User
            </Button>
          </Box>
        </Box>
        <TableContainer sx={{ height: '20rem', overflowY: 'scroll' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={customTableCell}
                  style={{ paddingBottom: '15px' }}
                >
                  IAM User
                </TableCell>
                <TableCell sx={customTableCell}>Email</TableCell>
                <TableCell sx={customTableCell}>Password</TableCell>
                <TableCell sx={customTableCell}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userDetails.userdetails?.map((element: any, index: number) => (
                <TableRow>
                  <TableCell sx={customTableCell}>
                    <Select
                      id="groupDropdown"
                      size="small"
                      value={element.group_name || ''}
                      onChange={(e) => handleInputChange(index, 'group_name', e.target.value)}
                      style={{ minWidth: '10rem', maxWidth: '10rem' }}
                    >
                      {allGroups.map((group) => (
                        <MenuItem key={group} value={group.identifier}>
                          {group.identifier}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell sx={customTableCell}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      sx={{
                        backgroundColor: '#F6F6F6',
                        border: 'none !important',
                        width: '10rem',
                      }}
                      inputProps={{
                        style: {
                          fontSize: '15px',
                        },
                      }}
                      value={element.username || ''}
                      onChange={(e) => handleInputChange(index, 'username', e.target.value)}
                    />
                  </TableCell>
                  <TableCell sx={customTableCell}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      type="password"
                      sx={{
                        backgroundColor: '#F6F6F6',
                        border: 'none !important',
                        width: '10rem',
                      }}
                      inputProps={{
                        style: {
                          fontSize: '15px',
                        },
                      }}
                      disabled={element.password === '*********'}
                      value={element.password || ''}
                      onChange={(e) => handleInputChange(index, 'password', e.target.value)}
                    />
                  </TableCell>
                  <TableCell sx={customTableCell}>
                    <DeleteOutlineOutlinedIcon
                      fontSize="medium"
                      sx={{ color: '#E34614', marginLeft: '2rem' }}
                      onClick={() => removeUserDetailsField(index)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default UserOnboarding;
