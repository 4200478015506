import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  MenuItem,
  Divider,
  Grid,
  Paper,
  Button,
  TextField,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SettingsIcon from '@mui/icons-material/Settings';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from '../../../utils/utility';
import jiraLogo from '../../../assets/images/jira.png';
import confluenceLogo from '../../../assets/images/confluence.png';
import { updateAtlassianConfiguration } from '../../../store/features/CreateAuthSourceSlice';

interface AtlassianAppsProps {
  groupSettings: any;
  setGroupSettings: any;
  allPolicies: any;
}

const Item = styled(Paper)(({ theme }: any) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

function RenderAtlassianApps({
  groupSettings,
  setGroupSettings,
  allPolicies,
}: AtlassianAppsProps) {
  const dispatch = useDispatch();
  const [atlassianApps, setAtlassianApps] = React.useState([]);
  const [viewAdvanceSettings, setViewAdvanceSettings] = React.useState('');
  useEffect(() => {
    async function getAllAtlassianApps() {
      const token = await getCookie('token');
      const axiosConfig = {
        method: 'GET',
        url: `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-atlassian-apps`,
        params: {
          token,
        },
      };
      axios(axiosConfig)
        .then((response: any) => {
          if (response && response?.data && response?.data?.atlassianApps) {
            setAtlassianApps(response.data.atlassianApps);
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_ATLASSIAN_APPS_ERROR);
        });
    }
    getAllAtlassianApps();
  }, []);
  const handleAtlassianAppPolicySettings = (event: any) => {
    var _id = '';
    const { name, value } = event.target;
    allPolicies?.forEach((data: any) => {
      if (data.identifier === value) {
        _id = data._id;
      }
    });
    setGroupSettings((prevValue: any) => ({
      ...prevValue,
      atlassianAppPolicy: {
        ...prevValue.atlassianAppPolicy,
        [name]: _id,
      },
    }));
  };
  const handleRestrictionType = (event: any) => {
    var RestrictionApp: string[] = [];
    var AllowedApp: string[] = [];
    var DisableApp: string[] = [];
    const { name, value } = event.target;
    const Settings = groupSettings;
    Settings?.restrictedApps?.forEach((app: any) => {
      RestrictionApp?.push(app);
    });
    Settings?.allowedApps?.forEach((app: any) => {
      AllowedApp?.push(app);
    });
    Settings?.blockApps?.forEach((app: any) => {
      DisableApp?.push(app);
    });

    if (AllowedApp?.includes(name)) {
      AllowedApp = AllowedApp?.filter((v: string) => v !== name);
      setGroupSettings((prevValue: any) => ({
        ...prevValue,
        allowedApps: AllowedApp,
      }));
    }

    if (RestrictionApp?.includes(name)) {
      RestrictionApp = RestrictionApp?.filter((v: string) => v !== name);
      setGroupSettings((prevValue: any) => ({
        ...prevValue,
        restrictedApps: RestrictionApp,
      }));
    }

    if (DisableApp?.includes(name)) {
      DisableApp = DisableApp.filter((v: string) => v !== name);
      setGroupSettings((prevValue: any) => ({
        ...prevValue,
        blockApps: DisableApp,
      }));
    }

    if (value === 'AppRestriction') {
      RestrictionApp.push(name);
      setGroupSettings((prevValue: any) => ({
        ...prevValue,
        restrictedApps: RestrictionApp,
      }));
    } else if (value === 'DisableApp') {
      DisableApp.push(name);
      setGroupSettings((prevValue: any) => ({
        ...prevValue,
        blockApps: DisableApp,
      }));
    } else if (value === 'NoAppRestricition') {
      AllowedApp.push(name);
      setGroupSettings((prevValue: any) => ({
        ...prevValue,
        allowedApps: AllowedApp,
      }));
    } else {
      let defaultPolicyID = '';
      allPolicies?.forEach((element: any) => {
        if (element.identifier === 'default') {
          defaultPolicyID = element._id;
        }
      });
      setGroupSettings((prevValue: any) => ({
        ...prevValue,
        atlassianAppPolicy: {
          ...prevValue.atlassianAppPolicy,
          [name]: defaultPolicyID,
        },
      }));
    }
  };

  useEffect(() => {
    function updateGroupDetails() {
      // console.log(groupSettings);
      dispatch(
        updateAtlassianConfiguration({
          type: 'group-settings',
          data: groupSettings,
        }),
      );
    }
    updateGroupDetails();
  }, [groupSettings]);

  const handleAdvanceSettingsForm = (appName: string) => {
    setViewAdvanceSettings(appName);
  };

  const addJiraFormFields = () => {
    setGroupSettings((prevState: any) => ({
      ...prevState,
      granular_restriction: {
        ...prevState.granular_restriction,
        jira: {
          ...prevState.granular_restriction.jira,
          projects: {
            ...prevState.granular_restriction.jira.projects,
            boards: [
              ...prevState.granular_restriction.jira.projects.boards,
              { name: '', access: false },
            ],
          },
        },
      },
    }));
  };
  const removeJiraFormFields = (i: number) => {
    const newFormValues = [
      ...groupSettings.granular_restriction.jira.projects.boards,
    ];
    newFormValues.splice(i, 1);
    setGroupSettings((prevState: any) => ({
      ...prevState,
      granular_restriction: {
        ...prevState.granular_restriction,
        jira: {
          ...prevState.granular_restriction.jira,
          projects: {
            ...prevState.granular_restriction.jira.projects,
            boards: newFormValues,
          },
        },
      },
    }));
  };

  const addConfluenceFormFields = () => {
    setGroupSettings((prevState: any) => ({
      ...prevState,
      granular_restriction: {
        ...prevState.granular_restriction,
        confluence: {
          ...prevState.granular_restriction.confluence,
          spaces: {
            ...prevState.granular_restriction.confluence.spaces,
            list: [
              ...prevState.granular_restriction.confluence.spaces.list,
              { name: '', access: false },
            ],
          },
        },
      },
    }));
  };
  const removeConfluenceFormFields = (i: number) => {
    const newFormValues = [
      ...groupSettings.granular_restriction.confluence.spaces.list,
    ];
    newFormValues.splice(i, 1);
    setGroupSettings((prevState: any) => ({
      ...prevState,
      granular_restriction: {
        ...prevState.granular_restriction,
        confluence: {
          ...prevState.granular_restriction.confluence,
          spaces: {
            ...prevState.granular_restriction.confluence.spaces,
            list: newFormValues,
          },
        },
      },
    }));
  };

  const handleChangeJiraSettings = (index: number, event: any) => {
    const { value } = event.target;
    const data = [...groupSettings.granular_restriction.jira.projects.boards];
    data[index] = { ...data[index], name: value };
    setGroupSettings((prevState: any) => ({
      ...prevState,
      granular_restriction: {
        ...prevState.granular_restriction,
        jira: {
          ...prevState.granular_restriction.jira,
          projects: {
            ...prevState.granular_restriction.jira.projects,
            boards: data,
          },
        },
      },
    }));
  };

  const handleJiraAccessToggle = (index: number, event: any) => {
    const data = [...groupSettings.granular_restriction.jira.projects.boards];
    data[index] = { ...data[index], access: event.target.checked };
    setGroupSettings((prevState: any) => ({
      ...prevState,
      granular_restriction: {
        ...prevState.granular_restriction,
        jira: {
          ...prevState.granular_restriction.jira,
          projects: {
            ...prevState.granular_restriction.jira.projects,
            boards: data,
          },
        },
      },
    }));
  };

  const handleChangeConfluenceSettings = (index: number, event: any) => {
    const { value } = event.target;
    const data = [...groupSettings.granular_restriction.confluence.spaces.list];
    data[index] = { ...data[index], name: value };
    setGroupSettings((prevState: any) => ({
      ...prevState,
      granular_restriction: {
        ...prevState.granular_restriction,
        confluence: {
          ...prevState.granular_restriction.confluence,
          spaces: {
            ...prevState.granular_restriction.confluence.spaces,
            list: data,
          },
        },
      },
    }));
  };

  const handleConfluenceAccessToggle = (index: number, event: any) => {
    const data = [...groupSettings.granular_restriction.confluence.spaces.list];
    data[index] = { ...data[index], access: event.target.checked };
    setGroupSettings((prevState: any) => ({
      ...prevState,
      granular_restriction: {
        ...prevState.granular_restriction,
        confluence: {
          ...prevState.granular_restriction.confluence,
          spaces: {
            ...prevState.granular_restriction.confluence.spaces,
            list: data,
          },
        },
      },
    }));
  };

  const handleJiraParentAccessToggle = (event: any) => {
    setGroupSettings((prevState: any) => ({
      ...prevState,
      granular_restriction: {
        ...prevState.granular_restriction,
        jira: {
          ...prevState.granular_restriction.jira,
          projects: {
            ...prevState.granular_restriction.jira.projects,
            parentAccess: event.target.checked,
          },
        },
      },
    }));
  };

  const handleConfluenceParentAccessToggle = (event: any) => {
    setGroupSettings((prevState: any) => ({
      ...prevState,
      granular_restriction: {
        ...prevState.granular_restriction,
        confluence: {
          ...prevState.granular_restriction.confluence,
          spaces: {
            ...prevState.granular_restriction.confluence.spaces,
            parentAccess: event.target.checked,
          },
        },
      },
    }));
  };

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" px={6} py={2}>
        <ArrowBackIcon
          sx={{
            marginRight: '1rem',
            display: viewAdvanceSettings === '' ? 'none' : 'flex',
          }}
          onClick={() => setViewAdvanceSettings('')}
        />
        <Typography variant="subtitle2">Application Permissions</Typography>
        {/* <TextField
            id="input-with-icon-textfield"
            size="small"
            variant="outlined"
            sx={{ marginRight: '3rem' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          /> */}
      </Box>
      <Box
        display={viewAdvanceSettings ? 'none' : 'flex'}
        gap={4}
        px={6}
        width="100%"
      >
        {atlassianApps?.map((app: any) => (
          <Grid item width={300}>
            <Item>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                py={2}
                px={2}
                sx={{ gap: '1rem', minHeight: '2.5rem' }}
              >
                <img
                  src={`${process.env.REACT_APP_NODE_BACKEND_URL}/api${app.imageSrc}`}
                  alt="atlassian"
                  width="40"
                />
                <Typography variant="subtitle1">{app.name}</Typography>
              </Box>
              <Divider />
              <Box display="flex" width="100%">
                <Button
                  sx={{ fontSize: '0.75rem', marginLeft: '7rem' }}
                  onClick={() => handleAdvanceSettingsForm(app.appKey)}
                >
                  <SettingsIcon sx={{ width: '0.75rem' }} />
                  &nbsp;&nbsp;Advance Settings
                </Button>
              </Box>
              <Box pb={2} pt={1} px={2} sx={{ gap: '0.75rem' }}>
                <FormControl sx={{ gap: '0.5rem', width: '100%' }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name={app.appKey}
                    onChange={handleRestrictionType}
                    value={
                      (groupSettings?.allowedApps?.includes(app.appKey)
                        ? 'NoAppRestricition'
                        : groupSettings?.restrictedApps?.includes(app.appKey)
                          ? 'AppRestriction'
                          : groupSettings?.blockApps?.includes(app.appKey)
                            ? 'DisableApp'
                            : 'CustomRestriction') || 'NoAppRestricition'
                    }
                  >
                    <FormControlLabel
                      value="AppRestriction"
                      control={<Radio />}
                      label="App Restriction"
                    />
                    <FormControlLabel
                      value="NoAppRestricition"
                      control={<Radio />}
                      label="No App Restriction"
                    />
                    <FormControlLabel
                      value="DisableApp"
                      control={<Radio />}
                      label="Disable App"
                    />
                    <FormControlLabel
                      value="CustomRestriction"
                      control={<Radio />}
                      label="Custom Restriction"
                    />
                    <Box
                      display="flex"
                      flexDirection="row"
                      width="100%"
                      alignItems="center"
                      gap="0.5rem"
                      justifyContent="space-between"
                    >
                      <Typography variant="caption" align="left">
                        Select Policy
                      </Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        sx={{ height: '2rem', width: '70%' }}
                        name={app.appKey}
                        onChange={handleAtlassianAppPolicySettings}
                        disabled={
                          groupSettings.allowedApps?.includes(app.appKey)
                          || groupSettings.restrictedApps?.includes(app.appKey)
                          || groupSettings.blockApps?.includes(app.appKey)
                        }
                        value={(() => {
                          let selectedIdentifier = '';
                          allPolicies?.forEach((data: any) => {
                            if (
                              data?._id
                              === groupSettings?.atlassianAppPolicy?.[app?.appKey]
                            ) {
                              selectedIdentifier = data.identifier;
                            }
                          });
                          return selectedIdentifier;
                        })()}
                        defaultValue="default"
                      >
                        {allPolicies?.map((source: any) => (
                          <MenuItem
                            key={source.identifier}
                            value={source.identifier}
                          >
                            {source.identifier}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            </Item>
          </Grid>
        ))}
      </Box>
      <Box
        display={viewAdvanceSettings ? 'flex' : 'none'}
        gap={4}
        px={6}
        width="100%"
        height="100%"
        overflow="auto"
      >
        <Box width="100%">
          {viewAdvanceSettings === 'jira' && (
            <Box px={2} width="100%">
              <Box display="flex" alignItems="center" gap="1rem" py={1}>
                <img src={jiraLogo} alt="jira" width={30} />
                <Typography py={2} color="black">
                  Jira Board Advance Settings
                </Typography>
              </Box>
              <Divider />
              <Box
                display="flex"
                flexDirection="row"
                gap="2rem"
                width="100%"
                alignItems="center"
                py={1}
              >
                <Typography variant="subtitle2">
                  Default Access Control
                </Typography>
                <Switch
                  name="parentAccess"
                  onChange={handleJiraParentAccessToggle}
                  checked={
                    groupSettings?.granular_restriction?.jira?.projects
                      ?.parentAccess
                  }
                />
              </Box>
              <Divider />
              <Box>
                <Button onClick={addJiraFormFields}>+ Add Boards</Button>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Board Name</TableCell>
                    <TableCell>Access (Allow / Deny)</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupSettings?.granular_restriction?.jira?.projects?.boards?.map(
                    (element: any, index: number) => (
                      <TableRow>
                        <TableCell>
                          <TextField
                            id={`board-name-${index}`}
                            size="small"
                            label="Board Name"
                            name="name"
                            variant="outlined"
                            sx={{
                              backgroundColor: '#F6F6F6',
                              border: 'none !important',
                              width: 200,
                            }}
                            inputProps={{
                              style: {
                                fontSize: '15px',
                              },
                            }}
                            value={element.name}
                            onChange={(event) => handleChangeJiraSettings(index, event)}
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            name={`access-${index}`}
                            onChange={(event) => handleJiraAccessToggle(index, event)}
                            checked={element?.access}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="delete"
                            onClick={() => removeJiraFormFields(index)}
                            sx={{ color: '#E34614' }}
                          >
                            <RemoveCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </Box>
          )}
          {viewAdvanceSettings === 'confluence' && (
            <Box px={2} width="100%">
              <Box display="flex" alignItems="center" gap="1rem" py={1}>
                <img src={confluenceLogo} alt="confluence" width={30} />
                <Typography py={2} color="black">
                  Confluence Space Advance Settings
                </Typography>
              </Box>
              <Divider />
              <Box
                display="flex"
                flexDirection="row"
                gap="2rem"
                width="100%"
                alignItems="center"
                py={1}
              >
                <Typography variant="subtitle2">
                  Default Access Control
                </Typography>
                <Switch
                  name="parentAccess"
                  onChange={handleConfluenceParentAccessToggle}
                  checked={
                    groupSettings?.granular_restriction?.confluence?.spaces
                      ?.parentAccess
                  }
                />
              </Box>
              <Divider />
              <Box>
                <Button onClick={addConfluenceFormFields}>+ Add Spaces</Button>
              </Box>
              <Box display="flex" flexDirection="column">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Space Name</TableCell>
                      <TableCell>Access (Allow / Deny)</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupSettings?.granular_restriction?.confluence?.spaces?.list?.map(
                      (element: any, index: number) => (
                        <TableRow>
                          <TableCell>
                            <TextField
                              id={`space-name-${index}`}
                              size="small"
                              label="Space Name"
                              name="name"
                              variant="outlined"
                              sx={{
                                backgroundColor: '#F6F6F6',
                                border: 'none !important',
                                width: 200,
                              }}
                              inputProps={{
                                style: {
                                  fontSize: '15px',
                                },
                              }}
                              value={element.name}
                              onChange={(event) => handleChangeConfluenceSettings(index, event)}
                            />
                          </TableCell>
                          <TableCell>
                            <Switch
                              name={`access-${index}`}
                              onChange={(event) => handleConfluenceAccessToggle(index, event)}
                              checked={element?.access}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              aria-label="delete"
                              onClick={() => removeConfluenceFormFields(index)}
                              sx={{ color: '#E34614' }}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default RenderAtlassianApps;
