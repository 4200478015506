import React, { useEffect } from 'react';
import {
  Box, Typography, MenuItem, Divider, Grid, Paper,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import axios from 'axios';
import { getCookie } from '../../../utils/utility';
import { updateOffice365Configuration } from '../../../store/features/CreateAuthSourceSlice';

interface Office365AppsProps {
  groupSettings: any;
  setGroupSettings: any;
  allPolicies: any;
}

const Item = styled(Paper)(({ theme }: any) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

export const RenderOffice365Apps = React.memo(
  ({ groupSettings, setGroupSettings, allPolicies }: Office365AppsProps) => {
    const dispatch = useDispatch();
    const [office365Apps, setOffice365Apps] = React.useState([]);
    useEffect(() => {
      async function getAllOffice365Apps() {
        const token = await getCookie('token');
        const axiosConfig = {
          method: 'GET',
          url: `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-office365-apps`,
          params: {
            token,
          },
        };
        axios(axiosConfig)
          .then((response: any) => {
            if (response && response?.data && response?.data?.office365Apps) {
              setOffice365Apps(response.data.office365Apps);
            }
          })
          .catch(() => {
            toast.error(process.env.REACT_APP_GET_OFFICE365_APPS_ERROR);
          });
      }
      getAllOffice365Apps();
    }, []);
    const handleOffice365AppPolicySettings = (event: any) => {
      const { name, value } = event.target;
      setGroupSettings((prevValue: any) => ({
        ...prevValue,
        office365AppPolicy: {
          ...prevValue.office365AppPolicy,
          [name]: value,
        },
      }));
    };
    const handleRestrictionType = (event: any) => {
      var RestrictionApp: string[] = [];
      var AllowedApp: string[] = [];
      var DisableApp: string[] = [];
      const { name, value } = event.target;
      const Settings = groupSettings;
      Settings?.restrictedApps?.forEach((app: any) => {
        RestrictionApp?.push(app);
      });
      Settings?.allowedApps?.forEach((app: any) => {
        AllowedApp?.push(app);
      });
      Settings?.blockApps?.forEach((app: any) => {
        DisableApp?.push(app);
      });

      if (AllowedApp?.includes(name)) {
        AllowedApp = AllowedApp?.filter((v: string) => v !== name);
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          allowedApps: AllowedApp,
        }));
      }

      if (RestrictionApp?.includes(name)) {
        RestrictionApp = RestrictionApp?.filter((v: string) => v !== name);
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          restrictedApps: RestrictionApp,
        }));
      }

      if (DisableApp?.includes(name)) {
        DisableApp = DisableApp.filter((v: string) => v !== name);
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          blockApps: DisableApp,
        }));
      }

      const newoffice365AppPolicy = {
        ...groupSettings?.office365AppPolicy,
      };
      delete newoffice365AppPolicy?.[name];
      setGroupSettings((prevValue: any) => ({
        ...prevValue,
        office365AppPolicy: newoffice365AppPolicy,
      }));

      if (value === 'AppRestriction') {
        RestrictionApp.push(name);
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          restrictedApps: RestrictionApp,
        }));
      } else if (value === 'DisableApp') {
        DisableApp.push(name);
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          blockApps: DisableApp,
        }));
      } else if (value === 'NoAppRestricition') {
        AllowedApp.push(name);
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          allowedApps: AllowedApp,
        }));
      } else {
        let defaultPolicyID = '';
        allPolicies.forEach((element: any) => {
          if (element.identifier === 'default') {
            defaultPolicyID = element._id;
          }
        });
        setGroupSettings((prevValue: any) => ({
          ...prevValue,
          office365AppPolicy: {
            ...newoffice365AppPolicy,
            [name]: defaultPolicyID,
          },
        }));
      }
    };

    useEffect(() => {
      function updateGroupDetails() {
        dispatch(
          updateOffice365Configuration({
            type: 'group-settings',
            data: groupSettings,
          }),
        );
      }
      updateGroupDetails();
    }, [groupSettings]);
    return (
      <>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2" px={6} py={3}>
            Applicaton Permissions
          </Typography>
        </Box>
        <Grid container spacing={4} px={6}>
          {office365Apps.map((app: any) => (
            <Grid item xs={4}>
              <Item>
                <Box
                  display="flex"
                  alignItems="center"
                  py={2}
                  px={2}
                  sx={{ gap: '1rem', minHeight: '2.5rem' }}
                >
                  <img
                    src={`${process.env.REACT_APP_NODE_BACKEND_URL}/api${app.imageSrc}`}
                    alt="office365"
                    width="40"
                  />
                  <Typography variant="subtitle1">{app.name}</Typography>
                </Box>
                <Divider />
                <Box py={2} px={2} sx={{ gap: '0.75rem' }}>
                  <FormControl sx={{ gap: '0.5rem', width: '100%' }}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name={app.appKey}
                      onChange={handleRestrictionType}
                      value={
                        (groupSettings?.allowedApps?.includes(app.appKey)
                          ? 'NoAppRestricition'
                          : groupSettings?.restrictedApps?.includes(app.appKey)
                            ? 'AppRestriction'
                            : groupSettings?.blockApps?.includes(app.appKey)
                              ? 'DisableApp'
                              : 'CustomRestriction') || 'NoAppRestricition'
                      }
                    >
                      <FormControlLabel
                        value="AppRestriction"
                        control={<Radio />}
                        label="App Restriction"
                      />
                      <FormControlLabel
                        value="NoAppRestricition"
                        control={<Radio />}
                        label="No App Restriction"
                      />
                      <FormControlLabel
                        value="DisableApp"
                        control={<Radio />}
                        label="Disable App"
                      />
                      <FormControlLabel
                        value="CustomRestriction"
                        control={<Radio />}
                        label="Custom Restriction"
                      />
                      <Box
                        display="flex"
                        flexDirection="row"
                        width="100%"
                        alignItems="center"
                        gap="0.5rem"
                        justifyContent="space-between"
                      >
                        <Typography variant="caption" align="left">
                          Select Policy
                        </Typography>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          size="small"
                          sx={{ height: '2rem', width: '70%' }}
                          name={app.appKey}
                          onChange={handleOffice365AppPolicySettings}
                          disabled={
                            groupSettings.allowedApps?.includes(app.appKey)
                            || groupSettings.restrictedApps?.includes(
                              app.appKey,
                            )
                            || groupSettings.blockApps?.includes(app.appKey)
                          }
                          value={
                            groupSettings?.office365AppPolicy?.[app.appKey]
                          }
                          defaultValue="default"
                        >
                          {allPolicies.map((source: any) => (
                            <MenuItem
                              key={source.identifier}
                              value={source._id}
                            >
                              {source.identifier}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Item>
            </Grid>
          ))}
        </Grid>
      </>
    );
  },
);

export default RenderOffice365Apps;
