import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToastContainer, toast } from 'react-toastify';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  getCookie,
  getUser,
  updateAuthenticationSource,
  testSAMLConnection,
} from '../../../utils/utility';
import {
  lightBackgroundButtonStyles,
  darkBackgroundButtonStyles,
} from '../../../styles/button';
import SPMetadataModal from '../../../components/SPMetadataModal';
import ErrorMessage from '../../../components/ErrorMessage';
import MainLayout from '../../../components/MainLayout';
import ImportIDPMetadata from '../../../components/ImportIDPMetadata';
import {
  downloadXML,
  generateAndOpenMetadata,
} from '../../../utils/SAMLAuthentication';

function EditAuthenticationSource() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedAuthSource, setSelectedAuthSource] = useState<any>([]);
  const [spMetadata, setSpMetadata] = React.useState<Object | null>(null);
  const [open, setOpen] = useState(false);
  const [importIDPMetadata, setImportIDPMetadata] = React.useState({
    open: false,
  });
  const [
    authenticationSourceDetails,
    setAuthenticationSourceDetails,
  ] = useState({
    authenticationName: null,
    issuer: null,
    loginURL: null,
    logoutURL: null,
    bindingType: 'HttpRedirect',
    X509Certificate: null,
    _id: null,
  });
  useEffect(() => {
    async function setEditingAuthenticationSource() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const identifierValue = urlParams.has('identifier')
        ? urlParams.get('identifier')
        : '';
      const userDetails = await getUser();
      const token = await getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-configured-authentication-source`,
          {
            userDetails,
            token,
          },
        )
        .then((res) => {
          if (res.data && res.data.authentication_source) {
            res.data.authentication_source.forEach((authSource: any) => {
              if (authSource.identifier === identifierValue) {
                setSelectedAuthSource(authSource);
                setSpMetadata(authSource);
                setAuthenticationSourceDetails({
                  authenticationName: authSource.identifier,
                  issuer: authSource.saml.entity_id,
                  loginURL: authSource.saml.login_url,
                  logoutURL: authSource.saml.logout_url,
                  bindingType: authSource.saml.binding_type,
                  X509Certificate: authSource.saml.x_509_certificate,
                  _id: authSource._id,
                });
              }
            });
          }
        })
        .catch(() => {
          toast.error(
            process.env.REACT_APP_GET_CONFIGURED_AUTHENTICATION_SOURCE_ERROR,
          );
        });
    }
    setEditingAuthenticationSource();
  }, []);

  const handleAuthenticationSource = (event: any) => {
    var { value } = event.target;
    event.preventDefault();
    const { name } = event.target;
    value = value?.trim();
    setAuthenticationSourceDetails((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleUpdateAuthenticationSource = () => {
    updateAuthenticationSource(authenticationSourceDetails)
      .then(() => {
        navigate('/configure/authentication-source');
      })
      .catch((error: any) => {
        setErrorMessage(error.message);
      });
  };

  const getSPMetadata = () => {
    setSpMetadata(selectedAuthSource);
    setOpen(true);
  };

  const uploadMetaIDPdata = async () => {
    setImportIDPMetadata((prevState) => ({ ...prevState, open: true }));
  };

  const generateAndOpenMetadataHandler = async () => {
    await generateAndOpenMetadata({
      authenticationSourceDetails,
      setAuthenticationSourceDetails,
      setSpMetadata,
    });
  };

  const downloadXMLHandler = async () => {
    await downloadXML({
      authenticationSourceDetails,
      setAuthenticationSourceDetails,
      spMetadata,
      setSpMetadata,
    });
  };

  const isScreenSmall = useMediaQuery((mediaQueryTheme: any) => mediaQueryTheme.breakpoints.down('sm'));
  return (
    <MainLayout>
      <Box display="flex" flexDirection="column" overflow="hidden">
        <ToastContainer />
        <Box
          display="flex"
          width="100%"
          pl={10}
          pt={5}
          pb={3}
          borderBottom="1px solid lightgray"
        >
          <Typography variant="h6">Edit Authentication - SAML</Typography>
        </Box>
        <Box display="flex" py={2}>
          <Box
            pl={7}
            pr={3}
            py={1}
            display="flex"
            flexDirection="column"
            width="70%"
            alignItems="center"
            gap="0.75rem"
            height={isScreenSmall ? 'calc(100vh - 200px)' : '65vh'}
            overflow="auto"
          >
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <TextField
              required
              fullWidth
              id="outlined-basic"
              size="small"
              name="authenticationName"
              label="Authentication Name"
              variant="outlined"
              sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
              value={
                (authenticationSourceDetails as any).authenticationName || ''
              }
              onChange={handleAuthenticationSource}
              disabled
            />
            <TextField
              required
              fullWidth
              id="outlined-basic"
              size="small"
              name="issuer"
              label="IdP Entity ID or Issuer"
              variant="outlined"
              value={(authenticationSourceDetails as any).issuer || ''}
              sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
              disabled={
                !authenticationSourceDetails.authenticationName && !spMetadata
              }
              onChange={handleAuthenticationSource}
            />
            <TextField
              fullWidth
              required
              id="outlined-basic"
              size="small"
              label="SAML Login URL"
              name="loginURL"
              variant="outlined"
              value={(authenticationSourceDetails as any).loginURL || ''}
              sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
              disabled={
                !authenticationSourceDetails.authenticationName && !spMetadata
              }
              onChange={handleAuthenticationSource}
            />
            <TextField
              fullWidth
              required
              id="outlined-basic"
              size="small"
              label="SAML Logout URL"
              name="logoutURL"
              variant="outlined"
              sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
              disabled={
                !authenticationSourceDetails.authenticationName && !spMetadata
              }
              value={(authenticationSourceDetails as any).logoutURL || ''}
              onChange={handleAuthenticationSource}
            />
            <Box width="100%">
              <Typography variant="caption">
                Select Binding Type for SSO Request:
              </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="bindingType"
                value={authenticationSourceDetails.bindingType || ''}
                onChange={handleAuthenticationSource}
              >
                <FormControlLabel
                  disabled={
                    !authenticationSourceDetails.authenticationName
                    && !spMetadata
                  }
                  value="HttpRedirect"
                  control={<Radio />}
                  label="HTTP-Redirect Binding"
                  sx={{ fontSize: '13px' }}
                />
                <FormControlLabel
                  value="HttpPost"
                  disabled={
                    !authenticationSourceDetails.authenticationName
                    && !spMetadata
                  }
                  control={<Radio />}
                  label="HTTP-Post Binding"
                  sx={{ fontSize: '15px' }}
                />
              </RadioGroup>
            </Box>
            <Box width="100%">
              <Typography variant="caption">X.509 Certificate</Typography>
              <TextField
                required
                fullWidth
                multiline
                name="X509Certificate"
                rows={7}
                maxRows={7}
                value={authenticationSourceDetails.X509Certificate || ''}
                disabled={
                  !authenticationSourceDetails.authenticationName && !spMetadata
                }
                onChange={handleAuthenticationSource}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            px={3}
            width="40%"
            flexDirection="column"
            gap="1rem"
          >
            <Box display="flex" flexDirection="column" gap="1rem">
              <Box display="flex" flexDirection="column" gap="1rem">
                <Typography>SP Metadata</Typography>
                <Box display="flex" flexDirection="column" gap="1rem">
                  <Box display="flex" gap="1rem">
                    <Button
                      onClick={getSPMetadata}
                      disabled={
                        !(authenticationSourceDetails as any).authenticationName
                      }
                      startIcon={<VisibilityIcon />}
                      sx={lightBackgroundButtonStyles}
                    >
                      View Metadata
                    </Button>
                    <Button
                      onClick={generateAndOpenMetadataHandler}
                      disabled={
                        !(authenticationSourceDetails as any).authenticationName
                      }
                      startIcon={<InsertLinkIcon />}
                      sx={lightBackgroundButtonStyles}
                    >
                      Metadata URL
                    </Button>
                  </Box>
                  <Button
                    onClick={downloadXMLHandler}
                    disabled={
                      !(authenticationSourceDetails as any).authenticationName
                    }
                    startIcon={<FileDownloadOutlinedIcon />}
                    sx={lightBackgroundButtonStyles}
                  >
                    Download Metadata
                  </Button>
                </Box>
              </Box>
              <Divider />
              <Typography>IDP Metadata</Typography>
              <Button
                onClick={uploadMetaIDPdata}
                sx={{ ...darkBackgroundButtonStyles, width: '12rem' }}
              >
                <FileUploadOutlinedIcon />
                &nbsp; Upload IDP Metadata
              </Button>
              <Divider />
              <Typography> Test Connection</Typography>
              <Button
                sx={{ ...darkBackgroundButtonStyles, width: '12rem' }}
                onClick={() => testSAMLConnection(
                  selectedAuthSource?._id,
                  String(process.env.REACT_APP_COMMON_AUTH_SOURCE),
                )}
              >
                Test Connection
              </Button>
              <ImportIDPMetadata
                open={importIDPMetadata.open}
                setOpen={setImportIDPMetadata}
                authenticationSource={authenticationSourceDetails}
                setAuthenticationSource={setAuthenticationSourceDetails}
              />
              <Divider />
            </Box>
            <SPMetadataModal
              open={open}
              setOpen={setOpen}
              SpMetadata={spMetadata}
            />
          </Box>
        </Box>
        <Box
          position="absolute"
          bottom="0"
          width="80%"
          display="flex"
          justifyContent="flex-end"
          bgcolor="background.paper"
          borderTop="1px solid lightgray"
          p={1.5}
        >
          <Box display="flex" mr={2}>
            <Button
              sx={{ ...lightBackgroundButtonStyles, width: '5rem' }}
              href="/configure/authentication-source"
            >
              Cancel
            </Button>
          </Box>
          <Box display="flex">
            <Button
              sx={darkBackgroundButtonStyles}
              onClick={handleUpdateAuthenticationSource}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default EditAuthenticationSource;
