export const lightBackgroundButtonStyles = {
  width: '12rem',
  padding: '3px',
  fontSize: '0.75rem',
  backgroundColor: 'white',
  color: '#2D3748',
  border: '1px solid #2D3748',
  '&:hover': {
    backgroundColor: 'white',
    color: '#2D3748',
    border: '1px solid #2D3748',
  },
  '&:disabled': {
    backgroundColor: 'lightgray',
    border: 'none',
  },
};
export const darkBackgroundButtonStyles = {
  width: '5rem',
  padding: '3px',
  fontSize: '0.75rem',
  backgroundColor: '#2D3748',
  color: 'white',
  border: 'none',
  '&:hover': {
    backgroundColor: '#2D3748',
    color: 'white',
    border: 'none',
  },
  '&:disabled': {
    backgroundColor: 'lightgray',
  },
};
