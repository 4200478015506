import { toast } from 'react-toastify';
import { getUser, generateMetaData } from './utility';

export const downloadXML = async ({
  authenticationSourceDetails,
  setAuthenticationSourceDetails,
  setSpMetadata,
}: any) => {
  const user = await getUser();
  if (!authenticationSourceDetails.authenticationName) {
    toast.error('Please add identifier name');
    return;
  }
  if (!authenticationSourceDetails?._id) {
    await generateMetaData(authenticationSourceDetails)
      .then(async (data) => {
        setAuthenticationSourceDetails((prevValue: any) => ({
          ...prevValue,
          authenticationName: (data as any).success.sp_metadata.identifier,
          _id: (data as any).success.sp_metadata._id,
        }));
        setSpMetadata((data as any).success.sp_metadata);
        const response = await fetch(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${
            user.customer_id
          }/${user?.current_active_tenant_id}/${
            (data as any).success.sp_metadata._id
          }/generate-sp-metadata`,
        );
        const blob = await response.blob();

        const url = window.URL.createObjectURL(
          new Blob([blob], { type: 'application/xml' }),
        );
        const a = document.createElement('a');
        a.href = url;
        a.download = 'sp-metadata.xml';
        a.click();
      })
      .catch((error) => {
        toast.error(error);
      });
  } else {
    const response = await fetch(
      `${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${user.customer_id}/${user?.current_active_tenant_id}/${authenticationSourceDetails?._id}/generate-sp-metadata`,
    );
    const blob = await response.blob();

    // Create a download link and click it
    const url = window.URL.createObjectURL(
      new Blob([blob], { type: 'application/xml' }),
    );
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sp-metadata.xml';
    a.click();
  }
};

export const generateAndOpenMetadata = async ({
  authenticationSourceDetails,
  setAuthenticationSourceDetails,
  setSpMetadata,
}: any) => {
  const user = await getUser();
  if (!authenticationSourceDetails.authenticationName) {
    toast.error('Please add identifier name');
    return;
  }
  if (!authenticationSourceDetails?._id) {
    await generateMetaData(authenticationSourceDetails)
      .then((data) => {
        setAuthenticationSourceDetails((prevValue: any) => ({
          ...prevValue,
          authenticationName: (data as any).success.sp_metadata.identifier,
          _id: (data as any).success.sp_metadata._id,
        }));
        setSpMetadata((data as any).success.sp_metadata);
        window.open(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${
            user.customer_id
          }/${user?.current_active_tenant_id}/${
            (data as any).success.sp_metadata._id
          }/generate-sp-metadata`,
          '_blank',
        );
      })
      .catch((error) => {
        toast.error(error);
      });
  } else {
    window.open(
      `${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${user.customer_id}/${user?.current_active_tenant_id}/${authenticationSourceDetails?._id}/generate-sp-metadata`,
      '_blank',
    );
  }
};
