import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  FormControl,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch } from 'react-redux';
import {
  getDraftProxyApplication,
  isValidDomain,
  isValidURL,
} from '../../../utils/utility';
import ErrorMessage from '../../../components/ErrorMessage';
import { updateAtlassianConfiguration } from '../../../store/features/CreateAuthSourceSlice';

interface BasicSettingsType {
  OrganizationName: null | string;
  OrganizationDomain: null | string;
  attributeKey: null | string;
  nameAttributeKey: null | string;
  AtlassianACSUrl: null | string;
  AtlassianEntityUrl: null | string;
  CASBType: null | string;
}

interface AtlassianBasicSettingsProps {
  errorMessage: string;
  setBasicSettingsError: any;
}

function BasicSettings({
  errorMessage,
  setBasicSettingsError,
}: AtlassianBasicSettingsProps) {
  const dispatch = useDispatch();

  const InputFields = [
    'Organization Name*',
    'Organization Domain*',
    'Group Attribute Key*',
    'Name Attribute Key*',
    'Atlassian Entity URL*',
    'Atlassian ACS URL*',
  ];
  const InputFieldsName = [
    'OrganizationName',
    'OrganizationDomain',
    'attributeKey',
    'nameAttributeKey',
    'AtlassianEntityUrl',
    'AtlassianACSUrl',
  ];
  const [basicSettings, setBasicSettings] = useState<BasicSettingsType>({
    OrganizationName: null,
    OrganizationDomain: null,
    attributeKey: null,
    nameAttributeKey: null,
    AtlassianEntityUrl: null,
    AtlassianACSUrl: null,
    CASBType: 'inline',
  });
  const [inputErrorMessage, setInputErrorMessage] = useState<BasicSettingsType>(
    {
      OrganizationName: null,
      OrganizationDomain: null,
      attributeKey: null,
      nameAttributeKey: null,
      AtlassianEntityUrl: null,
      AtlassianACSUrl: null,
      CASBType: 'inline',
    },
  );
  const handleBasicSettingsInput = (event: any) => {
    var value = '';
    event.preventDefault();
    setBasicSettingsError('');
    const { name } = event.target;
    value = event.target.value;
    value = value?.trim();
    switch (name) {
      case 'OrganizationDomain':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          OrganizationDomain: !isValidDomain(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      case 'AtlassianACSUrl':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          AtlassianACSUrl: !isValidURL(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      case 'AtlassianEntityUrl':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          AtlassianEntityUrl: !isValidURL(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      default:
        break;
    }
    setBasicSettings((prevValue: BasicSettingsType) => ({
      ...prevValue,
      [name]: value,
    }));
  };
  useEffect(() => {
    async function getDraftedAtlassianConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_ATLASSIAN_KEY,
      );
      setBasicSettings({
        OrganizationName: DraftApplication?.success?.identifier,
        OrganizationDomain: DraftApplication?.success?.organization_domain,
        attributeKey: DraftApplication?.success?.attribute_key,
        nameAttributeKey: DraftApplication?.success?.name_attribute_key,
        AtlassianACSUrl: DraftApplication?.success?.acs_url,
        AtlassianEntityUrl: DraftApplication?.success?.entity_id_url,
        CASBType: DraftApplication?.success?.casb_type,
      });
    }
    getDraftedAtlassianConfiguration();
  }, []);

  useEffect(() => {
    function updateAuthenticationSourceDetails() {
      dispatch(
        updateAtlassianConfiguration({
          type: 'basic-settings',
          data: basicSettings,
        }),
      );
    }
    updateAuthenticationSourceDetails();
  }, [basicSettings]);

  return (
    <Box height="67vh" sx={{ overflow: 'scroll' }}>
      <Box display="flex" flexDirection="row">
        <Box width="40%" display="flex" py={6} px={6} flexDirection="column">
          <Typography variant="h5">Basic Settings</Typography>
          <br />
          <Typography variant="subtitle2" color="#56526B">
            Add new Basic Settings for Atlassian Plus Proxy
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="60%"
          px={6}
          py={5}
          gap="1.5rem"
        >
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {InputFields.map((value, index) => (
            <FormControl>
              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                label={value}
                sx={{
                  backgroundColor:
                    inputErrorMessage[
                      InputFieldsName[index] as keyof BasicSettingsType
                    ] === null
                      ? '#F6F6F6'
                      : '#ff8d0024',
                  border: 'none !important',
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                name={InputFieldsName[index]}
                value={
                  basicSettings[
                    InputFieldsName[index] as keyof BasicSettingsType
                  ] || ''
                }
                onChange={handleBasicSettingsInput}
              />
              <Typography variant="caption" color="red">
                {
                  inputErrorMessage[
                    InputFieldsName[index] as keyof BasicSettingsType
                  ]
                }
              </Typography>
            </FormControl>
          ))}
          <Box width="100%">
            <Typography variant="body2">
              Select CASB Type:
            </Typography>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="CASBType"
              value={basicSettings.CASBType || ''}
              onChange={handleBasicSettingsInput}
            >
              <FormControlLabel
                value="inline"
                control={<Radio />}
                label="Inline"
                sx={{ fontSize: '13px' }}
              />
              <FormControlLabel
                value="offline"
                control={<Radio />}
                label="Offline"
                sx={{ fontSize: '15px' }}
              />
            </RadioGroup>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BasicSettings;
