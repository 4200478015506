import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Switch,
  TextField,
  MenuItem,
  Select,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from '../../../../utils/utility';

interface AuthenticationProps {
  proxyInputDetails: any;
  setProxyInputDetails: Function;
}

function Authentication({
  proxyInputDetails,
  setProxyInputDetails,
}: AuthenticationProps) {
  const [authenticationSources, setAuthenticationSources] = useState<any[]>([]);
  const [selectedSource, setSelectedSource] = useState('');
  useEffect(() => {
    async function getAuthenticationSources() {
      const token = getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-configured-authentication-source`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          if (res.data && res.data.authentication_source) {
            setAuthenticationSources(res.data.authentication_source);
          }
        })
        .catch(() => {
          toast.error(
            process.env.REACT_APP_GET_CONFIGURED_AUTHENTICATION_SOURCE_ERROR,
          );
        });
    }

    getAuthenticationSources();
  }, []);
  useEffect(() => {
    async function setAuthenticationSource() {
      authenticationSources.forEach((source) => {
        if (
          proxyInputDetails?.Authentication?.authenticationID === source._id
        ) {
          setSelectedSource(source.identifier);
        }
      });
    }
    setAuthenticationSource();
  }, [authenticationSources]);

  const selectAuthenticationSource = (event: any) => {
    authenticationSources.forEach((source) => {
      if (event.target.value === source.identifier) {
        setSelectedSource(source.identifier);
        setProxyInputDetails((prevValue: any) => ({
          ...prevValue,
          Authentication: {
            ...prevValue.Authentication,
            authenticationID: source._id,
          },
        }));
      }
    });
  };
  const handleAuthenticationToggle = (event: any) => {
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      Authentication: {
        ...prevValue.Authentication,
        enable: event.target.checked,
      },
    }));
  };
  return (
    <Box
      width="90%"
      display="flex"
      flexDirection="column"
      sx={{ gap: '0.5rem' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Authentication</Typography>
          <Switch
            checked={proxyInputDetails?.Authentication?.enable}
            onChange={handleAuthenticationToggle}
          />
        </Box>
        <Typography variant="caption">
          Enable the authentication feature for your proxy URL. You are also
          required to set the maximum number of requests and the time limit.
        </Typography>
      </Box>
      <Box display="flex" sx={{ gap: '5rem' }} alignItems="center" py={2}>
        <Typography variant="subtitle2">Select Authentication</Typography>
        <Box>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            sx={{ width: '30rem', height: '2.2rem' }}
            onChange={selectAuthenticationSource}
            value={selectedSource}
          >
            {authenticationSources.map((source) => (
              <MenuItem value={source.identifier}>{source.identifier}</MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" py={2} sx={{ gap: '1.5rem' }}>
        <Typography variant="subtitle2">
          Redirect after Authentication
        </Typography>
        <TextField
          size="small"
          sx={{ width: '40%' }}
          value={proxyInputDetails?.Authentication?.redirectAfterAuthentication}
          onChange={(event: any) => {
            setProxyInputDetails((prevValue: any) => ({
              ...prevValue,
              Authentication: {
                ...prevValue.Authentication,
                redirectAfterAuthentication: event.target.value,
              },
            }));
          }}
        />
      </Box>
    </Box>
  );
}

export default Authentication;
