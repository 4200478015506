import React, { useState } from 'react';
import {
  Box, Typography, Switch, TextField,
} from '@mui/material';

interface CachingPerformanceProps {
  proxyInputDetails: any;
  setProxyInputDetails: Function;
}

function CachingPerformance({
  proxyInputDetails,
  setProxyInputDetails,
}: CachingPerformanceProps) {
  const [hour, setHour] = useState(24);
  const [minutes, setMinutes] = useState(0);
  const [error] = useState('Cache Time Exceeded');
  const handleCachingToggle = (event: any) => {
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      CachingFeature: {
        ...prevValue.CachingFeature,
        enable: event.target.checked,
      },
    }));
  };

  const setCachingTime = (event: any) => {
    const cacheTime = event.target.value;
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      CachingFeature: {
        ...prevValue.CachingFeature,
        cachingTime: cacheTime,
      },
    }));
    setHour(Math.floor(cacheTime / 60));
    setMinutes(cacheTime % 60);
  };
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      sx={{ gap: '0.5rem', overflowY: 'auto', overflowX: 'hidden' }}
      height="40vh"
    >
      <Box
        display="flex"
        flexDirection="column"
        width="90%"
        sx={{ gap: '0.5rem' }}
        px={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Enable Cache</Typography>
          <Switch
            checked={proxyInputDetails?.CachingFeature?.enable}
            onChange={handleCachingToggle}
          />
        </Box>
        <Typography variant="caption">
          Use these settings to improve the performance of your page by caching
          the data. miniOrange will save the data in cache from original source
          after the first request. You can always clear the cache by using Purge
          Cache option.
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        width="90%"
        alignItems="center"
        px={2}
      >
        <Box>
          <Typography variant="subtitle2">
            Cache Expire TTL (in minutes)
          </Typography>
          <Typography variant="caption">Max age: 168 Hours</Typography>
        </Box>
        <Box width="60%">
          <TextField
            fullWidth
            size="small"
            onChange={setCachingTime}
            value={proxyInputDetails?.CachingFeature?.cachingTime}
          />
          <Box display="flex" flexDirection="column">
            <Typography variant="caption">
              miniOrange will automatically clear your cache after&nbsp;
              {hour}
              &nbsp;Minutes&nbsp;
              {minutes}
              &nbsp;Seconds
            </Typography>
            {hour > 168 && (
              <Typography variant="caption" color="red">
                {error}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="90%"
        sx={{ gap: '0.5rem', backgroundColor: '#F6F6F6' }}
        p={2}
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle2">Never Cache URL(s)</Typography>
          <Typography variant="caption">
            Sensitive pages like custom login/logout URLs should be excluded
            from cache.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="caption">
            Specify URLs of pages or posts that should never be cached(one per
            line)
          </Typography>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            defaultValue="Default Value"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default CachingPerformance;
