import React, { useEffect, useState } from 'react';
import {
  Box, Modal, Typography, Button, Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from '../../../utils/utility';
import { GroupSettingsForm } from '../../../pages/Office365/components/ManageGroups';
import RenderOffice365Apps from './RenderOffice365Apps';

interface AddGroupModalProps {
  open: boolean;
  setOpen: Function;
  setReloadGroup: Function;
  selectedGroup: any;
  appName: string;
}
function AddGroupModal({
  open,
  setOpen,
  setReloadGroup,
  selectedGroup,
  appName,
}: AddGroupModalProps) {
  const [groupSettingError, setGroupSettingError] = useState('');
  const EmptyFieldError = 'Please fill all required fields.';
  const [groupSettings, setGroupSettings] = React.useState({
    groupName: null,
    groupDescription: null,
    groupPolicy: '',
    allowedApps: [] as string[],
    restrictedApps: [] as string[],
    blockApps: [] as string[],
    office365AppPolicy: [] as any[],
  });
  const token = getCookie('token');
  const [allPolicies, setAllPolicies] = React.useState([]);
  useEffect(() => {
    function getAllPolicies() {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-policies`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((response: any) => {
          if (response && response.data && response.data.PolicyData) {
            setAllPolicies(response.data.PolicyData);
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_ALL_POLICIES_ERROR);
        });
    }
    getAllPolicies();
  }, []);

  useEffect(() => {
    async function getAllOffice365Apps() {
      if (appName !== process.env.REACT_APP_OFFICE365_KEY) {
        return;
      }

      const axiosConfig = {
        method: 'GET',
        url: `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-office365-apps`,
        params: {
          token,
        },
      };
      axios(axiosConfig)
        .then((response: any) => {
          if (response && response.data && response.data.office365Apps) {
            const appList: string[] = [];
            response.data.office365Apps.forEach((app: any) => {
              appList.push(app.appKey);
            });
            let defaultPolicy = '';
            allPolicies.forEach((policy: any) => {
              if (policy.identifier === 'default') {
                defaultPolicy = policy._id;
              }
            });
            const appPolicyList: any[] = [];
            appList.forEach((app: any) => {
              appPolicyList[app] = defaultPolicy;
            });
            setGroupSettings({
              groupName: null,
              groupDescription: null,
              groupPolicy: '',
              allowedApps: [],
              restrictedApps: appList,
              blockApps: [],
              office365AppPolicy: appPolicyList,
            });
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_GOOGLE_APPS_ERROR);
        });
    }
    getAllOffice365Apps();
  }, []);

  const handleClose = () => {
    setGroupSettings({
      groupName: null,
      groupDescription: null,
      groupPolicy: '',
      allowedApps: [],
      restrictedApps: [],
      blockApps: [],
      office365AppPolicy: [],
    });
    setGroupSettingError('');
    setOpen(false);
  };
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 705,
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: 'white',
    color: '#5F636D',
  };
  const updateGroupSetting = async (type: string) => {
    setGroupSettingError('');
    let isConfigInvalid = !groupSettings
      || !groupSettings.groupName
      || !groupSettings.groupDescription
      || !groupSettings.groupPolicy;
    if (appName === process.env.REACT_APP_OFFICE365_KEY) {
      isConfigInvalid = isConfigInvalid
        || !groupSettings.blockApps
        || !groupSettings.allowedApps
        || !groupSettings.restrictedApps;
    }

    if (isConfigInvalid) {
      setGroupSettingError(EmptyFieldError);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-group-settings`,
          {
            group_details: groupSettings,
            app: process.env.REACT_APP_OFFICE365_KEY,
            _id: selectedGroup?._id,
            type,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          toast.success('Group Added Successfully.');
          setGroupSettings({
            groupName: null,
            groupDescription: null,
            groupPolicy: '',
            allowedApps: [],
            restrictedApps: [],
            blockApps: [],
            office365AppPolicy: [],
          });
          setOpen(false);
          setReloadGroup(true);
        })
        .catch((error) => {
          const errorMessage = error?.response?.data || error?.response?.data.message;
          setGroupSettingError(errorMessage);
        });
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          px="20px"
          py="10px"
          borderBottom="1px solid #D9D9D9"
        >
          <Typography variant="h6">Add New Group</Typography>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </Box>
        {groupSettingError && (
          <Typography variant="caption" px="20px" py="10px" color="red">
            {groupSettingError}
          </Typography>
        )}
        <Box display="flex" flexDirection="column" width="100%">
          <GroupSettingsForm
            allPolicies={allPolicies}
            groupSettings={groupSettings}
            setGroupSettings={setGroupSettings}
            selectedGroup={selectedGroup}
            errorMessage=""
          />
          {appName === process.env.REACT_APP_OFFICE365_KEY && (
            <Box>
              <Divider />
              <Box height={370} sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
                <RenderOffice365Apps
                  allPolicies={allPolicies}
                  groupSettings={groupSettings}
                  setGroupSettings={setGroupSettings}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          width="100%"
          px="20px"
          py="10px"
          bottom="0"
          position="absolute"
          sx={{ gap: '2rem' }}
        >
          {selectedGroup.length === 0 ? (
            <Button
              sx={{
                backgroundColor: 'green',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'green',
                  color: 'white',
                },
              }}
              onClick={() => updateGroupSetting('save')}
            >
              Save
            </Button>
          ) : (
            <Button
              sx={{
                backgroundColor: 'green',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'green',
                  color: 'white',
                },
              }}
              onClick={() => updateGroupSetting('update')}
            >
              Update
            </Button>
          )}

          <Button
            sx={{
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                backgroundColor: 'red',
                color: 'white',
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddGroupModal;
