import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { getCookie } from '../../../utils/utility';
import Restriction from './EnableFeaturesView/Restriction';
import CachingPerformance from './EnableFeaturesView/Caching&Performance';
import LoadBalancingRateLimiting from './EnableFeaturesView/LoadBalancingRateLimiting';
import CookieDomainRewrite from './EnableFeaturesView/CookieDomainRewrite';
import Authentication from './EnableFeaturesView/Authentication';
import Substitution from './EnableFeaturesView/Substitution';
import CORS from './EnableFeaturesView/CORS';

interface EnableFeaturesProps {
  step: number;
  setStep: Function;
  proxyInputDetails: any;
  setProxyInputDetails: Function;
  type: string;
}

function EnableFeatures({
  step,
  setStep,
  proxyInputDetails,
  setProxyInputDetails,
  type,
}: EnableFeaturesProps) {
  const navigate = useNavigate();
  const [enableFeatureStep, setEnableFeatureStep] = useState('Restriction');
  const handleEnableFeatures = () => {
    const token = getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-new-proxy`,
        {
          proxyInputDetails,
          type,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((res) => {
        setProxyInputDetails((prevValue: any) => ({
          ...prevValue,
          id: res.data.id,
        }));
        navigate('/configure/proxy');
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_ADD_PROXY_ERROR);
      });
  };
  return (
    <Box width="100%" p={4} height="67vh" overflow="hidden">
      <Box
        display="flex"
        flexDirection="column"
        sx={{ gap: '0.5rem' }}
        width="100%"
        borderBottom="1px solid #D9D9D9"
        p={2}
      >
        <Typography variant="h6" color="#25213B">
          Enable Features
        </Typography>
        <Typography variant="subtitle2" color="#56526B">
          Enable Features you want on this proxy
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ gap: '0.5rem' }}
        width="100%"
        p={2}
      >
        <Box display="flex">
          <Box
            onClick={() => setEnableFeatureStep('Restriction')}
            p={1}
            width="6rem"
            borderBottom={
              enableFeatureStep === 'Restriction' ? '2px solid #56526B' : ''
            }
          >
            <Typography variant="subtitle2" color="#56526B">
              Restrictions
            </Typography>
          </Box>
          <Box
            onClick={() => setEnableFeatureStep('Caching & Performance')}
            p={1}
            width="12rem"
            borderBottom={
              enableFeatureStep === 'Caching & Performance'
                ? '2px solid #56526B'
                : ''
            }
          >
            <Typography variant="subtitle2" color="#56526B">
              Caching & Performance
            </Typography>
          </Box>
          <Box
            onClick={() => setEnableFeatureStep('Rate Limiting/Load Balancing')}
            p={1}
            width="14rem"
            borderBottom={
              enableFeatureStep === 'Rate Limiting/Load Balancing'
                ? '2px solid #56526B'
                : ''
            }
          >
            <Typography variant="subtitle2" color="#56526B">
              Rate Limiting/Load Balancing
            </Typography>
          </Box>
          <Box
            onClick={() => setEnableFeatureStep('Cookie Domain Rewrite')}
            p={1}
            width="11rem"
            borderBottom={
              enableFeatureStep === 'Cookie Domain Rewrite'
                ? '2px solid #56526B'
                : ''
            }
          >
            <Typography variant="subtitle2" color="#56526B">
              Cookie Domain Rewrite
            </Typography>
          </Box>
          <Box
            onClick={() => setEnableFeatureStep('Authentication')}
            p={1}
            width="8rem"
            borderBottom={
              enableFeatureStep === 'Authentication' ? '2px solid #56526B' : ''
            }
          >
            <Typography variant="subtitle2" color="#56526B">
              Authentication
            </Typography>
          </Box>
          <Box
            onClick={() => setEnableFeatureStep('CORS')}
            p={1}
            width="6rem"
            borderBottom={
              enableFeatureStep === 'CORS' ? '2px solid #56526B' : ''
            }
          >
            <Typography variant="subtitle2" color="#56526B">
              CORS
            </Typography>
          </Box>
          <Box
            onClick={() => setEnableFeatureStep('Substitution')}
            p={1}
            width="6rem"
            borderBottom={
              enableFeatureStep === 'Substitution' ? '2px solid #56526B' : ''
            }
          >
            <Typography variant="subtitle2" color="#56526B">
              Substitution
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ gap: '0.5rem', height: '36vh', overflow: 'auto !important' }}
        width="95%"
        p={2}
      >
        {enableFeatureStep === 'Restriction' && (
          <Restriction
            proxyInputDetails={proxyInputDetails}
            setProxyInputDetails={setProxyInputDetails}
          />
        )}
        {enableFeatureStep === 'Caching & Performance' && (
          <CachingPerformance
            proxyInputDetails={proxyInputDetails}
            setProxyInputDetails={setProxyInputDetails}
          />
        )}
        {enableFeatureStep === 'Rate Limiting/Load Balancing' && (
          <LoadBalancingRateLimiting
            proxyInputDetails={proxyInputDetails}
            setProxyInputDetails={setProxyInputDetails}
          />
        )}
        {enableFeatureStep === 'Cookie Domain Rewrite' && (
          <CookieDomainRewrite
            proxyInputDetails={proxyInputDetails}
            setProxyInputDetails={setProxyInputDetails}
          />
        )}
        {enableFeatureStep === 'Authentication' && (
          <Authentication
            proxyInputDetails={proxyInputDetails}
            setProxyInputDetails={setProxyInputDetails}
          />
        )}
        {enableFeatureStep === 'CORS' && (
          <CORS
            proxyInputDetails={proxyInputDetails}
            setProxyInputDetails={setProxyInputDetails}
          />
        )}
        {enableFeatureStep === 'Substitution' && (
          <Substitution
            proxyInputDetails={proxyInputDetails}
            setProxyInputDetails={setProxyInputDetails}
          />
        )}
      </Box>
      <Box
        display="flex"
        width="100%"
        borderTop="1px solid #D9D9D9"
        alignItems="center"
        justifyContent="right"
        pt={2}
        sx={{ gap: '1rem' }}
      >
        <Button variant="text" onClick={() => setStep(step - 1)}>
          Previous
        </Button>
        <Button
          variant="text"
          sx={{
            color: 'white',
            backgroundColor: '#2D3748',
            marginRight: '5rem',
            '&:hover': {
              backgroundColor: '#2D3748',
              color: 'white',
            },
          }}
          onClick={handleEnableFeatures}
        >
          Finish
        </Button>
      </Box>
    </Box>
  );
}

export default EnableFeatures;
