import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getCookie } from '../../../utils/utility';

interface ProxyDetailsProps {
  step: number;
  setStep: Function;
  proxyInputDetails: any;
  setProxyInputDetails: Function;
  type: string;
  setType: Function;
}

function ProxyDetails({
  step,
  setStep,
  proxyInputDetails,
  setProxyInputDetails,
  type,
  setType,
}: ProxyDetailsProps) {
  const saveProxyDetails = () => {
    if (
      !proxyInputDetails
      || !proxyInputDetails.proxyName
      || !proxyInputDetails.proxyLink
      || !proxyInputDetails.primaryServerURL
    ) {
      toast.error('Missing Required Fields');
    } else {
      const token = getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-new-proxy`,
          {
            proxyInputDetails,
            type,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          setProxyInputDetails((prevValue: any) => ({
            ...prevValue,
            id: res.data.id,
          }));
          setType('update');
          setStep(step + 1);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    }
  };
  const handleProxyDetails = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      [name]: value,
    }));
  };
  const handleEnableByIP = (event: any) => {
    event.preventDefault();
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      enableByIP: event.target.checked,
    }));
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" height="74vh">
      <Box
        display="flex"
        width="100%"
        px={3}
        py={2}
        sx={{ backgroundColor: '#ffeef0' }}
      >
        <Typography variant="body2" color="black">
          Configure&nbsp;&nbsp;
          <Link to="/tenant-settings">Virtual Hosting</Link>
          &nbsp;&nbsp;from Tenant Setting tab before Configuring Proxy
        </Typography>
      </Box>
      <Box display="flex" width="100%" height="70vh">
        <Box
          width="40%"
          display="flex"
          flexDirection="column"
          p={4}
          sx={{ gap: '1rem' }}
        >
          <Typography variant="h6">Proxy</Typography>
          <Typography variant="subtitle2">Create a new proxy Link</Typography>
        </Box>
        <Box
          width="60%"
          display="flex"
          flexDirection="column"
          py={4}
          px={7}
          sx={{ gap: '1.5rem' }}
        >
          <FormControl>
            <FormLabel sx={{ fontSize: '.75rem' }}>Proxy Name</FormLabel>
            <TextField
              required
              fullWidth
              id="outlined-basic"
              size="small"
              name="proxyName"
              variant="outlined"
              onChange={handleProxyDetails}
              value={proxyInputDetails.proxyName || ''}
              sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
            />
          </FormControl>
          {/* <Box display="flex">
            <Button
              disabled
              sx={{
                backgroundColor: 'lightgray',
                color: 'black',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                width: '40rem',
                overflow: 'hidden',
              }}
            >
              {url}
            </Button>
            <TextField
              required
              id="outlined-basic"
              size="small"
              name="proxyLink"
              label="Proxy Link"
              variant="outlined"
              onChange={handleProxyDetails}
              value={proxyInputDetails.proxyLink}
              sx={{
                backgroundColor: '#F6F6F6',
                border: 'none !important',
                borderTopLeftRadius: '0 !important',
                borderBottomLeftRadius: 0,
                width: 200,
              }}
            />
          </Box> */}
          <FormControl>
            <FormLabel sx={{ fontSize: '.75rem' }}>Proxy Path</FormLabel>
            <TextField
              required
              fullWidth
              id="outlined-basic"
              size="small"
              name="proxyLink"
              placeholder="Test"
              variant="outlined"
              onChange={handleProxyDetails}
              value={proxyInputDetails.proxyLink || ''}
              sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
            />
          </FormControl>
          <FormControl>
            <FormLabel sx={{ fontSize: '.75rem' }}>Origin Server URL</FormLabel>
            <TextField
              required
              fullWidth
              id="outlined-basic"
              size="small"
              name="primaryServerURL"
              variant="outlined"
              onChange={handleProxyDetails}
              value={proxyInputDetails.primaryServerURL || ''}
              sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
            />
          </FormControl>

          <Box display="flex" flexDirection="column">
            <FormControlLabel
              label="Enable by IP"
              control={(
                <Checkbox
                  name="enableByIP"
                  checked={proxyInputDetails.enableByIP}
                  onChange={handleEnableByIP}
                  color="primary"
                />
              )}
            />
            <Typography variant="caption">
              If you enable this option you have to enter server ip instead of
              domain in Server Url field.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        width="100%"
        borderTop="1px solid #D9D9D9"
        alignItems="center"
        justifyContent="right"
        pt={2}
        sx={{ gap: '1rem' }}
      >
        <Button variant="text" href="/configure/proxy">
          Cancel
        </Button>
        <Button
          variant="text"
          sx={{
            color: 'white',
            backgroundColor: '#2D3748',
            marginRight: '2rem',
            '&:hover': {
              backgroundColor: '#2D3748',
              color: 'white',
            },
          }}
          onClick={saveProxyDetails}
        >
          Save & Next
        </Button>
      </Box>
    </Box>
  );
}

export default ProxyDetails;
