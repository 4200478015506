import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box, Modal, Typography, Button,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { getCookie, isValidIPAddress } from '../utils/utility';
import AddNewPolicyForm from '../pages/GoogleConfiguration/Components/AddNewPolicyForm';
import ErrorMessage from './ErrorMessage';

interface AddPolicyModalProps {
  open: boolean;
  setOpen: Function;
  setReloadPolicy: Function;
  selectedPolicy: any;
}
function AddPolicyModal({
  open,
  setOpen,
  setReloadPolicy,
  selectedPolicy,
}: AddPolicyModalProps) {
  const handleClose = () => setOpen(false);
  const EmptyFieldError = 'Please fill all required fields.';
  const PolicySettingConfiguration = useSelector(
    (state: any) => state.PolicySettings,
  );
  const [policySettingError, setPolicySettingError] = useState('');
  useEffect(() => {
    setPolicySettingError('');
  }, [open]);

  const savePolicySettings = async () => {
    var hasInvalidIP = false;
    if (
      !PolicySettingConfiguration
      || !PolicySettingConfiguration.policyName
      || !PolicySettingConfiguration.policyDescription
    ) {
      setPolicySettingError(EmptyFieldError);
    } else if (PolicySettingConfiguration?.enableIPRestriction) {
      hasInvalidIP = PolicySettingConfiguration?.IPAddresses.some((ip: any) => {
        if (!ip || !isValidIPAddress(ip)) {
          toast.error('Invalid IP Address');
          return true;
        }
        return false;
      });
    }
    if (hasInvalidIP) {
      return;
    }
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-proxy-policy`,
        {
          policy_details: PolicySettingConfiguration,
          type: 'save',
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        setOpen(false);
        setReloadPolicy(true);
      })
      .catch((error) => {
        if (
          error.response
          && error.response.data
          && error.response.data.message
        ) {
          toast.error(error.response.data.message);
        }
      });
  };
  const updatePolicySettings = async () => {
    var hasInvalidIP = false;
    if (
      !PolicySettingConfiguration
      || !PolicySettingConfiguration.policyName
      || !PolicySettingConfiguration.policyDescription
    ) {
      setPolicySettingError(EmptyFieldError);
    } else if (PolicySettingConfiguration?.enableIPRestriction) {
      hasInvalidIP = PolicySettingConfiguration?.IPAddresses.some((ip: any) => {
        if (!ip || !isValidIPAddress(ip)) {
          toast.error('Invalid IP Address');
          return true;
        }
        return false;
      });
    }
    if (hasInvalidIP) {
      return;
    }
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-policy`,
        {
          policy_details: PolicySettingConfiguration,
          _id: selectedPolicy?._id,
          type: 'update',
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        setOpen(false);
        setReloadPolicy(true);
      })
      .catch((error) => {
        if (
          error.response
          && error.response.data
          && error.response.data.message
        ) {
          setPolicySettingError(error.response.data.message);
        }
      });
  };
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 600,
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: 'white',
    color: '#5F636D',
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          px="20px"
          py="10px"
          borderBottom="1px solid #D9D9D9"
        >
          <Typography variant="h6">Add New Policy</Typography>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </Box>
        {policySettingError && <ErrorMessage message={policySettingError} />}
        <Box width="100%">
          <AddNewPolicyForm
            errorMessage=""
            width="100%"
            selectedPolicy={selectedPolicy}
            app="google"
          />
        </Box>
        <Box
          display="flex"
          width="100%"
          px="20px"
          py="10px"
          sx={{ gap: '2rem' }}
        >
          {selectedPolicy?.identifier ? (
            <Button
              sx={{
                backgroundColor: 'green',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'green',
                  color: 'white',
                },
              }}
              onClick={updatePolicySettings}
            >
              Update
            </Button>
          ) : (
            <Button
              sx={{
                backgroundColor: 'green',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'green',
                  color: 'white',
                },
              }}
              onClick={savePolicySettings}
            >
              Save
            </Button>
          )}
          <Button
            sx={{
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                backgroundColor: 'red',
                color: 'white',
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddPolicyModal;
