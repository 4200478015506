import React from 'react';
import PolicyView from './PolicyView';

export default function index() {
  return (
    <div>
      <PolicyView />
    </div>
  );
}
