import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box, Modal, Typography, Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getCookie } from '../utils/utility';
import { GroupSettingsForm } from '../pages/GoogleConfiguration/Components/ManageGroups';

interface AddGroupModalProps {
  open: boolean;
  setOpen: Function;
  setReloadGroup: Function;
  selectedGroup: any;
  appName: any | string;
  title?: string;
}

function AddGroupModalCommon({
  open,
  setOpen,
  setReloadGroup,
  selectedGroup,
  appName,
  title,
}: AddGroupModalProps) {
  const GroupSettingConfiguration = useSelector(
    (state: any) => state.GroupSettings,
  );
  const [groupSettingError, setGroupSettingError] = useState('');
  const EmptyFieldError = 'Please fill all required fields.';
  const [groupSettings, setGroupSettings] = React.useState({
    groupName: null,
    groupDescription: null,
    groupPolicy: '',
  });
  const [allPolicies, setAllPolicies] = React.useState([]);
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    height: 350,
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: 'white',
    color: '#5F636D',
  };
  useEffect(() => {
    async function getAllPolicies() {
      const token = await getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-policies`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((response: any) => {
          if (response && response.data && response.data.PolicyData) {
            setAllPolicies(response.data.PolicyData);
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_ALL_POLICIES_ERROR);
        });
    }
    getAllPolicies();
  }, []);
  const handleClose = () => {
    setGroupSettingError('');
    setOpen(false);
  };
  const updateGroupSetting = async () => {
    setReloadGroup(false);
    setGroupSettingError('');
    const token = await getCookie('token');
    if (
      !GroupSettingConfiguration
      || !GroupSettingConfiguration.groupName
      || !GroupSettingConfiguration.groupDescription
      || !GroupSettingConfiguration.groupPolicy
    ) {
      setGroupSettingError(EmptyFieldError);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-group-settings`,
          {
            group_details: GroupSettingConfiguration,
            _id: selectedGroup?._id,
            app: appName,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          setOpen(false);
          setReloadGroup(true);
        })
        .catch((error) => {
          const errorMessage = error?.response?.data || error?.response?.data.message;
          setGroupSettingError(errorMessage);
        });
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          px="20px"
          py="10px"
          borderBottom="1px solid #D9D9D9"
        >
          <Typography variant="h6">
            {title !== '' || title !== null
              ? title
              : selectedGroup.length === 0
                ? 'Add New Group'
                : 'Update Group'}
          </Typography>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </Box>
        {groupSettingError && (
          <Typography variant="caption" px="20px" py="10px" color="red">
            {groupSettingError}
          </Typography>
        )}
        <Box display="flex" flexDirection="column" width="100%">
          <GroupSettingsForm
            allPolicies={allPolicies}
            groupSettings={groupSettings}
            setGroupSettings={setGroupSettings}
            selectedGroup={selectedGroup}
            errorMessage=""
          />
        </Box>
        <Box
          display="flex"
          width="100%"
          px="20px"
          py="10px"
          bottom="0"
          position="absolute"
          sx={{ gap: '2rem' }}
        >
          {selectedGroup.length === 0 ? (
            <Button
              sx={{
                backgroundColor: 'green',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'green',
                  color: 'white',
                },
              }}
              onClick={() => updateGroupSetting()}
            >
              Save
            </Button>
          ) : (
            <Button
              sx={{
                backgroundColor: 'green',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'green',
                  color: 'white',
                },
              }}
              onClick={() => updateGroupSetting()}
            >
              Update
            </Button>
          )}

          <Button
            sx={{
              backgroundColor: 'red',
              color: 'white',
              '&:hover': {
                backgroundColor: 'red',
                color: 'white',
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

AddGroupModalCommon.defaultProps = {
  title: '',
};

export default AddGroupModalCommon;
