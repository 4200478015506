import React, { useState } from 'react';
import {
  Box, Typography, Switch, Button, TextField,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

interface CORSProps {
  proxyInputDetails: any;
  setProxyInputDetails: Function;
}
function LoadBalancingRateLimiting({
  proxyInputDetails,
  setProxyInputDetails,
}: CORSProps) {
  const [rateLimiterMembers, setRateLimiterMembers] = useState(
    proxyInputDetails?.RateLimitingFeature?.whiteListPath || [''],
  );
  const addFormFieldsRateLimiter = () => {
    setRateLimiterMembers([...rateLimiterMembers, '']);
  };
  const removeFieldsRateLimiter = (index: number) => {
    const data = [...rateLimiterMembers];
    data.splice(index, 1);
    setRateLimiterMembers(data);
  };
  const handleChangeRateLimiter = (index: number, event: any) => {
    const data = [...rateLimiterMembers];
    data[index] = event.target.value;
    setRateLimiterMembers(data);
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      RateLimitingFeature: {
        ...prevValue.RateLimitingFeature,
        whiteListPath: data,
      },
    }));
  };
  const [balancerMembers, setBalancerMembers] = useState(
    proxyInputDetails?.LoadBalanceFeature?.memberList || [''],
  );
  const addFormFieldsLoadBalancer = () => {
    setBalancerMembers([...balancerMembers, '']);
  };
  const removeFieldsLoadBalancer = (index: number) => {
    const data = [...balancerMembers];
    data.splice(index, 1);
    setBalancerMembers(data);
  };
  const handleChangeLoadBalancer = (index: number, event: any) => {
    const data = [...balancerMembers];
    data[index] = event.target.value;
    setBalancerMembers(data);
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      LoadBalanceFeature: {
        ...prevValue.LoadBalanceFeature,
        memberList: data,
      },
    }));
  };
  const handleLoadBalanceToggle = (event: any) => {
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      LoadBalanceFeature: {
        ...prevValue.LoadBalanceFeature,
        enable: event.target.checked,
      },
    }));
  };
  const handleRateLimitingToggle = (event: any) => {
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      RateLimitingFeature: {
        ...prevValue.RateLimitingFeature,
        enable: event.target.checked,
      },
    }));
  };

  return (
    <Box
      width="90%"
      display="flex"
      flexDirection="column"
      sx={{ gap: '0.5rem' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          sx={{ gap: '0.5rem' }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2">Rate Limiting</Typography>
            <Switch
              onChange={handleRateLimitingToggle}
              checked={proxyInputDetails?.RateLimitingFeature?.enable}
            />
          </Box>
          <Typography variant="caption">
            Set the maximum number of requests and the time limit.
          </Typography>
          <Box display="flex" alignItems="center" sx={{ gap: '0.5rem' }}>
            <TextField
              size="small"
              label="Maximum Request"
              onChange={(event: any) => {
                setProxyInputDetails((prevValue: any) => ({
                  ...prevValue,
                  RateLimitingFeature: {
                    ...prevValue.RateLimitingFeature,
                    maxRequest: event.target.value,
                  },
                }));
              }}
              value={proxyInputDetails?.RateLimitingFeature?.maxRequest}
            />
            <TextField
              size="small"
              label="Time Limit(in mins)"
              onChange={(event: any) => {
                setProxyInputDetails((prevValue: any) => ({
                  ...prevValue,
                  RateLimitingFeature: {
                    ...prevValue.RateLimitingFeature,
                    timeLimit: event.target.value,
                  },
                }));
              }}
              value={proxyInputDetails?.RateLimitingFeature?.timeLimit}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            width="90%"
            sx={{ gap: '0.5rem', backgroundColor: '#F6F6F6' }}
            p={2}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle2">Whitelisted Path(s)</Typography>
              <Typography variant="caption">
                Static resource paths could be excluded from Rate Limiter.
              </Typography>
              <Typography variant="caption" style={{ color: 'red' }}>
                (Whitelisted paths will be matched on the basis of regExp. )
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" gap="0.75rem">
              <Box>
                <Button onClick={addFormFieldsRateLimiter}>
                  + Add whitelisted Paths
                </Button>
              </Box>
              <Box display="flex" flexDirection="column">
                {rateLimiterMembers.map((element: any, index: number) => (
                  <Box
                    display="flex"
                    sx={{ gap: '0.5rem', padding: '0.25rem' }}
                  >
                    <TextField
                      id="outlined-basic"
                      size="small"
                      label="Eg.  /static-contents"
                      variant="outlined"
                      sx={{
                        backgroundColor: '#F6F6F6',
                        border: 'none !important',
                        width: 400,
                      }}
                      inputProps={{
                        style: {
                          fontSize: '15px',
                        },
                      }}
                      value={element || ''}
                      onChange={(event) => handleChangeRateLimiter(index, event)}
                    />
                    <RemoveCircleIcon
                      fontSize="large"
                      sx={{ color: '#E34614', marginLeft: '2rem' }}
                      onClick={() => removeFieldsRateLimiter(index)}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Load Balancing</Typography>
          <Switch
            onChange={handleLoadBalanceToggle}
            checked={proxyInputDetails?.LoadBalanceFeature?.enable}
          />
        </Box>
        <Typography variant="caption">
          Load Balancing feature is used to ensure horizontal scaling of
          requests across the member servers. Your primary server address is
          also one of the participants in load distribution.
        </Typography>
        <Box display="flex" flexDirection="column" gap="0.75rem">
          <Box>
            <Button onClick={addFormFieldsLoadBalancer}>
              + Add Balancer Member
            </Button>
          </Box>
          <Box display="flex" flexDirection="column">
            {balancerMembers.map((element: any, index: number) => (
              <Box display="flex" sx={{ gap: '0.5rem', padding: '0.25rem' }}>
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="Eg.  https://<domain>.com or 23.30.3000"
                  variant="outlined"
                  sx={{
                    backgroundColor: '#F6F6F6',
                    border: 'none !important',
                    width: 400,
                  }}
                  inputProps={{
                    style: {
                      fontSize: '15px',
                    },
                  }}
                  value={element || ''}
                  onChange={(event) => handleChangeLoadBalancer(index, event)}
                />
                <RemoveCircleIcon
                  fontSize="large"
                  sx={{ color: '#E34614', marginLeft: '2rem' }}
                  onClick={() => removeFieldsLoadBalancer(index)}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LoadBalancingRateLimiting;
