import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import MainLayout from '../../components/MainLayout';
import {
  currentActiveTenantDetails,
  getUser,
  DomainDetails,
  getCookie,
} from '../../utils/utility';

function TenantSettings() {
  const [image, setImage] = useState('');
  const [imageurl, setImageURL] = useState('');
  const [proxyURL, setProxyURL] = useState('');
  const [tenantSettingsDetails, setTenantSettingsDetails] = useState({
    baseDomain: null,
    key: null,
    cert: null,
  });
  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    const fileType = file.type;
    const validImageTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/svg+xml',
    ];
    if (!validImageTypes.includes(fileType)) {
      toast.error('Invalid Image format!');
      return;
    }
    const fileSize = file.size;
    if (fileSize > 3 * 1024 * 1024) {
      toast.error('File Size Exceeding 3MB!');
      return;
    }
    setImage(file);
  };

  useEffect(() => {
    async function getTenantDetails() {
      const tenantDetails: any = await currentActiveTenantDetails();
      setImageURL(tenantDetails?.branding_logo_url);
      setProxyURL(tenantDetails?.url);
      setTenantSettingsDetails((prevValue) => ({
        ...prevValue,
        baseDomain: tenantDetails?.domain,
      }));
    }
    async function getCurrentDomainDetails() {
      const domainDetails: any = await DomainDetails();
      setTenantSettingsDetails((prevValue) => ({
        ...prevValue,
        key: domainDetails?.ssl_protocol?.key,
      }));
      setTenantSettingsDetails((prevValue) => ({
        ...prevValue,
        cert: domainDetails?.ssl_protocol?.cert,
      }));
    }
    getTenantDetails();
    getCurrentDomainDetails();
  }, []);

  const uploadTenantBrandingLogo = async () => {
    const formData = new FormData();
    const user = await getUser();
    if (!image) {
      toast.error('No Image Selected.');
      return;
    }
    formData.append('branding-logo', image);
    formData.append('customer_id', user.customer_id);
    formData.append('current_active_tenant_id', user.current_active_tenant_id);
    formData.append('_id', user.id);
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/upload-tenant-branding-logo`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((response) => {
        setImageURL(response?.data?.response?.Location);
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_UPLOAD_BRAND_IMAGE_ERROR);
      });
  };

  const saveTenantSettings = (event: any) => {
    var value = '';
    event.preventDefault();
    const { name } = event.target;
    value = event.target.value;
    value = value?.trim();
    setTenantSettingsDetails((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const restoreDefaultTenantSettings = async () => {
    const user = await getUser();
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/restore-default-tenant-settings`,
        {
          user,
          token,
        },
      )
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        toast.error(
          process.env.REACT_APP_RESTORE_DEFAULT_TENANT_SETTINGS_ERROR,
        );
      });
  };

  const updateTenantSettings = async () => {
    const token = await getCookie('token');
    if (
      !tenantSettingsDetails.key
      || !tenantSettingsDetails.cert
      || !tenantSettingsDetails.baseDomain
    ) {
      toast.error('Please provide all necessary information');
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/tenant-settings`,
        {
          tenantSettingsDetails,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((response: any) => {
        toast.success(response?.data?.message);
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <MainLayout>
      <Box display="flex" flexDirection="column" overflow="hidden" p={3}>
        <ToastContainer />
        <Box
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid #D9D9D9"
        >
          <Typography variant="h5" color="initial">
            Tenant Settings
          </Typography>
          <Button
            sx={{
              marginRight: '4rem',
              marginBottom: '1rem',
              padding: '0.5rem 1rem',
              color: 'white',
              backgroundColor: '#B22222',
              '&:hover': {
                backgroundColor: '#B22222',
                color: 'white',
              },
            }}
            onClick={restoreDefaultTenantSettings}
          >
            Restore Default
          </Button>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          width="100vw"
          height="100vh"
          sx={{
            overflowY: 'scroll',
            overflowX: 'hidden',
            gap: '1.5rem',
          }}
        >
          <Box>
            {imageurl && <img src={imageurl} alt="branding-logo" width="200" />}
          </Box>
          <Box display="flex" alignItems="center" gap="4rem">
            <Typography variant="subtitle2" sx={{ width: '10rem' }}>
              Branding Logo
            </Typography>
            <TextField
              id="branding-logo"
              type="file"
              size="small"
              sx={{ width: '30rem' }}
              onChange={handleFileChange}
            />
            <Button
              sx={{ px: '2rem' }}
              variant="contained"
              onClick={uploadTenantBrandingLogo}
            >
              Upload
            </Button>
          </Box>
          <Box display="flex" alignItems="center" gap="4rem">
            <Typography variant="subtitle2" sx={{ width: '10rem' }}>
              Base Domain
            </Typography>
            <TextField
              id="base-domain"
              size="small"
              sx={{ width: '30rem' }}
              name="baseDomain"
              value={tenantSettingsDetails.baseDomain}
              onChange={saveTenantSettings}
            />
          </Box>
          <Box display="flex" alignItems="center" gap="4rem">
            <Typography variant="subtitle2" sx={{ width: '10rem' }}>
              Proxy URL
            </Typography>
            <TextField
              id="proxy-url"
              size="small"
              sx={{ width: '30rem' }}
              value={proxyURL}
              onChange={saveTenantSettings}
              disabled
            />
          </Box>
          <Typography variant="subtitle1" sx={{ color: 'red' }}>
            Please provide SSL certificates for HTTPS Domain Protocol
          </Typography>
          <Box display="flex" alignItems="center" gap="4rem">
            <Typography variant="subtitle2" sx={{ width: '10rem' }}>
              Key Value
            </Typography>
            <TextField
              id="outlined-multiline-static"
              name="key"
              multiline
              rows={4}
              sx={{ width: '30rem' }}
              value={tenantSettingsDetails.key}
              onChange={saveTenantSettings}
            />
          </Box>
          <Box display="flex" alignItems="center" gap="4rem">
            <Typography variant="subtitle2" sx={{ width: '10rem' }}>
              Certificate Value
            </Typography>
            <TextField
              id="outlined-multiline-static"
              name="cert"
              multiline
              rows={4}
              value={tenantSettingsDetails.cert}
              onChange={saveTenantSettings}
              sx={{ width: '30rem' }}
            />
          </Box>
          <Box display="flex" alignItems="center" gap="4rem">
            <Button
              variant="contained"
              sx={{ width: '7rem' }}
              onClick={updateTenantSettings}
            >
              Submit
            </Button>
            <Button>Cancel</Button>
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default TenantSettings;
