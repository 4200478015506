import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Divider,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { Link } from 'react-router-dom';
import SPMetadataModal from '../SPMetadataModal';
import {
  lightBackgroundButtonStyles,
  darkBackgroundButtonStyles,
} from '../../styles/button';
import {
  generateMetaData,
  getCookie,
  getDraftProxyApplication,
} from '../../utils/utility';
import ImportIDPMetadata from '../ImportIDPMetadata';
import {
  downloadXML,
  generateAndOpenMetadata,
} from '../../utils/SAMLAuthentication';

function AuthenticationSourceCommon({
  errorMessage,
  updateConfiguration,
  appType,
}: {
  errorMessage: string;
  updateConfiguration: Function;
  appType: any;
}) {
  const [open, setOpen] = useState(false);
  const [importIDPMetadata, setImportIDPMetadata] = React.useState({
    open: false,
  });
  const token = getCookie('token');
  const [
    selectedAuthenticationSource,
    setSelectedAuthenticationSource,
  ] = useState('');
  const [allAuthenticationSources, setAllAuthenticationSources] = useState<
    any[]
  >([]);
  const [
    authenticationSourceDetails,
    setAuthenticationSourceDetails,
  ] = useState({
    authenticationName: null,
    issuer: null,
    loginURL: null,
    logoutURL: null,
    bindingType: 'HttpRedirect',
    X509Certificate: null,
    _id: null,
  });
  const [spMetadata, setSpMetadata] = React.useState<Object | null>(null);

  useEffect(() => {
    function updateAuthenticationSource() {
      updateConfiguration(authenticationSourceDetails);
    }
    updateAuthenticationSource();
  }, [authenticationSourceDetails]);

  const handleGenerateMetaData = () => {
    if (!authenticationSourceDetails._id) {
      generateMetaData(authenticationSourceDetails)
        .then((data) => {
          setOpen(true);
          setAuthenticationSourceDetails((prevValue) => ({
            ...prevValue,
            authenticationName: (data as any).success.sp_metadata.identifier,
            _id: (data as any).success.sp_metadata._id,
          }));
          setSpMetadata((data as any).success.sp_metadata);
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const uploadMetaIDPdata = async () => {
    setImportIDPMetadata((prevState) => ({ ...prevState, open: true }));
  };
  const handleAuthenticationSourceDetails = (event: any) => {
    var value = '';
    event.preventDefault();
    const { name } = event.target;
    value = event.target.value;
    value = value?.trim();
    setAuthenticationSourceDetails((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const selectAuthenticationSource = (event: SelectChangeEvent) => {
    setSelectedAuthenticationSource(event.target.value);
    if (event.target.value !== 'Add Authentication Source') {
      allAuthenticationSources.forEach((source) => {
        if (source.identifier === event.target.value) {
          setSpMetadata(source);
          setAuthenticationSourceDetails({
            authenticationName: source.identifier,
            issuer: source.saml.entity_id,
            loginURL: source.saml.login_url,
            logoutURL: source.saml.logout_url,
            bindingType: source.saml.binding_type,
            X509Certificate: source.saml.x_509_certificate,
            _id: source._id,
          });
        }
      });
    } else if (event.target.value === 'Add Authentication Source') {
      setAuthenticationSourceDetails({
        authenticationName: null,
        issuer: null,
        loginURL: null,
        logoutURL: null,
        bindingType: 'HttpRedirect',
        X509Certificate: null,
        _id: null,
      });
    }
  };

  useEffect(() => {
    async function getDraftedConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication(appType);
      allAuthenticationSources?.forEach((source) => {
        if (source?._id === DraftApplication?.success?.authentication_id) {
          setSpMetadata(source);
          setAuthenticationSourceDetails({
            authenticationName: source.identifier,
            issuer: source.saml.entity_id,
            loginURL: source.saml.login_url,
            logoutURL: source.saml.logout_url,
            bindingType: source.saml.binding_type,
            X509Certificate: source.saml.x_509_certificate,
            _id: source._id,
          });
          setSelectedAuthenticationSource(source.identifier);
        }
      });
    }
    getDraftedConfiguration();
  }, [allAuthenticationSources]);

  useEffect(() => {
    async function getAuthenticationSources() {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-configured-authentication-source`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          if (res.data && res.data.authentication_source) {
            setAllAuthenticationSources(res.data.authentication_source);
          }
        })
        .catch(() => {
          toast.error(
            process.env.REACT_APP_GET_CONFIGURED_AUTHENTICATION_SOURCE_ERROR,
          );
        });
    }
    getAuthenticationSources();
  }, []);

  const downloadXMLHandler = async () => {
    await downloadXML({
      authenticationSourceDetails,
      setAuthenticationSourceDetails,
      spMetadata,
      setSpMetadata,
    });
  };

  const generateAndOpenMetadataHandler = async () => {
    await generateAndOpenMetadata({
      authenticationSourceDetails,
      setAuthenticationSourceDetails,
      setSpMetadata,
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      className="authentication-source"
      height="67vh"
      sx={{ overflow: 'scroll' }}
    >
      <Box
        width="40%"
        display="flex"
        py={6}
        px={6}
        flexDirection="column"
        gap="0.5rem"
      >
        <Typography variant="h6">Authentication Source</Typography>
        <Typography variant="subtitle2" color="#56526B">
          Configure Authentication Source for your App.
        </Typography>
        <Box display="flex" flexDirection="column" gap="1rem">
          <Divider />
          <Box display="flex" flexDirection="column" gap="1rem">
            <Typography variant="caption">SP Metadata</Typography>
            <Box display="flex" flexDirection="column" gap="1rem">
              <Box display="flex" gap="1rem">
                {authenticationSourceDetails?._id === null && (
                  <Button
                    onClick={handleGenerateMetaData}
                    disabled={
                      !(authenticationSourceDetails as any).authenticationName
                    }
                    startIcon={<SettingsOutlinedIcon />}
                    sx={lightBackgroundButtonStyles}
                  >
                    Generate Metadata
                  </Button>
                )}
                {authenticationSourceDetails?._id !== null && (
                  <Button
                    onClick={() => setOpen(true)}
                    disabled={
                      !(authenticationSourceDetails as any).authenticationName
                    }
                    startIcon={<VisibilityIcon />}
                    sx={lightBackgroundButtonStyles}
                  >
                    View Metadata
                  </Button>
                )}
                <Button
                  onClick={generateAndOpenMetadataHandler}
                  disabled={
                    !(authenticationSourceDetails as any).authenticationName
                  }
                  startIcon={<InsertLinkIcon />}
                  sx={lightBackgroundButtonStyles}
                >
                  Metadata URL
                </Button>
              </Box>
              <Button
                onClick={downloadXMLHandler}
                disabled={
                  !(authenticationSourceDetails as any).authenticationName
                }
                startIcon={<FileDownloadOutlinedIcon />}
                sx={lightBackgroundButtonStyles}
              >
                Download Metadata
              </Button>
            </Box>
          </Box>
          <Divider />
          <Typography variant="caption">IDP Metadata</Typography>
          <Button
            onClick={uploadMetaIDPdata}
            sx={{ ...darkBackgroundButtonStyles, width: '12rem' }}
          >
            <FileUploadOutlinedIcon />
            &nbsp; Upload IDP Metadata
          </Button>
          <ImportIDPMetadata
            open={importIDPMetadata.open}
            setOpen={setImportIDPMetadata}
            authenticationSource={authenticationSourceDetails}
            setAuthenticationSource={setAuthenticationSourceDetails}
          />
          <Divider />
        </Box>

        <ImportIDPMetadata
          open={importIDPMetadata.open}
          setOpen={setImportIDPMetadata}
          authenticationSource={authenticationSourceDetails}
          setAuthenticationSource={setAuthenticationSourceDetails}
        />
        <SPMetadataModal
          open={open}
          setOpen={setOpen}
          SpMetadata={spMetadata}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="60%"
        px={6}
        py={5}
        gap="1.5rem"
        alignItems="center"
        color="#5F636D"
      >
        <Box>
          {errorMessage && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '30rem',
                color: 'white',
                backgroundColor: '#E6353D',
                borderRadius: '0.23rem',
                py: '0.5rem',
                px: '0.25rem',
                mb: '1rem',
              }}
            >
              <ErrorOutlineIcon />
              <Typography sx={{ ml: '0.25rem' }} variant="subtitle2">
                {errorMessage}
              </Typography>
            </Box>
          )}
          <Box display="flex" gap="2rem" alignItems="center">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              style={{
                width:
                  selectedAuthenticationSource !== 'Add Authentication Source'
                    ? '26rem'
                    : '30rem',
              }}
              sx={{ height: '2.2rem' }}
              onChange={selectAuthenticationSource}
              value={
                selectedAuthenticationSource
                || authenticationSourceDetails.authenticationName
                || ''
              }
            >
              {allAuthenticationSources?.map((source) => (
                <MenuItem value={source.identifier}>
                  {source.identifier}
                </MenuItem>
              ))}
              <MenuItem value="Add Authentication Source">
                Add Authentication Source
              </MenuItem>
            </Select>
            {selectedAuthenticationSource !== 'Add Authentication Source' && (
              <Box>
                <Link
                  to={`/edit/authentication-source/?identifier=${selectedAuthenticationSource}`}
                  style={{
                    pointerEvents:
                      selectedAuthenticationSource === '' ? 'none' : 'auto',
                  }}
                  target="_blank"
                >
                  <EditIcon
                    sx={{
                      color:
                        selectedAuthenticationSource === ''
                          ? 'lightgray'
                          : 'blue',
                    }}
                  />
                </Link>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="1.5rem"
          alignItems="center"
          width="30rem"
          height="1rem"
        >
          <TextField
            required
            fullWidth
            id="outlined-basic"
            size="small"
            name="authenticationName"
            label="Authentication Name"
            variant="outlined"
            sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
            value={authenticationSourceDetails.authenticationName || ''}
            disabled={
              selectedAuthenticationSource !== 'Add Authentication Source'
            }
            onChange={handleAuthenticationSourceDetails}
          />
          <TextField
            required
            fullWidth
            id="outlined-basic"
            size="small"
            name="issuer"
            label="IdP Entity ID or Issuer"
            variant="outlined"
            sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
            value={authenticationSourceDetails.issuer || ''}
            disabled={
              selectedAuthenticationSource !== 'Add Authentication Source'
            }
            onChange={handleAuthenticationSourceDetails}
          />
          <TextField
            fullWidth
            required
            id="outlined-basic"
            size="small"
            label="SAML Login URL"
            name="loginURL"
            variant="outlined"
            sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
            value={authenticationSourceDetails.loginURL || ''}
            disabled={
              selectedAuthenticationSource !== 'Add Authentication Source'
            }
            onChange={handleAuthenticationSourceDetails}
          />
          <TextField
            fullWidth
            required
            id="outlined-basic"
            size="small"
            label="SAML Logout URL"
            name="logoutURL"
            variant="outlined"
            sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
            value={authenticationSourceDetails.logoutURL || ''}
            disabled={
              selectedAuthenticationSource !== 'Add Authentication Source'
            }
            onChange={handleAuthenticationSourceDetails}
          />
          <Box width="100%">
            <Typography variant="body2">
              Select Binding Type for SSO Request:
            </Typography>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="bindingType"
              value={authenticationSourceDetails.bindingType || ''}
              onChange={handleAuthenticationSourceDetails}
            >
              <FormControlLabel
                value="HttpRedirect"
                control={<Radio />}
                label="HTTP-Redirect Binding"
                sx={{ fontSize: '13px' }}
                disabled={
                  selectedAuthenticationSource !== 'Add Authentication Source'
                }
              />
              <FormControlLabel
                value="HttpPost"
                control={<Radio />}
                label="HTTP-Post Binding"
                sx={{ fontSize: '15px' }}
                disabled={
                  selectedAuthenticationSource !== 'Add Authentication Source'
                }
              />
            </RadioGroup>
          </Box>
          <Box width="100%">
            <Typography variant="body2">X.509 Certificate</Typography>
            <TextField
              required
              fullWidth
              multiline
              name="X509Certificate"
              value={authenticationSourceDetails.X509Certificate || ''}
              rows={10}
              maxRows={10}
              sx={{ marginBottom: '2rem' }}
              disabled={
                selectedAuthenticationSource !== 'Add Authentication Source'
              }
              onChange={handleAuthenticationSourceDetails}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AuthenticationSourceCommon;
