import React from 'react';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface ErrorMessageProps {
  message: string;
}

export default function ErrorMessage({ message }: ErrorMessageProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        color: 'white',
        backgroundColor: '#E6353D',
        borderRadius: '0.23rem',
        py: '0.5rem',
        px: '0.25rem',
      }}
    >
      <ErrorOutlineIcon />
      <Typography sx={{ ml: '0.25rem' }} variant="subtitle2">
        {message}
      </Typography>
    </Box>
  );
}
