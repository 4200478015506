import React, { useEffect } from 'react';
import {
  Box, Typography, TextField, MenuItem, Divider,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Select from '@mui/material/Select';
import ErrorMessage from '../../../components/ErrorMessage';
import { getCookie } from '../../../utils/utility';
import RenderOffice365Apps from '../../../components/CASBApp/Office365/RenderOffice365Apps';
import { updateOffice365Configuration } from '../../../store/features/CreateAuthSourceSlice';

interface ManageGroupProps {
  errorMessage: string;
}

interface GroupSettingsFormProps {
  errorMessage: string;
  groupSettings: any;
  setGroupSettings: any;
  allPolicies: any;
  selectedGroup: any;
}

export function GroupSettingsForm({
  errorMessage,
  allPolicies,
  groupSettings,
  setGroupSettings,
  selectedGroup,
}: GroupSettingsFormProps) {
  const dispatch = useDispatch();
  const handleGroupSettings = (event: any) => {
    const { name, value } = event.target;
    setGroupSettings((prevValue: any) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  useEffect(() => {
    async function GroupSettings() {
      if (selectedGroup.length !== 0) {
        setGroupSettings({
          groupName: selectedGroup?.identifier,
          groupDescription: selectedGroup?.description,
          groupPolicy: selectedGroup?.policy_id,
          allowedApps: selectedGroup?.app_allowed_list,
          restrictedApps: selectedGroup?.app_restriction_list,
          blockApps: selectedGroup?.app_blocked_list,
          office365AppPolicy: selectedGroup?.app_custom_restriction_list,
        });
      } else {
        const token = await getCookie('token');
        const axiosConfig = {
          method: 'GET',
          url: `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-office365-apps`,
          params: {
            token,
          },
        };
        axios(axiosConfig)
          .then((response: any) => {
            if (response && response.data && response.data.googleApps) {
              const appList: string[] = [];
              response.data.googleApps.forEach((app: any) => {
                appList.push(app.appKey);
              });
              setGroupSettings({
                groupName: null,
                groupDescription: null,
                groupPolicy: '',
                allowedApps: [],
                restrictedApps: appList,
                blockApps: [],
                office365AppPolicy: [],
              });
            }
          })
          .catch(() => {
            toast.error(process.env.REACT_APP_GET_OFFICE_APPS_ERROR);
          });
      }
    }
    GroupSettings();
  }, [JSON.stringify(selectedGroup)]);

  useEffect(() => {
    function updateGroupDetails() {
      dispatch(
        updateOffice365Configuration({
          type: 'group-settings',
          data: groupSettings,
        }),
      );
    }
    updateGroupDetails();
  }, [groupSettings]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="60%"
      px={6}
      py={3}
      gap="1rem"
    >
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <TextField
        id="outlined-basic"
        size="small"
        label="Group Name"
        name="groupName"
        variant="outlined"
        sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
        inputProps={{
          style: {
            fontSize: '15px',
          },
        }}
        onChange={handleGroupSettings}
        value={groupSettings.groupName || ''}
      />
      <TextField
        id="outlined-basic"
        size="small"
        label="Group Description"
        name="groupDescription"
        variant="outlined"
        sx={{ backgroundColor: '#F6F6F6', border: 'none !important' }}
        inputProps={{
          style: {
            fontSize: '15px',
          },
        }}
        onChange={handleGroupSettings}
        value={groupSettings.groupDescription || ''}
      />
      <FormControl>
        <Typography variant="caption">
          Please select Office365 Policy
        </Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          name="groupPolicy"
          fullWidth
          sx={{ height: '2.2rem' }}
          onChange={handleGroupSettings}
          value={groupSettings?.groupPolicy}
        >
          {allPolicies?.map((source: any) => (
            <MenuItem value={source?._id}>{source?.identifier}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

function ManageGroups({ errorMessage }: ManageGroupProps) {
  const token = getCookie('token');
  const [allPolicies, setAllPolicies] = React.useState([]);
  const [groupSettings, setGroupSettings] = React.useState({
    groupName: null,
    groupDescription: null,
    groupPolicy: '',
  });

  useEffect(() => {
    async function getAllPolicies() {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-policies`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((response: any) => {
          if (response && response.data && response.data.PolicyData) {
            setAllPolicies(response.data.PolicyData);
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_ALL_POLICIES_ERROR);
        });
    }
    getAllPolicies();
  }, []);
  return (
    <Box height="67vh" sx={{ overflow: 'scroll' }}>
      <Box display="flex" flexDirection="row">
        <Box width="40%" display="flex" py={6} px={6} flexDirection="column">
          <Typography variant="h5">Group</Typography>
          <br />
          <Typography variant="subtitle2" color="#56526B">
            Group Settings for Office365 Proxy
          </Typography>
        </Box>
        <GroupSettingsForm
          allPolicies={allPolicies}
          groupSettings={groupSettings}
          setGroupSettings={setGroupSettings}
          errorMessage={errorMessage}
          selectedGroup={[]}
        />
      </Box>
      <Divider />
      <Box px={2} height="100%">
        <RenderOffice365Apps
          allPolicies={allPolicies}
          groupSettings={groupSettings}
          setGroupSettings={setGroupSettings}
        />
      </Box>
    </Box>
  );
}

export default ManageGroups;
