import React from 'react';
import { Box, Typography } from '@mui/material';
import AddNewPolicyForm from '../../GoogleConfiguration/Components/AddNewPolicyForm';

interface ManagePolicyProps {
  errorMessage: string;
}

function ManagePolicy({ errorMessage }: ManagePolicyProps) {
  return (
    <Box height="67vh" sx={{ overflow: 'scroll' }}>
      <Box display="flex" flexDirection="row">
        <Box width="40%" display="flex" py={6} pl={6} flexDirection="column">
          <Typography variant="h5">Policy</Typography>
          <br />
          <Typography variant="subtitle2" color="#56526B">
            Add new Policy Settings for Atlassian Proxy
          </Typography>
        </Box>
        <AddNewPolicyForm
          errorMessage={errorMessage}
          width="60%"
          selectedPolicy={[]}
          app={String(process.env.REACT_APP_ATLASSIAN_KEY)}
        />
      </Box>
    </Box>
  );
}
export default ManagePolicy;
