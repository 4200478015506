import React, { useState } from 'react';
import {
  Box, Typography, Switch, TextField, Button,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

interface SubstitutionProps {
  proxyInputDetails: any;
  setProxyInputDetails: Function;
}

interface HTMLString {
  find: string;
  replace: string;
}

function Substitution({
  proxyInputDetails,
  setProxyInputDetails,
}: SubstitutionProps) {
  const [htmlString, setHtmlString] = useState<HTMLString[]>(
    proxyInputDetails?.Substitution?.htmlString || [{ find: '', replace: '' }],
  );
  const [locationHeader, setLocationHeader] = useState<HTMLString[]>(
    proxyInputDetails?.Substitution?.locationHeader || [
      { find: '', replace: '' },
    ],
  );
  const addFormFieldsHTMLString = () => {
    setHtmlString([...htmlString, { find: '', replace: '' }]);
  };
  const addFormFieldsLocationHeader = () => {
    setLocationHeader([...htmlString, { find: '', replace: '' }]);
  };
  const handleChangeHtmlString = (index: number, event: any) => {
    const { name, value } = event.target;
    const data = [...htmlString];
    data[index][name as keyof HTMLString] = value;
    setHtmlString(data);
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      Substitution: {
        ...prevValue.Substitution,
        htmlString: data,
      },
    }));
  };
  const handleChangeLocationHeader = (index: number, event: any) => {
    const { name, value } = event.target;
    const data = [...locationHeader];
    data[index][name as keyof HTMLString] = value;
    setLocationHeader(data);
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      Substitution: {
        ...prevValue.Substitution,
        locationHeader: data,
      },
    }));
  };
  const handleSubstitutionToggle = (event: any) => {
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      Substitution: {
        ...prevValue.Substitution,
        enable: event.target.checked,
      },
    }));
  };
  const removeFieldsHtmlString = (index: number) => {
    const data = [...htmlString];
    data.splice(index, 1);
    setHtmlString(data);
  };
  const removeFieldsLocationHeader = (index: number) => {
    const data = [...locationHeader];
    data.splice(index, 1);
    setLocationHeader(data);
  };
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      sx={{ gap: '0.5rem' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Substitution</Typography>
          <Switch
            name="SubstitutionToggle"
            onChange={handleSubstitutionToggle}
            checked={proxyInputDetails?.Substitution?.enable}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <Typography variant="subtitle2">
          String/HTML Tag Substitution
        </Typography>
        <Typography variant="caption">
          1. String replacement is a method to replace a keyword by a desired
          new keyword in HTML response.
        </Typography>
        <Typography variant="caption">
          2. Substitution feature for string replacement is used to find and
          replace a string from the HTTP response of primary server address.
        </Typography>
        <Typography variant="caption">
          3. HTML tag replacement is a method to replace an entire HTML tag from
          response by a desired new HTML tag.
        </Typography>
        <Typography variant="caption">
          4. Substitution feature for HTM tag replacement is used to find and
          replace a HTML tag from the HTTP response of primary server address.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="0.75rem">
        <Box>
          <Button onClick={addFormFieldsHTMLString}>+ Add Member</Button>
        </Box>
        <Box display="flex" flexDirection="column">
          {htmlString.map((element: any, index: any) => (
            <Box display="flex" sx={{ gap: '0.5rem', padding: '0.25rem' }}>
              <TextField
                id="outlined-basic"
                size="small"
                name="find"
                label="eg. Example"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: 200,
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                value={element.find || ''}
                onChange={(event) => handleChangeHtmlString(index, event)}
              />
              <TextField
                id="outlined-basic"
                size="small"
                name="replace"
                label="eg. New Example"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: 200,
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                value={element.replace || ''}
                onChange={(event) => handleChangeHtmlString(index, event)}
              />
              <RemoveCircleIcon
                fontSize="large"
                sx={{ color: '#E34614', marginLeft: '2rem' }}
                onClick={() => removeFieldsHtmlString(index)}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <br />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <Typography variant="subtitle2">
          Location Header Substitution
        </Typography>
        <Typography variant="caption">
          1. The HTTP Location header is a response header that is used under 2
          circumstances to ask a browser to redirect a URL (status code 302) or
          provide information about the location of a newly created resource
          (status code of 201). Its usage is often confused with another HTTP
          Header which is HTTP Content-Location header.
        </Typography>
        <Typography variant="caption">
          2. Substitution feature for location based restriction is used to find
          and replace a domain from the primary server address to the proxied
          domain.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="0.75rem">
        <Box>
          <Button onClick={addFormFieldsLocationHeader}>+ Add Member</Button>
        </Box>
        <Box display="flex" flexDirection="column">
          {locationHeader.map((element: any, index: any) => (
            <Box display="flex" sx={{ gap: '0.5rem', padding: '0.25rem' }}>
              <TextField
                id="outlined-basic"
                size="small"
                name="find"
                label="eg. example.com"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: 200,
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                value={element.find || ''}
                onChange={(event) => handleChangeLocationHeader(index, event)}
              />
              <TextField
                id="outlined-basic"
                size="small"
                name="replace"
                label="eg. newsite.com"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: 200,
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                value={element.replace || ''}
                onChange={(event) => handleChangeLocationHeader(index, event)}
              />
              <RemoveCircleIcon
                fontSize="large"
                sx={{ color: '#E34614', marginLeft: '2rem' }}
                onClick={() => removeFieldsLocationHeader(index)}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Substitution;
