import React from 'react';
import { Outlet } from 'react-router-dom';
import ProtectedRouteForLogin from '../ProtectedRoutes/ProtectedRouteforLogin';

function PublicLayout() {
  return (
    <ProtectedRouteForLogin>
      <Outlet />
    </ProtectedRouteForLogin>
  );
}

export default PublicLayout;
