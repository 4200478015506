import React from 'react';
import { useDispatch } from 'react-redux';
import AuthenticationSourceCommon from '../../../components/CASBApp/AuthenticationSource';
import { updateOffice365Configuration } from '../../../store/features/CreateAuthSourceSlice';

interface AuthenticationSourceProps {
  errorMessage: string;
}

function AuthenticationSource({ errorMessage }: AuthenticationSourceProps) {
  const dispatch = useDispatch();
  return (
    <AuthenticationSourceCommon
      errorMessage={errorMessage}
      updateConfiguration={(config: any) => dispatch(
        updateOffice365Configuration({
          type: 'authentication-source',
          data: config,
        }),
      )}
      appType={process.env.REACT_APP_OFFICE365_KEY}
    />
  );
}

export default AuthenticationSource;
