import React, { useEffect, useState } from 'react';
import {
  Box, TextField, Button, Typography, MenuItem,
} from '@mui/material';
import Select from '@mui/material/Select';
import axios from 'axios';
import { toast } from 'react-toastify';
import SAMLIDPMetadata from '../../../components/SAMLIDPMetadata';
import {
  getUser,
  getCookie,
  getDraftProxyApplication,
  isValidDomain,
  isValidURL,
  testSAMLConnection,
} from '../../../utils/utility';
import ErrorMessage from '../../../components/ErrorMessage';

interface IGoogleApplication {
  identifier: null | string;
  organization_domain: null | string;
  attribute_key: null | string;
  name_attribute_key: null | string;
  acs_url: null | string;
  entity_id_url: null | string;
  authentication_saml_idp_metadata: object;
  authentication_id: string;
}
const initState: IGoogleApplication = {
  identifier: '',
  organization_domain: '',
  attribute_key: '',
  name_attribute_key: '',
  acs_url: '',
  entity_id_url: '',
  authentication_saml_idp_metadata: [],
  authentication_id: '',
};

function BasicSettings() {
  const [openIDPMetadata, setOpenIDPMetadata] = useState<boolean>(false);
  const [allAuthenticationSources, setAllAuthenticationSources] = useState<
    any[]
  >([]);
  const [basicSettingsError] = useState('');
  const [
    googleApplication,
    setGoogleApplication,
  ] = useState<IGoogleApplication>(initState);
  const InputFields = [
    'Organization Name*',
    'Organization Domain*',
    'Group Attribute Key*',
    'Name Attribute Key*',
    'Google ACS URL*',
    'Google Entity URL*',
  ];
  const InputFieldsName = [
    'identifier',
    'organization_domain',
    'attribute_key',
    'name_attribute_key',
    'acs_url',
    'entity_id_url',
  ];
  const [
    inputErrorMessage,
    setInputErrorMessage,
  ] = useState<IGoogleApplication>(initState);
  useEffect(() => {
    async function getAuthenticationSources() {
      const token = await getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-configured-authentication-source`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          if (res.data && res.data.authentication_source) {
            setAllAuthenticationSources(res.data.authentication_source);
          }
        })
        .catch(() => {
          toast.error(
            process.env.REACT_APP_GET_CONFIGURED_AUTHENTICATION_SOURCE_ERROR,
          );
        });
    }
    getAuthenticationSources();
  }, []);
  useEffect(() => {
    async function getDraftedGoogleConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication('google');
      setGoogleApplication(DraftApplication?.success);
    }
    getDraftedGoogleConfiguration();
  }, []);
  const handleBasicSettingsInput = (event: any) => {
    var { name, value } = event.target;
    switch (name) {
      case 'organization_domain':
        setInputErrorMessage((prevValue: IGoogleApplication) => ({
          ...prevValue,
          organization_domain: !isValidDomain(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      case 'acs_url':
        setInputErrorMessage((prevValue: IGoogleApplication) => ({
          ...prevValue,
          acs_url: !isValidURL(value)
            ? String(process.env.REACT_APP_INVALID_URL)
            : null,
        }));
        break;
      case 'entity_id_url':
        setInputErrorMessage((prevValue: IGoogleApplication) => ({
          ...prevValue,
          entity_id_url: !isValidURL(value)
            ? String(process.env.REACT_APP_INVALID_URL)
            : null,
        }));
        break;
      default:
        break;
    }
    value = value?.trim();
    setGoogleApplication((prevValue: any) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const updateGoogleApp = async () => {
    const user = await getUser();
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
        {
          user,
          token,
          customerID: user.customer_id,
          currentActiveTenantID: user.current_active_tenant_id,
          identifier: googleApplication.identifier,
          organization_domain: googleApplication.organization_domain,
          authentication_id: googleApplication.authentication_id,
          acs_url: googleApplication.acs_url,
          entity_id_url: googleApplication.entity_id_url,
          attribute_key: googleApplication.attribute_key,
          name_attribute_key: googleApplication.name_attribute_key,
          app: 'google',
        },
      )
      .then(() => {
        toast.success('Updated Configuration.');
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_UPDATE_PROXY_CONFIG_ERROR);
      });
  };
  const handleShowMetadataURL = async () => {
    const user = await getUser();
    window.open(
      `${process.env.REACT_APP_NODE_BACKEND_URL}/saml/${user.customer_id}/${user.current_active_tenant_id}/metadata?app_name=${process.env.REACT_APP_GOOGLE_KEY}`,
      '_blank',
    );
  };
  return (
    <Box width="100%" height="69vh" overflow="scroll">
      <Box
        display="flex"
        width="60%"
        flexDirection="column"
        pt={2}
        pb={4}
        sx={{ gap: '1.5rem' }}
      >
        {basicSettingsError && <ErrorMessage message={basicSettingsError} />}
        <Box>
          <Typography variant="caption">Authentication Source</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            name="authentication_id"
            label=""
            sx={{ width: '100%', height: '2.2rem' }}
            onChange={handleBasicSettingsInput}
            defaultValue="Authentication Source"
            value={googleApplication?.authentication_id || ''}
          >
            {allAuthenticationSources?.map((source: any) => (
              <MenuItem value={source._id}>{source.identifier}</MenuItem>
            ))}
          </Select>
        </Box>
        {InputFields.map((value, index) => (
          <Box display="flex" flexDirection="column">
            <TextField
              id="outlined-basic"
              size="small"
              variant="outlined"
              label={value}
              sx={{
                backgroundColor:
                  inputErrorMessage[
                    InputFieldsName[index] as keyof IGoogleApplication
                  ] === null
                  || inputErrorMessage[
                    InputFieldsName[index] as keyof IGoogleApplication
                  ] === ''
                    ? '#F6F6F6'
                    : '#ff8d0024',
                border: 'none !important',
              }}
              inputProps={{
                style: {
                  fontSize: '15px',
                },
              }}
              onChange={handleBasicSettingsInput}
              name={InputFieldsName[index]}
              value={
                InputFieldsName[index] === 'identifier'
                  ? googleApplication.identifier
                  : InputFieldsName[index] === 'organization_domain'
                    ? googleApplication.organization_domain
                    : InputFieldsName[index] === 'attribute_key'
                      ? googleApplication.attribute_key
                      : InputFieldsName[index] === 'name_attribute_key'
                        ? googleApplication.name_attribute_key
                        : InputFieldsName[index] === 'acs_url'
                          ? googleApplication.acs_url
                          : InputFieldsName[index] === 'entity_id_url'
                            ? googleApplication.entity_id_url
                            : ''
              }
            />
            <Typography variant="caption" color="red">
              {
                inputErrorMessage[
                  InputFieldsName[index] as keyof IGoogleApplication
                ]
              }
            </Typography>
          </Box>
        ))}

        <Box display="flex">
          <Button
            sx={{
              color: 'white',
              backgroundColor: '#2D3748',
              marginRight: '2rem',
              '&:hover': {
                backgroundColor: '#2D3748',
                color: 'white',
              },
              width: '7rem',
            }}
            onClick={updateGoogleApp}
          >
            Save
          </Button>
          <Box display="flex" alignItems="center">
            <SAMLIDPMetadata
              open={openIDPMetadata}
              setOpen={setOpenIDPMetadata}
              IDPMetadata={googleApplication.authentication_saml_idp_metadata}
              appName={process.env.REACT_APP_GOOGLE_KEY}
            />
            <Button
              sx={{ fontSize: '15px', backgroundColor: 'lightblue', px: 3 }}
              onClick={handleShowMetadataURL}
            >
              Metadata URL
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              sx={{ fontSize: '15px', backgroundColor: 'lightblue', px: 3 }}
              onClick={() => setOpenIDPMetadata(true)}
            >
              View IDP metadata
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              sx={{ fontSize: '15px', backgroundColor: 'lightblue', px: 3 }}
              onClick={() => testSAMLConnection(
                googleApplication?.authentication_id,
                String(process.env.REACT_APP_GOOGLE_KEY),
              )}
            >
              Test Connection
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BasicSettings;
