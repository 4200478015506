import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  Table,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import MailLockOutlinedIcon from '@mui/icons-material/MailLockOutlined';
import NetworkLockedOutlinedIcon from '@mui/icons-material/NetworkLockedOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import jwt from 'jwt-decode';
import axios from 'axios';
import MainLayout from '../../components/MainLayout';
import GoogleLogo from '../../assets/images/Google_Full.png';
import ShopifyLogo from '../../assets/images/shopify_nonplus.png';
import ShopifyPlusLogo from '../../assets/images/shopify-plus-logo.png';
import AtlassianLogo from '../../assets/images/atlassian.png';
import Office365Logo from '../../assets/images/office365-logo.png';
import { getAllTenants, getDraftProxyApplication } from '../../utils/utility';
import '../../index.css';

function AccessButton(): React.ReactElement {
  const navigate = useNavigate();
  return (
    <Button
      sx={{
        width: '150px',
        alignSelf: 'center',
        color: '#4339F2',
        marginY: '0.5rem',
        border: '1px solid #4339F2',
        textTransform: 'none',
        ':hover': {
          backgroundColor: '#2D3748',
          color: 'white',
          border: 'none',
        },
      }}
      onClick={() => navigate('/add/proxy')}
    >
      Access&nbsp;&nbsp;
      <ChevronRightIcon />
    </Button>
  );
}

interface AddAppButtonProps {
  app: string;
  href: string;
  data: any;
}

function AddAppButton({
  app,
  href,
  data,
}: AddAppButtonProps): React.ReactElement {
  if (
    app === process.env.REACT_APP_GOOGLE_KEY
    && !data
    && !data?.authentication_id
  ) {
    href = '/configure/casb/google';
  } else if (
    app === process.env.REACT_APP_GOOGLE_KEY
    && !data?.authentication_id
    && !data?.identifier
  ) {
    href = '/configure/casb/google/';
  } else if (
    app === process.env.REACT_APP_GOOGLE_KEY
    && data?.authentication_id
    && data?.identifier
  ) {
    href = '/edit/casb/google';
  } else if (
    app === process.env.REACT_APP_SHOPIFY_PLUS_KEY
    && !data
    && !data?.authentication_id
  ) {
    href = '/configure/casb/shopifyplus';
  } else if (
    app === process.env.REACT_APP_SHOPIFY_PLUS_KEY
    && !data?.authentication_id
    && !data?.identifier
  ) {
    href = '/configure/casb/shopifyplus/';
  } else if (
    app === process.env.REACT_APP_SHOPIFY_PLUS_KEY
    && data?.authentication_id
    && data?.identifier
  ) {
    href = '/edit/casb/shopifyplus';
  } else if (
    app === process.env.REACT_APP_ATLASSIAN_KEY
    && !data
    && !data?.authentication_id
  ) {
    href = '/configure/casb/atlassian';
  } else if (
    app === process.env.REACT_APP_ATLASSIAN_KEY
    && !data?.authentication_id
    && !data?.identifier
  ) {
    href = '/configure/casb/atlassian/';
  }

  const text = data?._id === undefined ? 'Add App' : 'Edit App';
  return (
    <Link to={href} style={{ textDecoration: 'none', color: '#2D3748' }}>
      <Button
        sx={{
          background: '#F5F6F8',
          alignSelf: 'center',
          color: '#2D3748',
        }}
      >
        <Typography variant="caption" textTransform="none" fontWeight="500">
          {text}
        </Typography>
      </Button>
    </Link>
  );
}

function GuideAndSetupLink({ guideLink, videoLink }: any): React.ReactElement {
  return (
    <Box display="flex" gap={2} mt={4}>
      {guideLink && (
        <Link
          style={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            color: '#5177FF',
          }}
          to={guideLink}
        >
          <OpenInNewOutlinedIcon />
          &nbsp;
          <Typography
            variant="button"
            fontWeight="500"
            textTransform="none"
            fontSize="small"
          >
            Guide Link
          </Typography>
        </Link>
      )}
      {videoLink && (
        <Link
          style={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            color: '#5177FF',
          }}
          to={videoLink}
        >
          <PlayCircleOutlineOutlinedIcon />
          &nbsp;
          <Typography
            variant="button"
            textTransform="none"
            fontWeight="500"
            fontSize="small"
          >
            Video Link
          </Typography>
        </Link>
      )}
    </Box>
  );
}

interface DomainConfigurationProps {
  currentTenant: string;
  configuredDomain: any;
  allTenants: any;
}

function DomainConfiguration({
  currentTenant,
  configuredDomain,
  allTenants,
}: DomainConfigurationProps): React.ReactElement {
  console.log(configuredDomain);
  const navigate = useNavigate();
  let count = 0;
  return (
    <Grid container xs={6} md={6.5} p={3}>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        height="auto"
        alignItems="center"
        gap={0.5}
        justifyContent="space-between"
      >
        <Typography variant="subtitle2" fontWeight="600">
          {/* Domain Information */}
          Tenant Information
        </Typography>
        <Box display="flex" gap={2}>
          <Box>
            <Button
              sx={{
                backgroundColor: '#F5F6F8',
                color: '#2D3748',
                textTransform: 'none',
              }}
              onClick={() => navigate('/tenant-view')}
            >
              View All
            </Button>
          </Box>
          {/* <Box>
            <Button
              sx={{
                backgroundColor: '#2D3748',
                color: '#ffffff',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#2D3748',
                  color: '#ffffff',
                },
              }}
            >
              Add New Domain
            </Button>
          </Box> */}
        </Box>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {/* Current Active Tenant */}
                Tenant Name
              </TableCell>
              <TableCell>
                {/* Configured Domain */}
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ paddingY: 1 }}>{currentTenant}</TableCell>
              {/* {configuredDomain.length === 0 && (
                <TableCell>Please Configure a Domain</TableCell>
              )} */}
              {/* {configuredDomain && (
                <TableCell>
                  {Object.values(configuredDomain).map((value: any) => (
                    <div>{value}</div>
                  ))}
                </TableCell>
              )} */}
              <TableCell sx={{ color: 'green', paddingY: 1 }}>Active</TableCell>
            </TableRow>
            {allTenants.map(
              (element: any) => element.tenant_name !== currentTenant
                && count < 2 && (
                  <TableRow key={count++}>
                    <TableCell sx={{ paddingY: 1 }}>
                      {element.tenant_name}
                    </TableCell>
                    <TableCell sx={{ color: 'red', paddingY: 1 }}>
                      Inactive
                    </TableCell>
                  </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

function HeaderBasedAuthentication(): React.ReactElement {
  return (
    <Grid item xs={6} md={6} p={2} borderRadius={1} border="1px solid  #D9D9D9">
      <Box height="7rem">
        <NetworkLockedOutlinedIcon
          sx={{ color: '#6F6F6F' }}
          style={{ fontSize: 40 }}
        />
        <Typography variant="subtitle1" fontWeight="600">
          {/* Header Based Authentication */}
          Caching & Performance
        </Typography>
      </Box>
      <AccessButton />
      <GuideAndSetupLink guideLink="" videoLink="" />
    </Grid>
  );
}

function URLRewriting(): React.ReactElement {
  return (
    <Grid
      item
      xs={6}
      md={5.75}
      p={2}
      borderRadius={1}
      border="1px solid  #D9D9D9"
    >
      <Box height="7rem">
        <LinkOutlinedIcon sx={{ color: '#6F6F6F' }} style={{ fontSize: 40 }} />
        <Typography variant="subtitle1" fontWeight="600">
          URL Re-writing
        </Typography>
      </Box>
      <AccessButton />
      <GuideAndSetupLink guideLink="" videoLink="" />
    </Grid>
  );
}

function MFAuthentication(): React.ReactElement {
  return (
    <Grid
      item
      xs={6}
      md={2.75}
      borderRadius={1}
      p={2}
      border="1px solid  #D9D9D9"
    >
      <Box>
        <MailLockOutlinedIcon
          sx={{ color: '#6F6F6F' }}
          style={{ fontSize: 40 }}
        />
        <Typography variant="subtitle1" fontWeight="600">
          IP Restriction
        </Typography>
      </Box>
      <AccessButton />
      <GuideAndSetupLink guideLink="" videoLink="" />
    </Grid>
  );
}

function getCookie(name: string): any {
  const pair = document.cookie
    .split('; ')
    .find((x) => x.startsWith(`${name}=`));
  if (pair) return pair.split('=')[1];
  return '';
}
interface AppItemProps {
  app: string;
  logoSrc: string;
  href: string;
  data: any;
}

function AppItem({
  logoSrc,
  app,
  href,
  data,
}: AppItemProps): React.ReactElement {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderRadius={1}
      border="1px solid  #D9D9D9"
      px={3}
      py={2}
      width="32%"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <Box alignItems="center" display="flex">
          <img src={logoSrc} height="40" alt={app} />
        </Box>
        <AddAppButton app={app} href={href} data={data} />
      </Box>
      <Box>
        <GuideAndSetupLink guideLink="" videoLink="" />
      </Box>
    </Box>
  );
}

interface CommomCasbComponnetProps {
  dataGoogle: any;
  dataShopifyNonPlus: any;
  dataShopifyPlus: any;
  dataAtlassian: any;
  dataOffice365: any;
}

function CommonCasbComponnet({
  dataAtlassian,
  dataGoogle,
  dataShopifyNonPlus,
  dataShopifyPlus,
  dataOffice365,
}: CommomCasbComponnetProps): React.ReactElement {
  const applications: Array<{
    app: string;
    logoSrc: string;
    href: string;
    data: any;
  }> = [
    {
      app: String(process.env.REACT_APP_ATLASSIAN_PLUS_KEY),
      logoSrc: AtlassianLogo,
      href: '/configure/casb/atlassian',
      data: dataAtlassian,
    },
    {
      app: String(process.env.REACT_APP_GOOGLE_KEY),
      logoSrc: GoogleLogo,
      href: '/configure/casb/google',
      data: dataGoogle,
    },
    {
      app: 'shopify',
      logoSrc: ShopifyLogo,
      href: '/configure/casb/shopify',
      data: dataShopifyNonPlus,
    },
    {
      app: String(process.env.REACT_APP_SHOPIFY_PLUS_KEY),
      logoSrc: ShopifyPlusLogo,
      href: '/configure/casb/shopifyplus',
      data: dataShopifyPlus,
    },
    {
      app: String(process.env.REACT_APP_OFFICE365_KEY),
      logoSrc: Office365Logo,
      href: '/configure/casb/office365',
      data: dataOffice365,
    },
    // Add more applications as needed
  ];
  const itemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the range of applications to display based on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the applications array based on the current page
  const displayedApplications = applications.slice(startIndex, endIndex);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <Grid
      item
      xs={6}
      md={9}
      borderRadius={1}
      p={1}
      border="1px solid #D9D9D9"
      direction="row"
    >
      <Grid container>
        <Grid item xs={6} md={8}>
          <Box display="flex" alignItems="center" width="100%">
            <CloudSyncOutlinedIcon
              sx={{ color: '#6F6F6F' }}
              style={{ fontSize: 40 }}
            />

            <Typography variant="subtitle1" fontWeight="600">
              Cloud Access Security Brokers
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={4} justifyContent="center">
          <Box display="flex" flexDirection="column">
            {/* <AccessButton /> */}
          </Box>
        </Grid>
      </Grid>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        my={2}
        gap={2}
      >
        {displayedApplications.map((app) => (
          <AppItem key={app.app} {...app} />
        ))}
      </Box>
      {applications.length > itemsPerPage && (
        <Box display="flex" justifyContent="space-between">
          <Button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            style={{
              borderRadius: '50%',
              background: '#D3D3D3',
              color: 'white',
              minWidth: 0,
              width: '20px',
              height: '20px',
            }}
          >
            <ChevronLeftIcon />
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={endIndex >= applications.length}
            style={{
              borderRadius: '50%',
              background: '#D3D3D3',
              color: 'white',
              minWidth: 0,
              width: '20px',
              height: '20px',
            }}
          >
            <ChevronRightIcon />
          </Button>
        </Box>
      )}
    </Grid>
  );
}

function Dashboard(): any {
  const [currentTenant, setCurrentTenant] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [domainList, setDomainList] = useState([]);
  const [, setConfiguredDomain] = useState([]);
  const [alltenants, setAllTenants] = useState<any[]>([]);
  const [googleApplication, setGoogleApplication] = useState([]);
  const [shopifyPlusApplication, setShopifyPlusApplication] = useState([]);
  const [atlassianApplication, setAtlassianApplication] = useState([]);
  const [shopifyNonPlusApplication, setShopifyNonPlusApplication] = useState(
    [],
  );
  const [office365Application, setOffice365Application] = useState([]);
  useEffect(() => {
    async function getDomaninInfo() {
      const token = getCookie('token');
      const user = jwt(token);
      const email = user?.email;
      setUserEmail(email);
      const currentActiveTenantId = user?.current_active_tenant_id;
      const axiosConfig = {
        method: 'GET',
        url: `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-domains`,
        params: {
          token,
          currentActiveTenantId,
        },
      };
      axios(axiosConfig).then((response) => {
        setCurrentTenant(response.data.message.tenant_name);
        setConfiguredDomain(response.data.message.configured_domain);
        const domainlist: any = [];
        response.data.message.configured_domain.forEach((info: any) => {
          domainlist.push(info.domain);
        });
        setDomainList(domainlist);
      });
    }
    async function getAllTenantsInfo() {
      getAllTenants().then((data: any) => {
        setAllTenants(data);
      });
    }
    async function getDraftApplicationStatus() {
      const DraftGoogleApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_GOOGLE_KEY,
      );
      setGoogleApplication(DraftGoogleApplication?.success);

      const DraftShopifyNPApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
      );
      setShopifyNonPlusApplication(DraftShopifyNPApplication?.success);

      const DraftShopifyPlusApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_SHOPIFY_PLUS_KEY,
      );
      setShopifyPlusApplication(DraftShopifyPlusApplication?.success);

      const DraftAtlassianApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_ATLASSIAN_KEY,
      );
      setAtlassianApplication(DraftAtlassianApplication?.success);

      const DraftOffice365Application: any = await getDraftProxyApplication(
        process.env.REACT_APP_OFFICE365_KEY,
      );
      setOffice365Application(DraftOffice365Application?.success);
    }

    getDraftApplicationStatus();
    getAllTenantsInfo();
    getDomaninInfo();
  }, []);

  return (
    <MainLayout>
      <Box>
        <Box display="flex" width="100%" px={3} py={2} alignItems="center">
          <Typography variant="subtitle1" fontWeight="500">
            Hey,&nbsp;
          </Typography>
          <Typography variant="subtitle1" fontWeight="500" color="#50514F">
            {userEmail}
          </Typography>
          <WavingHandIcon sx={{ color: '#F1C27D', marginLeft: '1rem' }} />
        </Box>
        <Divider />
        <Grid container p={3} justifyContent="space-between">
          <CommonCasbComponnet
            dataAtlassian={atlassianApplication}
            dataGoogle={googleApplication}
            dataShopifyNonPlus={shopifyNonPlusApplication}
            dataShopifyPlus={shopifyPlusApplication}
            dataOffice365={office365Application}
          />
          <MFAuthentication />
          <Grid item xs={6} md={5.5} borderRadius={3} mt={3}>
            <Grid container justifyContent="space-between">
              <HeaderBasedAuthentication />
              <URLRewriting />
            </Grid>
          </Grid>
          <DomainConfiguration
            currentTenant={currentTenant}
            configuredDomain={domainList}
            allTenants={alltenants}
          />
        </Grid>
      </Box>
    </MainLayout>
  );
}

export default Dashboard;
