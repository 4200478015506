import React, { useEffect, useState } from 'react';
import {
  Box, Divider, Typography, Button,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as KJUR from 'jsrsasign';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../components/MainLayout';
import office365logo from '../../assets/images/office365-logo.png';
import CircularIcon from '../../components/CircularIcon';
import AuthenticationSource from './components/AuthenticationSource';
import BasicSettings from './components/BasicSettings';
import {
  getDraftProxyApplication,
  isValidURL,
  isPEMCertificateValid,
  getCookie,
  isValidDomain,
  isValidIPAddress,
  testSAMLConnection,
} from '../../utils/utility';
import ManagePolicy from './components/ManagePolicy';
import ManageGroup from './components/ManageGroups';

interface completedStateProps {
  authentication_source: boolean;
  basic_settings: boolean;
  policy_settings: boolean;
  group_settings: boolean;
}

function Office365() {
  const navigate = useNavigate();
  const EmptyFieldError = 'Please fill all required fields.';
  const token = getCookie('token');
  const [draftApplication, setDraftApplication] = useState<any>([]);
  const [authenticationSourceError, setAuthenticationSourceError] = useState(
    '',
  );
  const [basicSettingsError, setBasicSettingsError] = useState('');
  const [policySettingError, setPolicySettingError] = useState('');
  const [groupSettingError, setGroupSettingError] = useState('');
  const Office365Configuration = useSelector(
    (state: any) => state.Office365Configuration,
  );
  const [step, setStep] = useState(1);
  const [, setCompletedState] = useState<completedStateProps>({
    authentication_source: false,
    basic_settings: false,
    policy_settings: false,
    group_settings: false,
  });
  useEffect(() => {
    async function getDraftConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_OFFICE365_KEY,
      );
      setDraftApplication(DraftApplication?.success);
      if (
        DraftApplication?.success?.authentication_id
        && DraftApplication?.success?.identifier
        && DraftApplication?.success?.organization_domain
        && DraftApplication?.success?.attribute_key
      ) {
        navigate('/edit/casb/office365');
      }
    }
    getDraftConfiguration();
  }, []);
  const updateAuthenticationSource = async () => {
    if (
      !Office365Configuration
      || !Office365Configuration.AuthenticationSource
      || !Office365Configuration.AuthenticationSource.authenticationName
      || !Office365Configuration.AuthenticationSource.issuer
      || !Office365Configuration.AuthenticationSource.loginURL
      || !Office365Configuration.AuthenticationSource.logoutURL
      || !Office365Configuration.AuthenticationSource.bindingType
      || !Office365Configuration.AuthenticationSource.X509Certificate
    ) {
      document.getElementsByClassName('authentication-source')[0].scrollTop = 0;
      setAuthenticationSourceError('Please fill all required fields.');
    } else if (
      !isValidURL(Office365Configuration.AuthenticationSource.issuer)
      || !isValidURL(Office365Configuration.AuthenticationSource.loginURL)
      || !isValidURL(Office365Configuration.AuthenticationSource.logoutURL)
    ) {
      document.getElementsByClassName('authentication-source')[0].scrollTop = 0;
      setAuthenticationSourceError('Invalid URL.');
    } else {
      const cert = new KJUR.X509();
      if (
        isPEMCertificateValid(
          Office365Configuration.AuthenticationSource.X509Certificate,
        )
      ) {
        try {
          cert.readCertPEM(
            Office365Configuration.AuthenticationSource.X509Certificate,
          );
          const issuer = cert.getIssuerString();
          if (!issuer) {
            document.getElementsByClassName(
              'authentication-source',
            )[0].scrollTop = 0;
            setAuthenticationSourceError(
              'Certificate may be corrupt or in an incorrect format.',
            );
            return;
          }
        } catch (error) {
          document.getElementsByClassName(
            'authentication-source',
          )[0].scrollTop = 0;
          setAuthenticationSourceError(
            'Certificate may be corrupt or in an incorrect format.',
          );
          return;
        }
      } else {
        document.getElementsByClassName(
          'authentication-source',
        )[0].scrollTop = 0;
        setAuthenticationSourceError('Header and Footer Missing');
        return;
      }
      const url = !Office365Configuration.AuthenticationSource._id
        ? `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-authentication-source`
        : `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-authentication-source`;
      axios
        .post(
          url,
          {
            data: Office365Configuration.AuthenticationSource,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res: any) => {
          let updatedAuthenticationDetails;
          if (!Office365Configuration?.AuthenticationSource?._id) {
            updatedAuthenticationDetails = {
              authenticationName:
                Office365Configuration.AuthenticationSource.authenticationName,
              issuer: Office365Configuration.AuthenticationSource.issuer,
              loginURL: Office365Configuration.AuthenticationSource.loginURL,
              logoutURL: Office365Configuration.AuthenticationSource.logoutURL,
              bindingType:
                Office365Configuration.AuthenticationSource.bindingType,
              X509Certificate:
                Office365Configuration.AuthenticationSource.X509Certificate,
              _id:
                res.authenticationDetails._id
                || Office365Configuration.AuthenticationSource._id,
            };
          } else {
            updatedAuthenticationDetails = Office365Configuration.AuthenticationSource;
          }
          document.getElementsByClassName(
            'authentication-source',
          )[0].scrollTop = 0;
          axios
            .post(
              `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
              {
                authentication_id: updatedAuthenticationDetails._id,
                app: process.env.REACT_APP_OFFICE365_KEY,
              },
              {
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(() => {
              setCompletedState((prevState) => ({
                ...prevState,
                authentication_source: true,
              }));
              setStep(step + 1);
            });
        })
        .catch((error: any) => {
          document.getElementsByClassName(
            'authentication-source',
          )[0].scrollTop = 0;
          setAuthenticationSourceError(error?.response?.data?.message);
        });
    }
  };

  const updateBasicSettings = () => {
    const BasicSettingConfiguration = Office365Configuration.BasicSettings;
    if (
      !BasicSettingConfiguration
      || !BasicSettingConfiguration?.identifier
      || !BasicSettingConfiguration?.organization_domain
      || !BasicSettingConfiguration?.acs_url
    ) {
      setBasicSettingsError(EmptyFieldError);
    } else if (
      !isValidDomain(BasicSettingConfiguration.organization_domain)
      || !isValidURL(BasicSettingConfiguration.acs_url)
    ) {
      setBasicSettingsError('URLs provided are not valid.');
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
          {
            identifier: BasicSettingConfiguration.identifier,
            organization_domain: BasicSettingConfiguration.organization_domain,
            authentication_id: draftApplication?.authentication_id,
            acs_url: BasicSettingConfiguration?.acs_url,
            entity_id_url: BasicSettingConfiguration.entity_id_url,
            attribute_key: BasicSettingConfiguration.attribute_key,
            name_attribute_key: BasicSettingConfiguration.name_attribute_key,
            mdm: BasicSettingConfiguration.mdm,
            casb_type: 'offline',
            app: process.env.REACT_APP_OFFICE365_KEY,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          setStep(step + 1);
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_UPDATE_PROXY_CONFIG_ERROR);
        });
    }
  };

  const updatePolicySettings = () => {
    var hasInvalidIP = false;
    const PolicySettingConfiguration = Office365Configuration.PolicySettings;
    if (
      !PolicySettingConfiguration
      || !PolicySettingConfiguration.policyName
      || !PolicySettingConfiguration.policyDescription
    ) {
      setPolicySettingError(EmptyFieldError);
    } else if (PolicySettingConfiguration?.enableIPRestriction) {
      hasInvalidIP = PolicySettingConfiguration?.IPAddresses.some((ip: any) => {
        if (!ip || !isValidIPAddress(ip)) {
          toast.error('Invalid IP Address');
          return true;
        }
        return false;
      });
    }
    if (hasInvalidIP) {
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-proxy-policy`,
        {
          policy_details: PolicySettingConfiguration,
          type: 'save',
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        setStep(step + 1);
      })
      .catch((error) => {
        if (
          error.response
          && error.response.data
          && error.response.data.message
        ) {
          setPolicySettingError(error.response.data.message);
        }
      });
  };
  const downloadScript = async () => {
    const OfficeApplication: any = await getDraftProxyApplication(
      process.env.REACT_APP_OFFICE365_KEY,
    );
    const BasicSettingConfiguration = Office365Configuration.BasicSettings;
    if (!BasicSettingConfiguration?.organization_domain) {
      toast.error('Please enter a valid domain in form.');
      return;
    }
    const logoutURL = OfficeApplication?.authentication_saml_idp_metadata?.login_url.replace(
      'login',
      'logout',
    );
    await axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-federation-script`,
        {
          entity_id:
            OfficeApplication?.success?.authentication_saml_idp_metadata
              ?.entity_id,
          login_url:
            OfficeApplication?.success?.authentication_saml_idp_metadata
              ?.login_url,
          logout_url: logoutURL,
          x_509_certificate:
            OfficeApplication?.success?.authentication_saml_idp_metadata
              ?.x_509_certificate,
          domain: BasicSettingConfiguration?.organization_domain,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(async (response) => {
        const content = await response.data;

        // Create a Blob from the script content
        const blob = new Blob([content], { type: 'text/plain' });

        // Create a link element and trigger a download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'federated_domain.ps1';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const updateGroupSetting = () => {
    const GroupSettingConfiguration = Office365Configuration.GroupSettings;
    if (
      !GroupSettingConfiguration
      || !GroupSettingConfiguration.groupName
      || !GroupSettingConfiguration.groupDescription
      || !GroupSettingConfiguration.groupPolicy
    ) {
      setGroupSettingError(EmptyFieldError);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-group-settings`,
          {
            group_details: GroupSettingConfiguration,
            app: process.env.REACT_APP_OFFICE365_KEY,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          navigate('/edit/casb/office365');
        })
        .catch((error) => {
          setGroupSettingError(error?.response?.data.message);
        });
    }
  };

  const handlePreviousPage = () => {
    if (step - 1 <= 0) {
      return;
    }
    setStep(step - 1);
  };
  return (
    <MainLayout>
      <Box display="flex" flexDirection="column" sx={{ height: '100%' }}>
        <ToastContainer />
        <Box
          display="flex"
          flexDirection="column"
          sx={{ height: '92% !important' }}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            height="15vh"
            borderBottom="1px solid #D9D9D9"
          >
            <Box
              width="40%"
              px={3}
              py={6}
              display="flex"
              alignItems="center"
              borderBottom="1px solid black"
            >
              <img
                src={office365logo}
                alt="office365-logo"
                height={40}
                style={{
                  marginLeft: '4rem',
                }}
              />
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="right"
              marginRight="0rem"
              color="#2D3748"
              px={3}
              py={6}
            >
              <Box display="flex" alignItems="center">
                <CircularIcon number={1} step={step} />
                <Typography variant="body2" ml="1rem">
                  Authentication Source
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mx="1rem">
                <Divider sx={{ width: '1rem' }} />
              </Box>
              <Box display="flex" alignItems="center">
                <CircularIcon number={2} step={step} />
                <Typography variant="body2" ml="1rem">
                  Basic Settings
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mx="1rem">
                <Divider sx={{ width: '1rem' }} />
              </Box>
              <Box display="flex" alignItems="center">
                <CircularIcon number={3} step={step} />
                <Typography variant="body2" ml="1rem">
                  Manage Policy
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mx="1rem">
                <Divider sx={{ width: '1rem' }} />
              </Box>
              <Box display="flex" alignItems="center">
                <CircularIcon number={4} step={step} />
                <Typography variant="body2" ml="1rem">
                  Users & Groups
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" width="100%">
            {(step === 1 && (
              <AuthenticationSource errorMessage={authenticationSourceError} />
            ))
              || (step === 2 && (
                <BasicSettings
                  errorMessage={basicSettingsError}
                  setBasicSettingsError={setBasicSettingsError}
                />
              ))
              || (step === 3 && (
                <ManagePolicy errorMessage={policySettingError} />
              ))
              || (step === 4 && <ManageGroup errorMessage={groupSettingError} />)}
          </Box>
          <Divider />
          <Box
            display="flex"
            alignItems="flex-end"
            width="100%"
            p={2}
            justifyContent="right"
            sx={{ marginBottom: 'auto' }}
          >
            {step === 3 && (
              <Button
                variant="text"
                onClick={() => {
                  setStep(step + 1);
                }}
              >
                Skip
              </Button>
            )}
            {step === 4 && (
              <Button
                variant="text"
                onClick={() => {
                  navigate('/edit/casb/office365');
                }}
              >
                Skip
              </Button>
            )}
            {step === 1 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={updateAuthenticationSource}
              >
                Save & Next
              </Button>
            )}
            {step === 2 && (
              <Box display="flex" gap="2rem" mr="2rem">
                <Button
                  variant="text"
                  sx={{
                    fontSize: '15px',
                    backgroundColor: 'lightblue',
                    px: 3,
                  }}
                  onClick={downloadScript}
                >
                  Download Federation Script
                </Button>
                <Button
                  sx={{
                    fontSize: '15px',
                    backgroundColor: 'lightblue',
                    px: 3,
                  }}
                  onClick={() => testSAMLConnection(
                    draftApplication?.authentication_id,
                    String(process.env.REACT_APP_COMMON_AUTH_SOURCE),
                  )}
                >
                  Test Connection
                </Button>
              </Box>
            )}
            {step > 1 && step <= 3 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={handlePreviousPage}
              >
                Previous
              </Button>
            )}
            {step === 2 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={updateBasicSettings}
              >
                Save & Next
              </Button>
            )}
            {step === 3 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={updatePolicySettings}
              >
                Save & Next
              </Button>
            )}
            {step === 4 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={updateGroupSetting}
              >
                Save & Next
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default Office365;
