import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Divider, Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import * as KJUR from 'jsrsasign';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

import MainLayout from '../../components/MainLayout';
import AtlassianLogo from '../../assets/images/atlassian.png';
import CircularIcon from '../../components/CircularIcon';
import AtlassianAuthenticationSource from './components/AtlassianAuthenticationSource';
import BasicSettings from './components/BasicSettings';
import ManagePolicy from './components/ManagePolicy';
import ManageGroup from './components/ManageGroup';
import {
  getCookie,
  isValidURL,
  isPEMCertificateValid,
  getDraftProxyApplication,
  isValidDomain,
  isValidIPAddress,
  getUser,
} from '../../utils/utility';
import CircularTick from '../../components/CircularTick';
import SAMLIDPMetadata from '../../components/SAMLIDPMetadata';

interface draftApplicationType {
  authentication_id: string;
  atlassian_identifier: string;
  authentication_saml_idp_metadata: Object;
}

function Atlassian() {
  const navigate = useNavigate();
  const EmptyFieldError = 'Please fill all required fields.';
  const token = getCookie('token');

  const [step, setStep] = useState(1);

  const AtlassianConfiguration = useSelector(
    (state: any) => state.AtlassianConfiguration,
  );

  const [authSourceCompleted, setAuthSourceCompleted] = useState(false);
  const [basicSettingsCompleted, setBasicSettingsCompleted] = useState(false);
  const [policySettingsCompleted, setPolicySettingsCompleted] = useState(false);

  const [openIDPMetadata, setOpenIDPMetadata] = useState(false);

  const [authenticationSourceError, setAuthenticationSourceError] = useState(
    '',
  );
  const [basicSettingsError, setBasicSettingsError] = useState('');
  const [policySettingError, setPolicySettingError] = useState('');
  const [groupSettingError, setGroupSettingError] = useState('');

  const [
    draftApplication,
    setDraftApplication,
  ] = useState<draftApplicationType>({
    authentication_id: '',
    authentication_saml_idp_metadata: [],
    atlassian_identifier: '',
  });
  const handlePreviousPage = () => {
    if (step - 1 <= 0) {
      return;
    }
    setStep(step - 1);
  };
  useEffect(() => {
    async function getDraftConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_ATLASSIAN_KEY,
      );
      setDraftApplication(DraftApplication?.success);
      if (
        DraftApplication?.success?.authentication_id
        && DraftApplication?.success?.identifier
        && DraftApplication?.success?.organization_domain
        && DraftApplication?.success?.attribute_key
      ) {
        navigate('/edit/casb/atlassian');
      }
    }
    getDraftConfiguration();
  }, [authSourceCompleted]);
  useEffect(() => {
    if (!draftApplication?.authentication_id) {
      setStep(1);
    } else if (!draftApplication?.atlassian_identifier) {
      setStep(2);
    }
  }, [draftApplication]);

  const updateAuthenticationSource = () => {
    setAuthenticationSourceError('');
    if (
      !AtlassianConfiguration
      || !AtlassianConfiguration.AuthenticationSource
      || !AtlassianConfiguration.AuthenticationSource
      || !AtlassianConfiguration.AuthenticationSource.authenticationName
      || !AtlassianConfiguration.AuthenticationSource.issuer
      || !AtlassianConfiguration.AuthenticationSource.loginURL
      || !AtlassianConfiguration.AuthenticationSource.bindingType
      || !AtlassianConfiguration.AuthenticationSource.X509Certificate
    ) {
      document.getElementsByClassName('authentication-source')[0].scrollTop = 0;
      setAuthenticationSourceError(EmptyFieldError);
    } else if (
      !isValidURL(AtlassianConfiguration.AuthenticationSource.issuer)
      || !isValidURL(AtlassianConfiguration.AuthenticationSource.loginURL)
      || (AtlassianConfiguration.AuthenticationSource.logoutURL
        && !isValidURL(AtlassianConfiguration.AuthenticationSource.logoutURL))
    ) {
      document.getElementsByClassName('authentication-source')[0].scrollTop = 0;
      setAuthenticationSourceError('Invalid URL.');
    } else {
      const cert = new KJUR.X509();
      if (
        isPEMCertificateValid(
          AtlassianConfiguration.AuthenticationSource.X509Certificate,
        )
      ) {
        try {
          cert.readCertPEM(
            AtlassianConfiguration.AuthenticationSource.X509Certificate,
          );
          const issuer = cert.getIssuerString();
          if (!issuer) {
            document.getElementsByClassName(
              'authentication-source',
            )[0].scrollTop = 0;
            setAuthenticationSourceError(
              'Certificate may be corrupt or in an incorrect format.',
            );
            return;
          }
        } catch (error) {
          document.getElementsByClassName(
            'authentication-source',
          )[0].scrollTop = 0;
          setAuthenticationSourceError(
            'Certificate may be corrupt or in an incorrect format.',
          );
          return;
        }
      } else {
        document.getElementsByClassName(
          'authentication-source',
        )[0].scrollTop = 0;
        setAuthenticationSourceError('Header and Footer Missing');
        return;
      }
      const url = !AtlassianConfiguration.AuthenticationSource._id
        ? `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-authentication-source`
        : `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-authentication-source`;
      axios
        .post(
          url,
          {
            data: AtlassianConfiguration.AuthenticationSource,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res: any) => {
          let updatedAuthenticationDetails;
          if (!AtlassianConfiguration?._id) {
            updatedAuthenticationDetails = {
              authenticationName:
                AtlassianConfiguration.AuthenticationSource.authenticationName,
              issuer: AtlassianConfiguration.AuthenticationSource.issuer,
              loginURL: AtlassianConfiguration.AuthenticationSource.loginURL,
              logoutURL: AtlassianConfiguration.AuthenticationSource.logoutURL,
              bindingType:
                AtlassianConfiguration.AuthenticationSource.bindingType,
              X509Certificate:
                AtlassianConfiguration.AuthenticationSource.X509Certificate,
              _id:
                res?.data?.authenticationDetails?._id
                || AtlassianConfiguration.AuthenticationSource._id,
            };
          } else {
            updatedAuthenticationDetails = AtlassianConfiguration.AuthenticationSource;
          }
          document.getElementsByClassName(
            'authentication-source',
          )[0].scrollTop = 0;
          axios
            .post(
              `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
              {
                authentication_id: updatedAuthenticationDetails._id,
                app: process.env.REACT_APP_ATLASSIAN_KEY,
              },
              {
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(() => {
              setAuthSourceCompleted(true);
              setStep(step + 1);
            });
        })
        .catch((error: any) => {
          document.getElementsByClassName(
            'authentication-source',
          )[0].scrollTop = 0;
          setAuthenticationSourceError(error.response.data.message);
        });
    }
  };

  const updateBasicSettings = () => {
    const BasicSettingConfiguration = AtlassianConfiguration.BasicSettings;
    if (
      !BasicSettingConfiguration
      || !BasicSettingConfiguration?.OrganizationName
      || !BasicSettingConfiguration?.OrganizationDomain
      || !BasicSettingConfiguration?.AtlassianACSUrl
      || !BasicSettingConfiguration?.AtlassianEntityUrl
      || !BasicSettingConfiguration?.attributeKey
      || !BasicSettingConfiguration?.nameAttributeKey
    ) {
      setBasicSettingsError(EmptyFieldError);
    } else if (
      !isValidDomain(BasicSettingConfiguration.OrganizationDomain)
      || !isValidURL(BasicSettingConfiguration.AtlassianACSUrl)
      || !isValidURL(BasicSettingConfiguration.AtlassianEntityUrl)
    ) {
      setBasicSettingsError('URLs provided are not valid.');
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
          {
            identifier: BasicSettingConfiguration.OrganizationName,
            organization_domain: BasicSettingConfiguration.OrganizationDomain,
            authentication_id: draftApplication?.authentication_id,
            acs_url: BasicSettingConfiguration?.AtlassianACSUrl,
            entity_id_url: BasicSettingConfiguration.AtlassianEntityUrl,
            attribute_key: BasicSettingConfiguration?.attributeKey,
            name_attribute_key: BasicSettingConfiguration?.nameAttributeKey,
            name_id_format: BasicSettingConfiguration?.nameIdFormat,
            casb_type: BasicSettingConfiguration?.CASBType,
            app: process.env.REACT_APP_ATLASSIAN_KEY,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          setBasicSettingsCompleted(true);
          setStep(step + 1);
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_UPDATE_PROXY_CONFIG_ERROR);
        });
    }
  };
  const updatePolicySettings = () => {
    var hasInvalidIP = false;
    const PolicySettingConfiguration = AtlassianConfiguration.PolicySettings;
    if (
      !PolicySettingConfiguration
      || !PolicySettingConfiguration.policyName
      || !PolicySettingConfiguration.policyDescription
    ) {
      setPolicySettingError(EmptyFieldError);
    } else if (PolicySettingConfiguration?.enableIPRestriction) {
      hasInvalidIP = PolicySettingConfiguration?.IPAddresses.some((ip: any) => {
        if (!ip || !isValidIPAddress(ip)) {
          toast.error('Invalid IP Address');
          return true;
        }
        return false;
      });
    }
    if (hasInvalidIP) {
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-proxy-policy`,
        {
          policy_details: PolicySettingConfiguration,
          type: 'save',
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        setPolicySettingsCompleted(true);
        setStep(step + 1);
        setPolicySettingsCompleted(true);
      })
      .catch((error) => {
        if (
          error.response
          && error.response.data
          && error.response.data.message
        ) {
          setPolicySettingError(error.response.data.message);
        }
      });
  };
  const updateGroupSetting = () => {
    const GroupSettingConfiguration = AtlassianConfiguration.GroupSettings;
    if (
      !GroupSettingConfiguration
      || !GroupSettingConfiguration.groupName
      || !GroupSettingConfiguration.groupDescription
      || !GroupSettingConfiguration.groupPolicy
    ) {
      setGroupSettingError(EmptyFieldError);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-group-settings`,
          {
            group_details: GroupSettingConfiguration,
            app: process.env.REACT_APP_ATLASSIAN_KEY,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          navigate('/edit/casb/atlassian');
        })
        .catch((error) => {
          setGroupSettingError(error?.response?.data.message);
        });
    }
  };
  const OpenAtlassianIDPMetadata = () => {
    setOpenIDPMetadata(true);
  };
  const OpenIDPMetadataURL = async () => {
    const user = await getUser();
    window.open(
      `${process.env.REACT_APP_NODE_BACKEND_URL}/saml/${user.customer_id}/${user.current_active_tenant_id}/metadata?app_name=${process.env.REACT_APP_ATLASSIAN_KEY}`,
      '_blank',
    );
  };
  return (
    <MainLayout>
      <Box display="flex" flexDirection="column" sx={{ height: '100%' }}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ height: '92% !important' }}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            height="15vh"
            borderBottom="1px solid #D9D9D9"
          >
            <ToastContainer />
            <Box
              width="40%"
              px={3}
              py={6}
              display="flex"
              alignItems="center"
              borderBottom="1px solid black"
            >
              <img
                src={AtlassianLogo}
                alt="Atlassian-logo"
                width={200}
                style={{
                  marginLeft: '4rem',
                }}
              />
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="right"
              marginRight="0rem"
              color="#2D3748"
              px={3}
              py={6}
            >
              <Box display="flex" alignItems="center">
                {authSourceCompleted || draftApplication?.authentication_id ? (
                  <CircularTick />
                ) : (
                  <CircularIcon number={1} step={step} />
                )}
                <Typography variant="body2" ml="1rem">
                  Authentication Source
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mx="1rem">
                <Divider sx={{ width: '1rem' }} />
              </Box>
              <Box display="flex" alignItems="center">
                {basicSettingsCompleted
                || draftApplication?.atlassian_identifier ? (
                  <CircularTick />
                  ) : (
                    <CircularIcon number={2} step={step} />
                  )}
                <Typography variant="body2" ml="1rem">
                  Basic Settings
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" mx="1rem">
                <Divider sx={{ width: '1rem' }} />
              </Box>

              <Box display="flex" alignItems="center">
                {policySettingsCompleted ? (
                  <CircularTick />
                ) : (
                  <CircularIcon number={3} step={step} />
                )}
                <Typography variant="body2" ml="1rem">
                  Manage Policy
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" mx="1rem">
                <Divider sx={{ width: '1rem' }} />
              </Box>

              <Box display="flex" alignItems="center">
                <CircularIcon number={4} step={step} />
                <Typography variant="body2" ml="1rem">
                  Manage Group
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" width="100%">
            <Box height="65vh !important">
              {(step === 1 && (
                <AtlassianAuthenticationSource
                  errorMessage={authenticationSourceError}
                />
              ))
                || (step === 2 && (
                  <BasicSettings
                    errorMessage={basicSettingsError}
                    setBasicSettingsError={setBasicSettingsError}
                  />
                ))
                || (step === 3 && (
                  <ManagePolicy errorMessage={policySettingError} />
                ))
                || (step === 4 && (
                  <ManageGroup errorMessage={groupSettingError} />
                ))}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" sx={{ marginBottom: 0 }}>
          <Divider />
          <Box
            display="flex"
            alignItems="flex-end"
            width="100%"
            p={2}
            justifyContent="right"
            sx={{ marginBottom: 'auto' }}
          >
            {step === 2 && (
              <Box display="flex" gap="2rem" mr="2rem">
                <Button
                  variant="text"
                  sx={{ fontSize: '15px', backgroundColor: 'lightblue', px: 3 }}
                  onClick={OpenIDPMetadataURL}
                >
                  Metadata URL
                </Button>
                <Button
                  variant="text"
                  sx={{ fontSize: '15px', backgroundColor: 'lightblue', px: 3 }}
                  onClick={OpenAtlassianIDPMetadata}
                >
                  View IDP Metadata
                </Button>
              </Box>
            )}
            {step === 3 && (
              <Button
                variant="text"
                onClick={() => {
                  setStep(step + 1);
                  setPolicySettingsCompleted(true);
                }}
              >
                Skip
              </Button>
            )}
            {step === 4 && (
              <Button
                variant="text"
                onClick={() => {
                  navigate('/edit/casb/atlassian');
                }}
              >
                Skip
              </Button>
            )}

            {step > 1 && step <= 3 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={handlePreviousPage}
              >
                Previous
              </Button>
            )}
            {step === 1 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={updateAuthenticationSource}
              >
                Save & Next
              </Button>
            )}
            {step === 2 && (
              <>
                <SAMLIDPMetadata
                  open={openIDPMetadata}
                  setOpen={setOpenIDPMetadata}
                  IDPMetadata={
                    draftApplication?.authentication_saml_idp_metadata
                  }
                  appName={process.env.REACT_APP_ATLASSIAN_KEY}
                />
                <Button
                  variant="text"
                  sx={{
                    color: 'white',
                    backgroundColor: '#2D3748',
                    marginRight: '2rem',
                    '&:hover': {
                      backgroundColor: '#2D3748',
                      color: 'white',
                    },
                  }}
                  onClick={updateBasicSettings}
                >
                  Save & Next
                </Button>
              </>
            )}
            {step === 3 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={updatePolicySettings}
              >
                Save & Next
              </Button>
            )}
            {step === 4 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={updateGroupSetting}
              >
                Save & Next
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default Atlassian;
