import React from 'react';
import { useDispatch } from 'react-redux';
import { addAuthSource } from '../../../store/features/CreateAuthSourceSlice';
import AuthenticationSourceCommon from '../../../components/CASBApp/AuthenticationSource';

interface AuthenticationSourceProps {
  errorMessage: string;
}

function AuthenticationSource({ errorMessage }: AuthenticationSourceProps) {
  const dispatch = useDispatch();
  return (
    <AuthenticationSourceCommon
      errorMessage={errorMessage}
      updateConfiguration={(config: any) => dispatch(addAuthSource(config))}
      appType="google"
    />
  );
}

export default AuthenticationSource;
