import React, { useState, useEffect } from 'react';
import * as KJUR from 'jsrsasign';
import { useSelector } from 'react-redux';
import {
  Box, Divider, Typography, Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import validator from 'validator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainLayout from '../../components/MainLayout';
import CircularIcon from '../../components/CircularIcon';
import GoogleLogo from '../../assets/images/google-logo.png';
import {
  getCookie,
  isValidURL,
  isPEMCertificateValid,
  getDraftProxyApplication,
  isValidIPAddress,
  getUser,
} from '../../utils/utility';
import AuthenticationSource from './Components/AuthenticationSource';
import BasicSettings from './Components/BasicSettings';
import SAMLIDPMetadata from '../../components/SAMLIDPMetadata';
import ManagePolicy from './Components/ManagePolicy';
import ManageGroup from './Components/ManageGroups';
import CircularTick from '../../components/CircularTick';

interface draftApplicationType {
  authentication_id: string;
  identifier: string;
  authentication_saml_idp_metadata: Object;
}

function GoogleConfiguration() {
  const navigate = useNavigate();
  const EmptyFieldError = 'Please fill all required fields.';
  const token = getCookie('token');
  const [step, setStep] = React.useState(1);
  const AuthenticationSourceDetails = useSelector((state: any) => state);
  const BasicSettingConfiguration = useSelector(
    (state: any) => state.BasicSettings,
  );
  const PolicySettingConfiguration = useSelector(
    (state: any) => state.PolicySettings,
  );
  const GroupSettingConfiguration = useSelector(
    (state: any) => state.GroupSettings,
  );
  const [authSourceCompleted, setAuthSourceCompleted] = useState(false);
  const [basicSettingsCompleted, setBasicSettingsCompleted] = useState(false);
  const [openIDPMetadata, setOpenIDPMetadata] = useState(false);
  const [authenticationSourceError, setAuthenticationSourceError] = useState(
    '',
  );
  const [basicSettingsError, setBasicSettingsError] = useState('');
  const [policySettingError, setPolicySettingError] = useState('');
  const [groupSettingError, setGroupSettingError] = useState('');
  const [policySettingsCompleted, setPolicySettingsCompleted] = useState(false);
  const [
    draftApplication,
    setDraftApplication,
  ] = useState<draftApplicationType>({
    authentication_id: '',
    authentication_saml_idp_metadata: [],
    identifier: '',
  });
  const handlePreviousPage = () => {
    if (step - 1 <= 0) {
      return;
    }
    setStep(step - 1);
  };
  useEffect(() => {
    async function getDraftedGoogleConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_GOOGLE_KEY,
      );
      setDraftApplication(DraftApplication?.success);
      if (
        DraftApplication?.success?.authentication_id
        && DraftApplication?.success?.identifier
      ) {
        navigate('/edit/casb/google');
      }
    }
    getDraftedGoogleConfiguration();
  }, [authSourceCompleted]);
  useEffect(() => {
    if (!draftApplication?.authentication_id) {
      setStep(1);
    } else if (!draftApplication?.identifier) {
      setStep(2);
    }
  }, [draftApplication]);

  const updateAuthenticationSource = () => {
    setAuthenticationSourceError('');
    if (
      !AuthenticationSourceDetails
      || !AuthenticationSourceDetails.AuthenticationSource
      || !AuthenticationSourceDetails.AuthenticationSource.data
      || !AuthenticationSourceDetails.AuthenticationSource.data
        .authenticationName
      || !AuthenticationSourceDetails.AuthenticationSource.data.issuer
      || !AuthenticationSourceDetails.AuthenticationSource.data.loginURL
      || !AuthenticationSourceDetails.AuthenticationSource.data.bindingType
      || !AuthenticationSourceDetails.AuthenticationSource.data.X509Certificate
    ) {
      document.getElementsByClassName('authentication-source')[0].scrollTop = 0;
      setAuthenticationSourceError(EmptyFieldError);
    } else if (
      !isValidURL(
        AuthenticationSourceDetails.AuthenticationSource.data.issuer,
      )
      || !isValidURL(
        AuthenticationSourceDetails.AuthenticationSource.data.loginURL,
      )
      || (AuthenticationSourceDetails.AuthenticationSource.data.logoutURL
        && !isValidURL(
          AuthenticationSourceDetails.AuthenticationSource.data.logoutURL,
        ))
    ) {
      document.getElementsByClassName('authentication-source')[0].scrollTop = 0;
      setAuthenticationSourceError('Invalid URL.');
    } else {
      const cert = new KJUR.X509();
      if (
        isPEMCertificateValid(
          AuthenticationSourceDetails.AuthenticationSource.data.X509Certificate,
        )
      ) {
        try {
          cert.readCertPEM(
            AuthenticationSourceDetails.AuthenticationSource.data
              .X509Certificate,
          );
          const issuer = cert.getIssuerString();
          if (!issuer) {
            document.getElementsByClassName(
              'authentication-source',
            )[0].scrollTop = 0;
            setAuthenticationSourceError(
              'Certificate may be corrupt or in an incorrect format.',
            );
            return;
          }
        } catch (error) {
          document.getElementsByClassName(
            'authentication-source',
          )[0].scrollTop = 0;
          setAuthenticationSourceError(
            'Certificate may be corrupt or in an incorrect format.',
          );
          return;
        }
      } else {
        document.getElementsByClassName(
          'authentication-source',
        )[0].scrollTop = 0;
        setAuthenticationSourceError('Header and Footer Missing');
        return;
      }
      const url = !AuthenticationSourceDetails.AuthenticationSource.data._id
        ? `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-authentication-source`
        : `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-authentication-source`;
      axios
        .post(
          url,
          {
            data: AuthenticationSourceDetails.AuthenticationSource.data,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res: any) => {
          let updatedAuthenticationDetails;
          if (!AuthenticationSourceDetails?.AuthenticationSource?.data._id) {
            updatedAuthenticationDetails = {
              authenticationName:
                AuthenticationSourceDetails.AuthenticationSource.data
                  .authenticationName,
              issuer:
                AuthenticationSourceDetails.AuthenticationSource.data.issuer,
              loginURL:
                AuthenticationSourceDetails.AuthenticationSource.data.loginURL,
              logoutURL:
                AuthenticationSourceDetails.AuthenticationSource?.data
                  ?.logoutURL,
              bindingType:
                AuthenticationSourceDetails.AuthenticationSource.data
                  .bindingType,
              X509Certificate:
                AuthenticationSourceDetails.AuthenticationSource.data
                  .X509Certificate,
              _id:
                res.data.authenticationDetails._id
                || AuthenticationSourceDetails.AuthenticationSource._id,
            };
          } else {
            updatedAuthenticationDetails = AuthenticationSourceDetails.AuthenticationSource.data;
          }
          document.getElementsByClassName(
            'authentication-source',
          )[0].scrollTop = 0;
          axios
            .post(
              `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
              {
                authentication_id: updatedAuthenticationDetails._id,
                app: process.env.REACT_APP_GOOGLE_KEY,
              },
              {
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(() => {
              setAuthSourceCompleted(true);
              setStep(step + 1);
            });
        })
        .catch((error: any) => {
          document.getElementsByClassName(
            'authentication-source',
          )[0].scrollTop = 0;
          setAuthenticationSourceError(error.response.data.message);
        });
    }
  };

  const updateBasicSettings = () => {
    if (
      !BasicSettingConfiguration
      || !BasicSettingConfiguration?.organizationName
      || !BasicSettingConfiguration?.organizationDomain
      || !BasicSettingConfiguration?.googleACSUrl
      || !BasicSettingConfiguration?.googleEntityUrl
    ) {
      setBasicSettingsError(EmptyFieldError);
    } else if (
      !validator.isURL(BasicSettingConfiguration.organizationDomain)
      || !isValidURL(BasicSettingConfiguration.googleACSUrl)
      || !isValidURL(BasicSettingConfiguration.googleEntityUrl)
    ) {
      setBasicSettingsError('URLs provided are not valid.');
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
          {
            identifier: BasicSettingConfiguration.organizationName,
            organization_domain: BasicSettingConfiguration.organizationDomain,
            authentication_id: draftApplication?.authentication_id,
            acs_url: BasicSettingConfiguration?.googleACSUrl,
            entity_id_url: BasicSettingConfiguration.googleEntityUrl,
            name_attribute_key: BasicSettingConfiguration.nameAttributeKey,
            attribute_key: BasicSettingConfiguration?.attributeKey,
            app: process.env.REACT_APP_GOOGLE_KEY,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          setBasicSettingsCompleted(true);
          setStep(step + 1);
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_UPDATE_PROXY_CONFIG_ERROR);
        });
    }
  };
  const updatePolicySettings = () => {
    var hasInvalidIP = false;
    if (
      !PolicySettingConfiguration
      || !PolicySettingConfiguration.policyName
      || !PolicySettingConfiguration.policyDescription
    ) {
      setPolicySettingError(EmptyFieldError);
    } else if (PolicySettingConfiguration?.enableIPRestriction) {
      hasInvalidIP = PolicySettingConfiguration?.IPAddresses.some((ip: any) => {
        if (!ip || !isValidIPAddress(ip)) {
          toast.error('Invalid IP Address');
          return true;
        }
        return false;
      });
    }
    if (hasInvalidIP) {
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-proxy-policy`,
        {
          policy_details: PolicySettingConfiguration,
          type: 'save',
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        setPolicySettingsCompleted(true);
        setStep(step + 1);
        setPolicySettingsCompleted(true);
      })
      .catch((error) => {
        if (
          error.response
          && error.response.data
          && error.response.data.message
        ) {
          setPolicySettingError(error.response.data.message);
        }
      });
  };
  const updateGroupSetting = () => {
    if (
      !GroupSettingConfiguration
      || !GroupSettingConfiguration.groupName
      || !GroupSettingConfiguration.groupDescription
      || !GroupSettingConfiguration.groupPolicy
      || !GroupSettingConfiguration.blockApps
      || !GroupSettingConfiguration.allowedApps
      || !GroupSettingConfiguration.restrictedApps
    ) {
      setGroupSettingError(EmptyFieldError);
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-group-settings`,
          {
            group_details: GroupSettingConfiguration,
            app: process.env.REACT_APP_GOOGLE_KEY,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          navigate('/edit/casb/google');
        })
        .catch((error) => {
          setGroupSettingError(error?.response?.data.message);
        });
    }
  };
  const OpenGoogleIDPMetadata = () => {
    setOpenIDPMetadata(true);
  };
  const OpenIDPMetadataURL = async () => {
    const user = await getUser();
    window.open(
      `${process.env.REACT_APP_NODE_BACKEND_URL}/saml/${user.customer_id}/${user.current_active_tenant_id}/metadata?app_name=${process.env.REACT_APP_GOOGLE_KEY}`,
      '_blank',
    );
  };
  return (
    <MainLayout>
      <Box display="flex" flexDirection="column" sx={{ height: '100%' }}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ height: '92% !important' }}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            height="15vh"
            borderBottom="1px solid #D9D9D9"
          >
            <ToastContainer />
            <Box
              width="40%"
              px={3}
              display="flex"
              alignItems="center"
              borderBottom="1px solid black"
            >
              <img
                src={GoogleLogo}
                alt="google-logo"
                width={40}
                style={{
                  marginLeft: '4rem',
                }}
              />
              <Typography variant="subtitle1" color="#777876" ml={3}>
                Google
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="right"
              marginRight="0rem"
              color="#2D3748"
              px={3}
            >
              <Box display="flex" alignItems="center">
                {authSourceCompleted || draftApplication?.authentication_id ? (
                  <CircularTick />
                ) : (
                  <CircularIcon number={1} step={step} />
                )}
                <Typography variant="body2" ml="1rem">
                  Authentication Source
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mx="1rem">
                <Divider sx={{ width: '1rem' }} />
              </Box>
              <Box display="flex" alignItems="center">
                {basicSettingsCompleted || draftApplication?.identifier ? (
                  <CircularTick />
                ) : (
                  <CircularIcon number={2} step={step} />
                )}
                <Typography variant="body2" ml="1rem">
                  Basic Settings
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mx="1rem">
                <Divider sx={{ width: '1rem' }} />
              </Box>
              <Box display="flex" alignItems="center">
                {policySettingsCompleted ? (
                  <CircularTick />
                ) : (
                  <CircularIcon number={3} step={step} />
                )}
                <Typography variant="body2" ml="1rem">
                  Manage Policy
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mx="1rem">
                <Divider sx={{ width: '1rem' }} />
              </Box>
              <Box display="flex" alignItems="center">
                <CircularIcon number={4} step={step} />
                <Typography variant="body2" ml="1rem">
                  Manage Group
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            {(step === 1 && (
              <AuthenticationSource errorMessage={authenticationSourceError} />
            ))
              || (step === 2 && (
                <BasicSettings
                  errorMessage={basicSettingsError}
                  setBasicSettingsError={setBasicSettingsError}
                />
              ))
              || (step === 3 && (
                <ManagePolicy errorMessage={policySettingError} />
              ))
              || (step === 4 && <ManageGroup errorMessage={groupSettingError} />)}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" sx={{ marginBottom: 0 }}>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            p={2}
            justifyContent="right"
            mt="auto"
          >
            {step === 2 && (
              <Box display="flex" gap="2rem" mr="2rem">
                <Button
                  variant="text"
                  sx={{ fontSize: '15px', backgroundColor: 'lightblue', px: 3 }}
                  onClick={OpenIDPMetadataURL}
                >
                  Metadata URL
                </Button>
                <Button
                  variant="text"
                  sx={{ fontSize: '15px', backgroundColor: 'lightblue', px: 3 }}
                  onClick={OpenGoogleIDPMetadata}
                >
                  View IDP Metadata
                </Button>
              </Box>
            )}
            {step === 3 && (
              <Button
                variant="text"
                onClick={() => {
                  setStep(step + 1);
                  setPolicySettingsCompleted(true);
                }}
              >
                Skip
              </Button>
            )}
            {step === 4 && (
              <Button
                variant="text"
                onClick={() => {
                  navigate('/edit/casb/google');
                }}
              >
                Skip
              </Button>
            )}
            {step > 1 && step <= 3 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={handlePreviousPage}
              >
                Previous
              </Button>
            )}
            {step === 1 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={updateAuthenticationSource}
              >
                Save & Next
              </Button>
            )}
            {step === 2 && (
              <>
                <SAMLIDPMetadata
                  open={openIDPMetadata}
                  setOpen={setOpenIDPMetadata}
                  IDPMetadata={
                    draftApplication?.authentication_saml_idp_metadata
                  }
                  appName={process.env.REACT_APP_GOOGLE_KEY}
                />
                <Button
                  variant="text"
                  sx={{
                    color: 'white',
                    backgroundColor: '#2D3748',
                    marginRight: '2rem',
                    '&:hover': {
                      backgroundColor: '#2D3748',
                      color: 'white',
                    },
                  }}
                  onClick={updateBasicSettings}
                >
                  Save & Next
                </Button>
              </>
            )}
            {step === 3 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={updatePolicySettings}
              >
                Save & Next
              </Button>
            )}
            {step === 4 && (
              <Button
                variant="text"
                sx={{
                  color: 'white',
                  backgroundColor: '#2D3748',
                  marginRight: '2rem',
                  '&:hover': {
                    backgroundColor: '#2D3748',
                    color: 'white',
                  },
                }}
                onClick={updateGroupSetting}
              >
                Save & Finish
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default GoogleConfiguration;
