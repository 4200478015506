import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Divider, Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { useSelector } from 'react-redux';
import * as KJUR from 'jsrsasign';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainLayout from '../../components/MainLayout';
import shopifyNonPlusLogo from '../../assets/images/shopify_nonplus.png';
import CircularIcon from '../../components/CircularIcon';
import CircularTick from '../../components/CircularTick';
import ShopifyNonPlusAuthenticationSource from './components/ShopifyNPAuthenticationSource';
import BasicSettings from './components/BasicSettings';
import ManagePolicy from './components/ManagePolicy';
import ManageGroup from './components/ManageGroup';
import {
  isValidURL,
  isPEMCertificateValid,
  getCookie,
  getDraftProxyApplication,
  isValidIPAddress,
  testSAMLConnection,
} from '../../utils/utility';

interface completedStateProps {
  authentication_source: boolean;
  basic_settings: boolean;
  policy_settings: boolean;
  group_settings: boolean;
}

function ShopifyNonPlus() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [draftApplication, setDraftApplication] = useState<any>([]);
  const [authenticationSourceError, setAuthenticationSourceError] = useState(
    '',
  );
  const [completedState, setCompletedState] = useState<completedStateProps>({
    authentication_source: false,
    basic_settings: false,
    policy_settings: false,
    group_settings: false,
  });
  const [basicSettingsError, setBasicSettingsError] = useState('');
  const [policySettingError, setPolicySettingError] = useState('');
  const [, setGroupSettingError] = useState('');
  const shopifyNonPlusConfig = useSelector(
    (state: any) => state.ShopifyNonPlusConfiguration,
  );
  const [authenticationID, setAuthenticationID] = useState<string>('');
  useEffect(() => {
    async function getDraftConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
      );
      setDraftApplication(DraftApplication?.success);
      if (
        DraftApplication?.success?.authentication_id
        && DraftApplication?.success?.identifier
        && DraftApplication?.success?.organization_domain
        && DraftApplication?.success?.attribute_key
      ) {
        navigate('/edit/casb/shopify');
      } else if (DraftApplication?.success?.authentication_id) {
        setAuthenticationID(DraftApplication?.success?.authentication_id);
        setCompletedState((prevState) => ({
          ...prevState,
          authentication_source: true,
        }));
        setStep(step + 1);
      }
    }
    getDraftConfiguration();
  }, []);
  const updateAuthenticationSource = async () => {
    if (
      !shopifyNonPlusConfig
      || !shopifyNonPlusConfig.AuthenticationSource
      || !shopifyNonPlusConfig.AuthenticationSource.authenticationName
      || !shopifyNonPlusConfig.AuthenticationSource.issuer
      || !shopifyNonPlusConfig.AuthenticationSource.loginURL
      || !shopifyNonPlusConfig.AuthenticationSource.bindingType
      || !shopifyNonPlusConfig.AuthenticationSource.X509Certificate
    ) {
      document.getElementsByClassName('authentication-source')[0].scrollTop = 0;
      setAuthenticationSourceError('Please fill all required fields.');
    } else if (
      !isValidURL(shopifyNonPlusConfig.AuthenticationSource.issuer)
      || !isValidURL(shopifyNonPlusConfig.AuthenticationSource.loginURL)
      || (shopifyNonPlusConfig.AuthenticationSource.logoutURL
        && !isValidURL(shopifyNonPlusConfig.AuthenticationSource.logoutURL))
    ) {
      document.getElementsByClassName('authentication-source')[0].scrollTop = 0;
      setAuthenticationSourceError('Invalid URL.');
    } else {
      const cert = new KJUR.X509();
      if (
        isPEMCertificateValid(
          shopifyNonPlusConfig.AuthenticationSource.X509Certificate,
        )
      ) {
        try {
          cert.readCertPEM(
            shopifyNonPlusConfig.AuthenticationSource.X509Certificate,
          );
          const issuer = cert.getIssuerString();
          if (!issuer) {
            document.getElementsByClassName(
              'authentication-source',
            )[0].scrollTop = 0;
            setAuthenticationSourceError(
              'Certificate may be corrupt or in an incorrect format.',
            );
            return;
          }
        } catch (error) {
          document.getElementsByClassName(
            'authentication-source',
          )[0].scrollTop = 0;
          setAuthenticationSourceError(
            'Certificate may be corrupt or in an incorrect format.',
          );
          return;
        }
      } else {
        document.getElementsByClassName(
          'authentication-source',
        )[0].scrollTop = 0;
        setAuthenticationSourceError('Header and Footer Missing');
        return;
      }
      const token = await getCookie('token');
      const url = !shopifyNonPlusConfig.AuthenticationSource._id
        ? `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-authentication-source`
        : `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-authentication-source`;
      axios
        .post(
          url,
          {
            data: shopifyNonPlusConfig.AuthenticationSource,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res: any) => {
          let updatedAuthenticationDetails: any;
          if (!shopifyNonPlusConfig?.AuthenticationSource?._id) {
            updatedAuthenticationDetails = {
              authenticationName:
                shopifyNonPlusConfig?.AuthenticationSource?.authenticationName,
              issuer: shopifyNonPlusConfig?.AuthenticationSource?.issuer,
              loginURL: shopifyNonPlusConfig?.AuthenticationSource?.loginURL,
              logoutURL: shopifyNonPlusConfig?.AuthenticationSource?.logoutURL,
              bindingType:
                shopifyNonPlusConfig?.AuthenticationSource?.bindingType,
              X509Certificate:
                shopifyNonPlusConfig?.AuthenticationSource?.X509Certificate,
              _id:
                res?.data?.authenticationDetails._id
                || shopifyNonPlusConfig?.AuthenticationSource?._id,
            };
          } else {
            updatedAuthenticationDetails = shopifyNonPlusConfig.AuthenticationSource;
          }
          document.getElementsByClassName(
            'authentication-source',
          )[0].scrollTop = 0;
          axios
            .post(
              `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
              {
                authentication_id: updatedAuthenticationDetails._id,
                app: process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
              },
              {
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(() => {
              setAuthenticationID(updatedAuthenticationDetails?._id);
              setCompletedState((prevState) => ({
                ...prevState,
                authentication_source: true,
              }));
              toast.success('Authentication source successfully configured!');
            });
        })
        .catch((error: any) => {
          document.getElementsByClassName(
            'authentication-source',
          )[0].scrollTop = 0;
          setAuthenticationSourceError(error?.response?.data?.message);
        });
    }
  };
  const updateBasicSettings = async () => {
    const token = await getCookie('token');
    if (
      !shopifyNonPlusConfig.BasicSettings
      || !shopifyNonPlusConfig.BasicSettings?.identifier
      || !shopifyNonPlusConfig.BasicSettings?.organization_domain
      || !shopifyNonPlusConfig.BasicSettings?.attribute_key
    ) {
      setBasicSettingsError('Please fill all required fields.');
    } else if (
      !validator.isURL(shopifyNonPlusConfig.BasicSettings.organization_domain)
    ) {
      setBasicSettingsError('URLs provided are not valid.');
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
          {
            identifier: shopifyNonPlusConfig.BasicSettings.identifier,
            organization_domain:
              shopifyNonPlusConfig.BasicSettings.organization_domain,
            attribute_key: shopifyNonPlusConfig.BasicSettings?.attribute_key,
            type: shopifyNonPlusConfig.BasicSettings?.type,
            casb: shopifyNonPlusConfig.BasicSettings?.enableCASB,
            app: process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          setCompletedState((prevState) => ({
            ...prevState,
            basic_settings: true,
          }));
          setStep(step + 1);
        });
    }
  };
  const updatePolicySettings = async () => {
    var hasInvalidIP = false;
    const token = await getCookie('token');
    if (
      !shopifyNonPlusConfig.PolicySettings
      || !shopifyNonPlusConfig.PolicySettings.policyName
      || !shopifyNonPlusConfig.PolicySettings.policyDescription
    ) {
      setPolicySettingError('Please fill all required fields.');
    } else if (shopifyNonPlusConfig?.PolicySettings?.enableIPRestriction) {
      hasInvalidIP = shopifyNonPlusConfig?.PolicySettings?.IPAddresses.some(
        (ip: any) => {
          if (!ip || !isValidIPAddress(ip)) {
            toast.error('Invalid IP Address');
            return true;
          }
          return false;
        },
      );
    }
    if (hasInvalidIP) {
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-proxy-policy`,
        {
          policy_details: shopifyNonPlusConfig.PolicySettings,
          type: 'save',
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        setCompletedState((prevState) => ({
          ...prevState,
          policy_settings: true,
        }));
        setStep(step + 1);
      })
      .catch((error) => {
        if (
          error.response
          && error.response.data
          && error.response.data.message
        ) {
          setPolicySettingError(error.response.data.message);
        }
      });
  };
  const previousStep = async () => {
    if (step - 1 < 0) {
      return;
    }
    setStep(step - 1);
  };
  const updateGroupSettings = async () => {
    const token = await getCookie('token');
    const isEmptyEntry = shopifyNonPlusConfig.UserGroupSettings.userdetails.some(
      (entry: any) => Object.values(entry).some((value) => value === ''),
    );
    if (isEmptyEntry) {
      toast.error(process.env.REACT_APP_EMPTY_INPUT_FIELDS);
      return;
    }
    const hasDuplicateUsernames = shopifyNonPlusConfig.UserGroupSettings.userdetails.some(
      (userObject: any, index: number, array: any) => array
        .slice(0, index)
        .some((prevUser: any) => prevUser.username === userObject.username),
    );

    const hasDuplicateGroup = shopifyNonPlusConfig.UserGroupSettings.userdetails.some(
      (user: any, index: any) => shopifyNonPlusConfig.UserGroupSettings.userdetails
        .slice(index + 1)
        .some((otherUser: any) => user.group_name === otherUser.group_name),
    );

    if (hasDuplicateGroup) {
      toast.error(process.env.REACT_APP_DUPLICATE_GROUPS);
      return;
    }
    if (hasDuplicateUsernames && !draftApplication?.features?.multi_staff) {
      toast.error(process.env.REACT_APP_DUPLICATE_USERNAME);
      return;
    }
    if (
      !shopifyNonPlusConfig.UserGroupSettings
      || !shopifyNonPlusConfig.UserGroupSettings.type
    ) {
      setGroupSettingError('Please fill all required fields.');
    } else {
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
          {
            user_details: shopifyNonPlusConfig.UserGroupSettings.userdetails,
            app: process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then(() => {
          setCompletedState((prevState) => ({
            ...prevState,
            group_settings: true,
          }));
          navigate('/edit/casb/shopify');
        })
        .catch((error) => {
          setGroupSettingError(error?.response?.data.message);
        });
    }
  };
  return (
    <MainLayout>
      <Box display="flex" flexDirection="column" sx={{ height: '100%' }}>
        <ToastContainer />
        <Box
          display="flex"
          flexDirection="column"
          sx={{ height: '92% !important' }}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            height="15vh"
            borderBottom="1px solid #D9D9D9"
          >
            <Box
              width="40%"
              px={3}
              py={6}
              display="flex"
              alignItems="center"
              borderBottom="1px solid black"
            >
              <img
                src={shopifyNonPlusLogo}
                alt="shopifyNonPlus-logo"
                height={40}
                style={{
                  marginLeft: '4rem',
                }}
              />
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="right"
              marginRight="0rem"
              color="#2D3748"
              px={3}
              py={6}
            >
              <Box display="flex" alignItems="center">
                {completedState.authentication_source
                || draftApplication?.authentication_id ? (
                  <CircularTick />
                  ) : (
                    <CircularIcon number={1} step={step} />
                  )}
                <Typography variant="body2" ml="1rem">
                  Authentication Source
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mx="1rem">
                <Divider sx={{ width: '1rem' }} />
              </Box>
              <Box display="flex" alignItems="center">
                {completedState.basic_settings
                || (draftApplication?.identifier
                  && draftApplication?.organization_domain
                  && draftApplication?.attribute_key) ? (
                    <CircularTick />
                  ) : (
                    <CircularIcon number={2} step={step} />
                  )}
                <Typography variant="body2" ml="1rem">
                  Basic Settings
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mx="1rem">
                <Divider sx={{ width: '1rem' }} />
              </Box>
              <Box display="flex" alignItems="center">
                {completedState.policy_settings ? (
                  <CircularTick />
                ) : (
                  <CircularIcon number={3} step={step} />
                )}
                <Typography variant="body2" ml="1rem">
                  Manage Policy
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mx="1rem">
                <Divider sx={{ width: '1rem' }} />
              </Box>
              <Box display="flex" alignItems="center">
                <CircularIcon number={4} step={step} />
                <Typography variant="body2" ml="1rem">
                  Staff Users Onboarding & Groups
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" width="100%">
            {(step === 1 && (
              <ShopifyNonPlusAuthenticationSource
                errorMessage={authenticationSourceError}
              />
            ))
              || (step === 2 && (
                <BasicSettings
                  errorMessage={basicSettingsError}
                  setError={setBasicSettingsError}
                />
              ))
              || (step === 3 && (
                <ManagePolicy errorMessage={policySettingError} />
              ))
              || (step === 4 && <ManageGroup />)}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" sx={{ marginBottom: 0 }}>
          <Divider />
          <Box
            display="flex"
            alignItems="flex-end"
            width="100%"
            p={2}
            justifyContent="right"
            sx={{ marginBottom: 'auto' }}
          >
            {step === 1 && (
              <>
                <Button
                  variant="text"
                  sx={{
                    color: 'white',
                    backgroundColor: '#2D3748',
                    marginRight: '2rem',
                    '&:hover': {
                      backgroundColor: '#2D3748',
                      color: 'white',
                    },
                  }}
                  onClick={updateAuthenticationSource}
                >
                  Save
                </Button>
                <Button
                  variant="text"
                  sx={{
                    color: 'white',
                    backgroundColor: '#2D3748',
                    marginRight: '2rem',
                    '&:hover': {
                      backgroundColor: '#2D3748',
                      color: 'white',
                    },
                    '&:disabled': {
                      color: 'darkgray',
                      backgroundColor: 'lightgray',
                    },
                  }}
                  onClick={() => testSAMLConnection(
                    authenticationID,
                    String(process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY),
                  )}
                  disabled={!completedState.authentication_source}
                >
                  Test SAML Connection
                </Button>
                <Button
                  sx={{
                    color: 'white',
                    backgroundColor: '#2D3748',
                    marginRight: '2rem',
                    '&:hover': {
                      backgroundColor: '#2D3748',
                      color: 'white',
                    },
                    '&:disabled': {
                      color: 'darkgray',
                      backgroundColor: 'lightgray',
                    },
                  }}
                  onClick={() => setStep(step + 1)}
                  disabled={!completedState.authentication_source}
                >
                  Next
                </Button>
              </>
            )}
            {step === 2 && (
              <>
                <Button
                  variant="text"
                  sx={{
                    color: 'white',
                    backgroundColor: '#2D3748',
                    marginRight: '2rem',
                    '&:hover': {
                      backgroundColor: '#2D3748',
                      color: 'white',
                    },
                  }}
                  onClick={previousStep}
                >
                  Previous
                </Button>
                <Button
                  variant="text"
                  sx={{
                    color: 'white',
                    backgroundColor: '#2D3748',
                    marginRight: '2rem',
                    '&:hover': {
                      backgroundColor: '#2D3748',
                      color: 'white',
                    },
                    '&:disabled': {
                      color: 'darkgray',
                      backgroundColor: 'lightgray',
                    },
                  }}
                  onClick={() => testSAMLConnection(
                    authenticationID,
                    String(process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY),
                  )}
                  disabled={!completedState.authentication_source}
                >
                  Test SAML Connection
                </Button>
                <Button
                  variant="text"
                  sx={{
                    color: 'white',
                    backgroundColor: '#2D3748',
                    marginRight: '2rem',
                    '&:hover': {
                      backgroundColor: '#2D3748',
                      color: 'white',
                    },
                  }}
                  onClick={updateBasicSettings}
                >
                  Save & Next
                </Button>
              </>
            )}
            {step === 3 && (
              <>
                <Button
                  sx={{ marginRight: '2rem' }}
                  onClick={() => {
                    setStep(step + 1);
                    setCompletedState((prevState) => ({
                      ...prevState,
                      policy_settings: true,
                    }));
                  }}
                >
                  Skip
                </Button>
                <Button
                  variant="text"
                  sx={{
                    color: 'white',
                    backgroundColor: '#2D3748',
                    marginRight: '2rem',
                    '&:hover': {
                      backgroundColor: '#2D3748',
                      color: 'white',
                    },
                  }}
                  onClick={updatePolicySettings}
                >
                  Save & Next
                </Button>
              </>
            )}
            {step === 4 && (
              <>
                <Button
                  sx={{ marginRight: '2rem' }}
                  onClick={() => navigate('/edit/casb/shopify')}
                >
                  Skip
                </Button>
                <Button
                  variant="text"
                  sx={{
                    color: 'white',
                    backgroundColor: '#2D3748',
                    marginRight: '2rem',
                    '&:hover': {
                      backgroundColor: '#2D3748',
                      color: 'white',
                    },
                  }}
                  onClick={updateGroupSettings}
                >
                  Save & Next
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default ShopifyNonPlus;
