import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';
import { getCookie } from '../utils/utility';

function ProtectedRouteForLogin({ children }: any) {
  const [redirectToDashboard, setRedirectToDashboard] = useState<any>(null);
  const token = getCookie('token');
  useEffect(() => {
    const checkAuth = async () => {
      axios
        .post(`${process.env.REACT_APP_NODE_BACKEND_URL}/api/verify-user-session`, {
          token,
        })
        .then(() => {
          setRedirectToDashboard(true);
        })
        .catch(() => {
          setRedirectToDashboard(false);
          document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        });
    };
    checkAuth();
  }, []);

  if (redirectToDashboard) {
    return <Navigate to="/dashboard" />;
  }
  if (redirectToDashboard === false) {
    return <Outlet />;
  }
  return children;
}

export default ProtectedRouteForLogin;
