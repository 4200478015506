import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Box, Typography, TextField, FormControl, Switch,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  isValidDomain,
  isValidURL,
  getDraftProxyApplication,
} from '../../../utils/utility';
import ErrorMessage from '../../../components/ErrorMessage';
import { updateOffice365Configuration } from '../../../store/features/CreateAuthSourceSlice';

interface BasicSettingsType {
  identifier: null | string;
  organization_domain: null | string;
  attribute_key: null | string;
  name_attribute_key: null | string;
  acs_url: null | string;
  entity_id_url: null | string;
  CASBType: null | string;
  mdm: boolean;
}

interface ShopifyPlusBasicSettingsProps {
  errorMessage: string;
  setBasicSettingsError: any;
}

function BasicSettings({
  errorMessage,
  setBasicSettingsError,
}: ShopifyPlusBasicSettingsProps) {
  const dispatch = useDispatch();
  const InputFields = [
    'Organization Name*',
    'Organization Domain*',
    'Group Attribute Key*',
    'Name Attribute Key',
    'Office365 ACS URL*',
    'Office365 Entity URL*',
  ];
  const InputFieldsName = [
    'identifier',
    'organization_domain',
    'attribute_key',
    'name_attribute_key',
    'acs_url',
    'entity_id_url',
  ];
  const [basicSettings, setBasicSettings] = useState<BasicSettingsType>({
    identifier: null,
    organization_domain: null,
    attribute_key: null,
    acs_url: 'https://login.microsoftonline.com/login.srf',
    name_attribute_key: null,
    entity_id_url: 'urn:federation:MicrosoftOnline',
    CASBType: 'offline',
    mdm: false,
  });
  const [inputErrorMessage, setInputErrorMessage] = useState<BasicSettingsType>(
    {
      identifier: null,
      organization_domain: null,
      attribute_key: null,
      name_attribute_key: null,
      acs_url: null,
      entity_id_url: null,
      CASBType: 'offline',
      mdm: false,
    },
  );
  const handleBasicSettingsInput = (event: any) => {
    var value = '';
    event.preventDefault();
    setBasicSettingsError('');
    const { name } = event.target;
    value = event.target.value;
    value = value?.trim();
    switch (name) {
      case 'organization_domain':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          organization_domain: !isValidDomain(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      case 'acs_url':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          acs_url: !isValidURL(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      default:
        break;
    }
    setBasicSettings((prevValue: BasicSettingsType) => ({
      ...prevValue,
      [name]: value,
    }));
  };
  useEffect(() => {
    async function getDraftedShopifyPlusConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_OFFICE365_KEY,
      );
      setBasicSettings({
        identifier: DraftApplication?.success?.identifier,
        organization_domain: DraftApplication?.success?.organization_domain,
        attribute_key: DraftApplication?.success?.attribute_key,
        name_attribute_key: DraftApplication?.success?.name_attribute_key,
        acs_url: DraftApplication?.success?.acs_url,
        entity_id_url: DraftApplication?.success?.entity_id_url,
        CASBType: DraftApplication?.success?.casb_type,
        mdm: DraftApplication?.success?.mdm,
      });
    }
    getDraftedShopifyPlusConfiguration();
  }, []);
  useEffect(() => {
    function updateBasicSettingsDetails() {
      dispatch(
        updateOffice365Configuration({
          type: 'basic-settings',
          data: basicSettings,
        }),
      );
    }
    updateBasicSettingsDetails();
  }, [basicSettings]);
  const handleEnableCASBChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBasicSettings((prevSettings) => ({
      ...prevSettings,
      mdm: event.target.checked,
    }));
  };
  return (
    <Box height="67vh" sx={{ overflow: 'scroll' }}>
      <Box display="flex" flexDirection="row">
        <Box width="40%" display="flex" py={6} px={6} flexDirection="column">
          <Typography variant="h5">Basic Settings</Typography>
          <br />
          <Typography variant="subtitle2" color="#56526B">
            Add new Basic Settings for Shopify Plus Proxy
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="60%"
          px={6}
          py={5}
          gap="1.5rem"
        >
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {InputFields.map((value, index) => (
            <FormControl>
              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                label={value}
                sx={{
                  backgroundColor:
                    inputErrorMessage[
                      InputFieldsName[index] as keyof BasicSettingsType
                    ] === null
                      ? '#F6F6F6'
                      : '#ff8d0024',
                  border: 'none !important',
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                name={InputFieldsName[index]}
                value={
                  basicSettings[
                    InputFieldsName[index] as keyof BasicSettingsType
                  ] || ''
                }
                onChange={handleBasicSettingsInput}
              />
              <Typography variant="caption" color="red">
                {
                  inputErrorMessage[
                    InputFieldsName[index] as keyof BasicSettingsType
                  ]
                }
              </Typography>
            </FormControl>
          ))}
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="CASBType"
            value="offline"
            onChange={handleBasicSettingsInput}
          >
            <FormControlLabel
              value="inline"
              control={<Radio />}
              label="Inline"
              sx={{ fontSize: '13px' }}
            />
            <FormControlLabel
              value="offline"
              control={<Radio />}
              label="Offline"
              sx={{ fontSize: '15px' }}
            />
          </RadioGroup>
          <Box display="flex" alignItems="center" gap="10rem">
            <Typography variant="subtitle2">Enable MDM</Typography>
            <Switch
              checked={basicSettings.mdm}
              onChange={handleEnableCASBChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BasicSettings;
