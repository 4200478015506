import React, { useState } from 'react';
import {
  Box, Typography, Switch, TextField, Button,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

interface CORSProps {
  proxyInputDetails: any;
  setProxyInputDetails: Function;
}

function CORS({ proxyInputDetails, setProxyInputDetails }: CORSProps) {
  const [domain, setDomain] = useState(
    proxyInputDetails?.CORS?.DomainList || [],
  );
  const addFormFields = () => {
    setDomain([...domain, '']);
  };
  const removeFields = (index: number) => {
    const data = [...domain];
    data.splice(index, 1);
    setDomain(data);
  };
  const handleChange = (index: number, event: any) => {
    const data = [...domain];
    data[index] = event.target.value;
    setDomain(data);
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      CORS: {
        ...prevValue.CORS,
        DomainList: data,
      },
    }));
  };
  const handleCORSDomainToggle = (event: any) => {
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      CORS: {
        ...prevValue.CORS,
        enable: event.target.checked,
      },
    }));
  };
  return (
    <Box
      width="90%"
      display="flex"
      flexDirection="column"
      sx={{ gap: '0.5rem' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">CORS</Typography>
          <Switch
            onChange={handleCORSDomainToggle}
            checked={proxyInputDetails?.CORS?.enable}
          />
        </Box>
        <Typography variant="caption">
          CORS feature is used to enable access to specific domains that are
          submitted by a user to make asynchronous requests to the server
          address.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="0.75rem">
        <Box>
          <Button onClick={addFormFields}>+ Add Domain</Button>
        </Box>
        <Box display="flex" flexDirection="column">
          {domain.map((element: any, index: number) => (
            <Box display="flex" sx={{ gap: '0.5rem', padding: '0.25rem' }}>
              <TextField
                id="outlined-basic"
                size="small"
                label="Eg.(<domain>.com)"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: 400,
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                value={element || ''}
                onChange={(event) => handleChange(index, event)}
              />
              <RemoveCircleIcon
                fontSize="large"
                sx={{ color: '#E34614', marginLeft: '2rem' }}
                onClick={() => removeFields(index)}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default CORS;
