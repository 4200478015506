import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import { toast } from 'react-toastify';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, Link } from 'react-router-dom';
import { getCookie, DomainDetails } from '../../../utils/utility';
import MainLayout from '../../../components/MainLayout';

const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function ProxyTable() {
  const [proxies, setProxies] = useState([
    {
      identifier: '',
      path: '',
      server_url: '',
    },
  ]);
  const [reloadProxy, setReloadProxy] = useState(false);
  const navigate = useNavigate();
  const [proxyURL, setProxyURL] = useState(null);
  const handleAddProxy = () => {
    navigate('/add/proxy');
  };
  useEffect(() => {
    const token = getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-proxies`,
        {},
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((res) => {
        if (res?.data?.proxies) {
          setProxies(res.data.proxies);
        }
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_GET_ALL_PROXIES_ERROR);
      });
  }, [reloadProxy]);
  useEffect(() => {
    async function getDomainDetails() {
      const domainDetails: any = await DomainDetails();
      setProxyURL(domainDetails?.url);
    }
    getDomainDetails();
  }, []);
  const handleEditProxyDetails = (identifier: string, path: string) => {
    navigate(`/edit/proxy?identifier=${identifier}&path=${path}`);
  };
  const handleDeleteProxyDetails = async (identifier: string) => {
    setReloadProxy(false);
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/delete-proxy`,
        {
          identifier,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        setReloadProxy(true);
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_DELETE_PROXY_ERROR);
      });
  };
  return (
    <MainLayout>
      <Box width="100%">
        <Box
          display="flex"
          width="95%"
          p={2}
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid #D9D9D9"
        >
          <Typography variant="h6">Proxy</Typography>
          <Button variant="text" onClick={handleAddProxy}>
            + Add new Proxy
          </Button>
        </Box>
        <Box>
          <TableContainer component={Paper} sx={{ padding: '2rem' }}>
            <Table sx={{ width: '95%' }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Identifier</StyledTableCell>
                  <StyledTableCell align="center">Path</StyledTableCell>
                  <StyledTableCell align="center">Server URL</StyledTableCell>
                  <StyledTableCell align="right"> Edit</StyledTableCell>
                  <StyledTableCell align="right"> Delete</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {proxies?.map((row) => (
                  <StyledTableRow key={row.identifier}>
                    <StyledTableCell component="th" scope="row">
                      {row.identifier}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link to={proxyURL + row.path} target="_blank">
                        {row.path}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.server_url}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      onClick={() => handleEditProxyDetails(row.identifier, row.path)}
                      key={row.identifier}
                    >
                      <EditIcon sx={{ color: 'blue' }} />
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      onClick={() => handleDeleteProxyDetails(row.identifier)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <DeleteIcon sx={{ color: 'red' }} />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                {proxies.length === 0 && (
                  <StyledTableRow>
                    <StyledTableCell align="center" />
                    <StyledTableCell align="center" />
                    <StyledTableCell align="center">
                      No Configurations
                    </StyledTableCell>
                    <StyledTableCell align="center" />
                    <StyledTableCell align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default ProxyTable;
