import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { getCookie } from '../utils/utility';

interface ImportIDPMetadataProps {
  open: boolean;
  setOpen: Function;
  authenticationSource: any;
  setAuthenticationSource: Function;
}

interface FormatSelectChangeEvent {
  target: {
    value: string;
  };
}

function ImportIDPMetadata({
  open,
  setOpen,
  authenticationSource,
  setAuthenticationSource,
}: ImportIDPMetadataProps) {
  const [format, setFormat] = useState('URL');
  const [url, setURL] = useState('');
  const [xml, setXml] = useState('');
  const handleAuthenticationSource = (event: any) => {
    const { name, value } = event.target;
    setAuthenticationSource((prevValue: any) => ({
      ...prevValue,
      [name]: value,
    }));
  };
  const closeDialog = () => {
    setOpen(!open);
  };
  const importMetadata = async () => {
    const token = await getCookie('token');
    if (format === 'URL' && !url) {
      toast.error('Empty URL Field');
      return;
    }
    if (format === 'Text' && !xml) {
      toast.error('Empty Text Field.');
      return;
    }
    if (format === 'File' && !xml) {
      toast.error('Please select file.');
      return;
    }
    const toastMessage = toast.loading('Uploading Metadata ...');
    axios
      .post(`${process.env.REACT_APP_NODE_BACKEND_URL}/api/idp-xml-data`, {
        url,
        format,
        token,
        xml,
      })
      .then((response) => {
        const idpMetadata = response?.data?.idp_metadata;
        setXml('');
        setURL('');
        setAuthenticationSource((prevValue: any) => ({
          ...prevValue,
          issuer: idpMetadata?.entityID,
          loginURL: idpMetadata?.loginURL,
          logoutURL: idpMetadata?.logoutURL,
          X509Certificate: idpMetadata?.certificate,
        }));
        toast.update(toastMessage, {
          render: 'Metadata Uploaded',
          type: 'success',
          autoClose: 1000,
          closeButton: true,
          isLoading: false,
        });
        setOpen(!open);
      })
      .catch((error) => {
        toast.update(toastMessage, {
          render: 'Error fetching IDP metadata',
          type: 'error',
          autoClose: 1000,
          closeButton: true,
          isLoading: false,
        });
        console.error('Error fetching IDP metadata:', error);
      });
  };
  const handleFormatChange = (event: FormatSelectChangeEvent) => {
    setFormat(event.target.value);
  };
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e?.target?.result as string;
      if (content) {
        setXml(content);
      }
    };

    reader.readAsText(file);
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Import IDP Metadata</DialogTitle>
      <DialogContent>
        <InputLabel size="small" sx={{ mb: 1 }}>
          App Name
        </InputLabel>
        <ToastContainer />
        <TextField
          fullWidth
          margin="dense"
          size="small"
          sx={{ mb: 1.5 }}
          value={authenticationSource?.authenticationName || ''}
          name="authenticationName"
          onChange={handleAuthenticationSource}
          disabled={window.location.href.includes('/edit')}
        />
        <FormControl fullWidth margin="dense" size="small">
          <Select
            size="small"
            sx={{ mb: 1.5 }}
            value={format}
            onChange={handleFormatChange}
          >
            <MenuItem value="URL">URL</MenuItem>
            <MenuItem value="Text">Text</MenuItem>
            <MenuItem value="File">File</MenuItem>
          </Select>
        </FormControl>
        {format === 'URL' ? (
          <TextField
            fullWidth
            size="small"
            value={url}
            onChange={(event) => setURL(event.target.value)}
          />
        ) : format === 'Text' ? (
          <TextField
            fullWidth
            multiline
            rows={10}
            maxRows={10}
            value={xml}
            onChange={(event) => setXml(event.target.value)}
          />
        ) : format === 'File' ? (
          <TextField
            fullWidth
            type="file"
            size="small"
            InputProps={{ inputProps: { accept: '.xml' } }}
            onChange={handleFileUpload}
          />
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions sx={{ pb: 1.5 }}>
        <Button color="primary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button color="primary" onClick={importMetadata}>
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ImportIDPMetadata;
