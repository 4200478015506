import React, { useEffect, useState } from 'react';
import {
  Box, Button, Divider, TextField, Paper,
} from '@mui/material';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MainLayout from '../../components/MainLayout';
import { getCookie } from '../../utils/utility';
import AddPolicyModal from '../../components/AddPolicyModal';

const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  name: string,
  _id: string,
  description: string,
  enableIPRestriction: boolean,
  enableTimeRestriction: boolean,
  enableFileRestriction: boolean,
  status: boolean,
) {
  return {
    name,
    _id,
    description,
    enableIPRestriction,
    enableTimeRestriction,
    enableFileRestriction,
    status,
  };
}

function PolicyView() {
  const [allPolicies, setAllPolicies] = useState<any[]>([]);
  const [searchedPolicies, setSearchedPolicies] = useState<any[]>([]);
  const [selectedPolicy, setSelectedPolicy] = useState<any[]>([]);
  const [reloadPolicy, setReloadPolicy] = useState(false);
  const [openNewPolicy, setOpenNewPolicy] = useState(false);
  const [searchPolicy, setSearchPolicy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(6);
  const [paginatedData, setPaginatedData] = useState<any>([]);
  const totalPages = Math.ceil((allPolicies?.length || 0) / rowsPerPage);
  const searchPolicies = (e: any) => {
    const prefix = e.target.value.toLowerCase();
    setSearchPolicy(prefix);
    setSearchedPolicies(allPolicies);
    setSearchedPolicies((current) => current.filter(
      (policy) => policy.name.toLowerCase().startsWith(prefix) === true,
    ));
    setPaginatedData(allPolicies);
    setPaginatedData((current: any) => current.filter(
      (policy: any) => policy.name.toLowerCase().startsWith(prefix) === true,
    ));
  };
  const handleAddNewPolicy = () => {
    setSelectedPolicy([]);
    setOpenNewPolicy(true);
  };
  useEffect(() => {
    async function getAllPolicies() {
      const token = await getCookie('token');
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-policies`,
          {},
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((response: any) => {
          if (response && response.data && response.data.PolicyData) {
            const policies = response.data.PolicyData;
            const result: any[] = [];
            policies.forEach((policy: any) => {
              const enableIPAddress = policy.ip_based_restriction_feature.enable;
              const enableTimeConstraint = policy.time_based_restriction_feature.enable;
              const enableFileRestriction = policy.file_restriction_feature.enable;
              const policyArray = createData(
                policy.identifier,
                policy._id,
                policy.description,
                enableIPAddress,
                enableTimeConstraint,
                enableFileRestriction,
                enableIPAddress
                  || enableTimeConstraint
                  || enableFileRestriction,
              );
              result.push(policyArray);
            });
            setAllPolicies(result);
            setSearchedPolicies(result);
          }
        })
        .catch(() => {
          toast.error(process.env.REACT_APP_GET_ALL_POLICIES_ERROR);
        });
      setReloadPolicy(false);
    }
    getAllPolicies();
  }, [reloadPolicy]);
  const deletePolicySettings = async (identifierID: string) => {
    if (identifierID === 'default') {
      return;
    }
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/delete-policy-settings`,
        {
          identifierID,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        setReloadPolicy(true);
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_DELETE_POLICY_ERROR);
      });
  };

  const editPolicySettings = async (identifier: string) => {
    const token = await getCookie('token');
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-policies`,
        {},
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((response: any) => {
        if (response && response.data && response.data.PolicyData) {
          response?.data?.PolicyData.forEach((policy: any) => {
            if (policy?.identifier === identifier) {
              setSelectedPolicy(policy);
            }
          });
        }
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_GET_ALL_POLICIES_ERROR);
      });
    setOpenNewPolicy(true);
  };

  const handleNextPage = () => {
    setPage((prevPage: any) => Math.min(prevPage + 1, totalPages - 1));
  };

  const handlePrevPage = () => {
    setPage((prevPage: any) => Math.max(prevPage - 1, 0));
  };

  useEffect(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    setPaginatedData(allPolicies?.slice(start, end) || []);
  }, [allPolicies, page, rowsPerPage]);
  return (
    <MainLayout>
      <Box
        display="flex"
        flexDirection="column"
        my={5}
        marginLeft={5}
        width="95%"
        overflow="hidden"
      >
        <ToastContainer />
        <Box display="flex" width="95%" justifyContent="space-between" p={2}>
          <Box>
            <TextField
              id="input-with-icon-textfield"
              size="small"
              variant="outlined"
              sx={{ marginRight: '3rem' }}
              onChange={searchPolicies}
              value={searchPolicy || ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box>
            <Button
              sx={{
                backgroundColor: '#2D3748',
                color: '#ffffff',
                paddingX: '1rem',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#2D3748',
                  color: '#ffffff',
                },
              }}
              onClick={handleAddNewPolicy}
            >
              Add New Policy
            </Button>
            <AddPolicyModal
              open={openNewPolicy}
              setOpen={setOpenNewPolicy}
              selectedPolicy={selectedPolicy}
              setReloadPolicy={setReloadPolicy}
            />
          </Box>
        </Box>
        <Divider />
        <TableContainer
          component={Paper}
          sx={{ padding: '2rem', height: '65vh', overflow: 'auto' }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Policy Name</StyledTableCell>
                <StyledTableCell>Policy Description</StyledTableCell>
                <StyledTableCell>Restriction Type</StyledTableCell>
                <StyledTableCell sx={{ marginRight: '10px' }}>
                  Actions
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((row: any) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row" size="small">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell>{row.description}</StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" gap="0.5rem">
                      {row.enableIPRestriction ? (
                        <Chip
                          label="IP Restriction"
                          variant="outlined"
                          sx={{ backgroundColor: '#DDF2F4' }}
                        />
                      ) : (
                        ''
                      )}
                      {row.enableTimeRestriction ? (
                        <Chip
                          label="Time Restriction"
                          variant="outlined"
                          sx={{ backgroundColor: '#C6C9D0' }}
                        />
                      ) : (
                        ''
                      )}
                      {row.enableFileRestriction ? (
                        <Chip
                          label="File Restriction"
                          variant="outlined"
                          sx={{ backgroundColor: '#C2D9E1' }}
                        />
                      ) : (
                        ''
                      )}
                      {!row.enableIPRestriction
                      && !row.enableTimeRestriction
                      && !row.enableFileRestriction ? (
                        <Chip
                          label="No Restriction"
                          variant="outlined"
                          sx={{ backgroundColor: '#E7CBA9' }}
                        />
                        ) : (
                          ''
                        )}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" alignItems="center">
                      {row.name === 'default' ? (
                        <Button>
                          <EditIcon sx={{ color: 'gray' }} />
                        </Button>
                      ) : (
                        <Button onClick={() => editPolicySettings(row.name)}>
                          <EditIcon sx={{ color: 'blue' }} />
                        </Button>
                      )}
                      &nbsp;&nbsp;&nbsp;
                      {row.name === 'default' ? (
                        <Button>
                          <DeleteIcon sx={{ color: 'gray' }} />
                        </Button>
                      ) : (
                        <Button onClick={() => deletePolicySettings(row._id)}>
                          <DeleteIcon sx={{ color: 'red' }} />
                        </Button>
                      )}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {searchedPolicies?.length === 0 && (
                <StyledTableRow>
                  <StyledTableCell align="center" />
                  <StyledTableCell align="center" />
                  <StyledTableCell align="left">
                    No Configurations
                  </StyledTableCell>
                  <StyledTableCell align="center" />
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1rem',
              marginRight: '5rem',
            }}
          >
            <Button onClick={handlePrevPage} disabled={page === 0}>
              Previous
            </Button>
            <Button onClick={handleNextPage} disabled={page === totalPages - 1}>
              Next
            </Button>
          </div>
        </TableContainer>
      </Box>
    </MainLayout>
  );
}

export default PolicyView;
