import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserOnboarding from '../../../components/CASBApp/ShopifyNonPlus/UserOnboarding';
import { getCookie, getDraftProxyApplication } from '../../../utils/utility';
import { lightBackgroundButtonStyles } from '../../../styles/button';

interface UserDetails {
  group_name: string;
  username: string;
  password: string;
}

function ShopifyConfiguration() {
  const [userDetails, setUserDetails] = useState<{
    type: string;
    userdetails: UserDetails[];
    enableCASB: boolean;
  }>({
    type: 'sso',
    userdetails: [],
    enableCASB: false,
  });
  const [reload, setReload] = useState(false);
  useEffect(() => {
    async function getDraftedConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
      );
      const users = DraftApplication?.success?.users || [];
      setUserDetails({
        type:
          DraftApplication?.success?.features?.multi_staff === true
            ? 'multistaff'
            : 'sso',
        userdetails: users,
        enableCASB: DraftApplication?.success?.features?.casb,
      });
    }
    getDraftedConfiguration();
  }, [reload]);

  const handleShopifyUserSave = async () => {
    setReload(false);
    const token = await getCookie('token');
    const isEmptyEntry = userDetails.userdetails.some((entry) => Object.values(entry).some((value) => value === ''));
    if (isEmptyEntry) {
      toast.error(process.env.REACT_APP_EMPTY_INPUT_FIELDS);
      return;
    }

    const hasDuplicateUsernames = userDetails.userdetails.some(
      (userObject, index, array) => array
        .slice(0, index)
        .some((prevUser) => prevUser.username === userObject.username),
    );

    const hasDuplicateGroup = userDetails.userdetails.some((user, index) => userDetails.userdetails
      .slice(index + 1)
      .some((otherUser) => user.group_name === otherUser.group_name));

    if (hasDuplicateGroup) {
      toast.error(process.env.REACT_APP_DUPLICATE_GROUPS);
      return;
    } if (hasDuplicateUsernames && userDetails?.type !== 'multistaff') {
      toast.error(process.env.REACT_APP_DUPLICATE_USERNAME);
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-proxy-configuration`,
        {
          user_details: userDetails.userdetails,
          app: process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        setReload(true);
        toast.success('Updated Configuration.');
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_UPDATE_PROXY_CONFIG_ERROR);
      });
  };

  return (
    <Box height="69vh" sx={{ overflow: 'scroll' }}>
      <ToastContainer />
      <UserOnboarding
        userDetails={userDetails}
        setUserDetails={setUserDetails}
      />
      <Box
        display="flex"
        width="100%"
        justifyContent="flex-end"
        borderTop="1px solid #D9D9D9"
        pt={1.2}
      >
        <Button
          sx={lightBackgroundButtonStyles}
          style={{ marginRight: '3rem' }}
          onClick={handleShopifyUserSave}
        >
          Save Configuration
        </Button>
      </Box>
    </Box>
  );
}

export default ShopifyConfiguration;
