import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  getDraftProxyApplication,
  isValidDomain,
  isValidURL,
} from '../../../utils/utility';
import ErrorMessage from '../../../components/ErrorMessage';
import { updateshopifyPlusConfiguration } from '../../../store/features/CreateAuthSourceSlice';

interface BasicSettingsType {
  shopifyPlusStoreName: null | string;
  shopifyPlusDomain: null | string;
  attributeKey: null | string;
  shopifyPlusACSUrl: null | string;
  shopifyPlusEntityUrl: null | string;
  nameIdFormat: null | string;
}

interface ShopifyPlusBasicSettingsProps {
  errorMessage: string;
  setBasicSettingsError: any;
}

function BasicSettings({
  errorMessage,
  setBasicSettingsError,
}: ShopifyPlusBasicSettingsProps) {
  const dispatch = useDispatch();

  /**
   * It gives the list/dropdown of all the allNameIdFormat
   */
  const [allNameIdFormat] = useState<any[]>([
    'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified',
    'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress',
    'urn:oasis:names:tc:SAML:2.0:nameid-format:persistent',
    'urn:oasis:names:tc:SAML:2.0:nameid-format:transient',
  ]);

  const InputFields = [
    'Shopify Store Name*',
    'Shopify Domain*',
    'Group Attribute Key*',
    'Shopify ACS URL*',
    'Shopify Entity URL*',
  ];
  const InputFieldsName = [
    'shopifyPlusStoreName',
    'shopifyPlusDomain',
    'attributeKey',
    'shopifyPlusACSUrl',
    'shopifyPlusEntityUrl',
  ];
  const [basicSettings, setBasicSettings] = useState<BasicSettingsType>({
    shopifyPlusStoreName: null,
    shopifyPlusDomain: null,
    attributeKey: null,
    shopifyPlusACSUrl: null,
    shopifyPlusEntityUrl: null,
    nameIdFormat: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified',
  });
  const [inputErrorMessage, setInputErrorMessage] = useState<BasicSettingsType>(
    {
      shopifyPlusStoreName: null,
      shopifyPlusDomain: null,
      attributeKey: null,
      shopifyPlusACSUrl: null,
      shopifyPlusEntityUrl: null,
      nameIdFormat: null,
    },
  );
  const handleBasicSettingsInput = (event: any) => {
    var value = '';
    event.preventDefault();
    setBasicSettingsError('');
    const { name } = event.target;
    value = event.target.value;
    value = value?.trim();
    switch (name) {
      case 'shopifyPlusDomain':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          shopifyPlusDomain: !isValidDomain(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      case 'shopifyPlusACSUrl':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          shopifyPlusACSUrl: !isValidURL(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      case 'shopifyPlusEntityUrl':
        setInputErrorMessage((prevValue: BasicSettingsType) => ({
          ...prevValue,
          shopifyPlusEntityUrl: !isValidURL(value)
            ? String(process.env.REACT_APP_INVALID_DOMAIN)
            : null,
        }));
        break;
      default:
        break;
    }
    setBasicSettings((prevValue: BasicSettingsType) => ({
      ...prevValue,
      [name]: value,
    }));
  };
  useEffect(() => {
    async function getDraftedShopifyPlusConfiguration() {
      const DraftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_SHOPIFY_PLUS_KEY,
      );
      setBasicSettings({
        shopifyPlusStoreName: DraftApplication?.success?.identifier,
        shopifyPlusDomain: DraftApplication?.success?.organization_domain,
        attributeKey: DraftApplication?.success?.attribute_key,
        shopifyPlusACSUrl: DraftApplication?.success?.acs_url,
        shopifyPlusEntityUrl: DraftApplication?.success?.entity_id_url,
        nameIdFormat: DraftApplication?.success?.name_id_format,
      });
    }
    getDraftedShopifyPlusConfiguration();
  }, []);

  useEffect(() => {
    function updateAuthenticationSourceDetails() {
      dispatch(
        updateshopifyPlusConfiguration({
          type: 'basic-settings',
          data: basicSettings,
        }),
      );
    }
    updateAuthenticationSourceDetails();
  }, [basicSettings]);

  return (
    <Box height="67vh" sx={{ overflow: 'scroll' }}>
      <Box display="flex" flexDirection="row">
        <Box width="40%" display="flex" py={6} px={6} flexDirection="column">
          <Typography variant="h5">Basic Settings</Typography>
          <br />
          <Typography variant="subtitle2" color="#56526B">
            Add new Basic Settings for Shopify Plus Proxy
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="60%"
          px={6}
          py={5}
          gap="1.5rem"
        >
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {InputFields.map((value, index) => (
            <FormControl>
              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                label={value}
                sx={{
                  backgroundColor:
                    inputErrorMessage[
                      InputFieldsName[index] as keyof BasicSettingsType
                    ] === null
                      ? '#F6F6F6'
                      : '#ff8d0024',
                  border: 'none !important',
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                name={InputFieldsName[index]}
                value={
                  basicSettings[
                    InputFieldsName[index] as keyof BasicSettingsType
                  ] || ''
                }
                onChange={handleBasicSettingsInput}
              />
              <Typography variant="caption" color="red">
                {
                  inputErrorMessage[
                    InputFieldsName[index] as keyof BasicSettingsType
                  ]
                }
              </Typography>
            </FormControl>
          ))}
          <Box>
            <Typography variant="caption">Name ID Format</Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              size="small"
              fullWidth
              sx={{ height: '2.2rem' }}
              name="nameIdFormat"
              onChange={handleBasicSettingsInput}
              value={
                basicSettings.nameIdFormat
                || 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified'
              }
            >
              {allNameIdFormat?.map((source: string) => (
                <MenuItem key={source} value={source}>
                  {source}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BasicSettings;
