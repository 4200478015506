import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCookie } from '../../../utils/utility';
import CircularIcon from '../../../components/CircularIcon';
import CircularTick from '../../../components/CircularTick';
import MainLayout from '../../../components/MainLayout';
import ProxyDetails from './ProxyDetails';
import EnableFeatures from './EnableFeatures';

function AddProxy() {
  const [step, setStep] = useState(1);
  const [type, setType] = useState('save');
  const [title, setTitle] = useState('Add Proxy');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const identifier = urlParams.has('identifier')
    ? urlParams.get('identifier')
    : '';
  const path = urlParams.has('path') ? urlParams.get('path') : '';
  useEffect(() => {
    if (identifier && path) {
      setType('update');
    }
  }, []);

  const [proxyInputDetails, setProxyInputDetails] = useState({
    proxyName: null,
    proxyLink: null,
    primaryServerURL: null,
    enableByIP: false,
    id: null,
    IPRestriction: {
      enable: false,
      IPList: [],
      restrictIPs: false,
    },
    LocationBasedRestriction: {
      enable: false,
      LocationList: [],
    },
    CORS: {
      enable: false,
      DomainList: [],
    },
    CachingFeature: {
      enable: false,
      cachingTime: null,
    },
    LoadBalanceFeature: {
      enable: false,
      memberList: [],
    },
    RateLimitingFeature: {
      enable: false,
      maxRequest: 100,
      timeLimit: 5,
      whiteListPath: [],
    },
    CookieDomainRewriteFeature: {
      enable: false,
      CookieDomainPathList: [{ domain: null, path: null }],
    },
    Authentication: {
      enable: false,
      authenticationID: 'Select Authentication',
      redirectAfterAuthentication: null,
    },
    Substitution: {
      enable: false,
      htmlString: [],
      locationHeader: [],
    },
  });
  useEffect(() => {
    async function editProxyDetails() {
      if (identifier !== '' && path !== '') {
        setTitle('Edit Proxy');
        const token = getCookie('token');
        axios
          .post(
            `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-proxy-details`,
            {
              identifier,
              path,
            },
            {
              headers: {
                Authorization: token,
              },
            },
          )
          .then((res) => {
            var domainPathList = [];
            if (
              res?.data?.proxy?.cookie_domain_rewrite_feature
                ?.cookie_path_list
              && res?.data?.proxy?.cookie_domain_rewrite_feature
                ?.cookie_domain_list
            ) {
              for (
                let i = 0;
                i
                < res?.data?.proxy?.cookie_domain_rewrite_feature
                  ?.cookie_domain_list.length;
                i++
              ) {
                domainPathList.push({
                  domain:
                    res?.data?.proxy?.cookie_domain_rewrite_feature
                      ?.cookie_domain_list[i],
                  path:
                    res?.data?.proxy?.cookie_domain_rewrite_feature
                      ?.cookie_path_list[i],
                });
              }
            } else {
              domainPathList = [{ domain: '', path: '' }];
            }
            setProxyInputDetails({
              proxyName: res?.data?.proxy?.identifier,
              proxyLink: res?.data?.proxy?.path,
              primaryServerURL: res?.data?.proxy?.server_url,
              enableByIP: res?.data?.proxy?.proxying_by_ip,
              id: res?.data?.proxy?._id,
              IPRestriction: {
                enable: res?.data?.proxy?.ip_restriction_feature?.enable,
                IPList:
                  res?.data?.proxy?.ip_restriction_feature?.ip_address_list,
                restrictIPs:
                  res?.data?.proxy?.ip_restriction_feature?.restrict_ips,
              },
              LocationBasedRestriction: {
                enable:
                  res?.data?.proxy?.location_based_restriction_feature?.enable,
                LocationList:
                  res?.data?.proxy?.location_based_restriction_feature
                    ?.location_based_restriction_list,
              },
              CORS: {
                enable: res?.data?.proxy?.cors_feature?.enable,
                DomainList: res?.data?.proxy?.cors_feature?.domain_name_list,
              },
              CachingFeature: {
                enable: res?.data?.proxy?.caching_feature?.enable,
                cachingTime: res?.data?.proxy?.caching_feature?.caching_time,
              },
              LoadBalanceFeature: {
                enable: res?.data?.proxy?.load_balancing_feature?.enable,
                memberList:
                  res?.data?.proxy?.load_balancing_feature
                    ?.balancer_member_list,
              },
              RateLimitingFeature: {
                enable: res?.data?.proxy?.rate_limiting_feature?.enable,
                maxRequest:
                  res?.data?.proxy?.rate_limiting_feature?.max_number_requests,
                timeLimit: res?.data?.proxy?.rate_limiting_feature?.time_limit,
                whiteListPath:
                  res?.data?.proxy?.rate_limiting_feature
                    ?.whitelisted_path_list,
              },
              CookieDomainRewriteFeature: {
                enable: res?.data?.proxy?.cookie_domain_rewrite_feature?.enable,
                CookieDomainPathList: domainPathList,
              },
              Authentication: {
                enable: res?.data?.proxy?.authentication_feature?.enable,
                authenticationID:
                  res?.data?.proxy?.authentication_feature?.authentication_id,
                redirectAfterAuthentication:
                  res?.data?.proxy?.authentication_feature
                    ?.redirect_after_authentication,
              },
              Substitution: {
                enable: res?.data?.proxy?.substitution_feature?.enable,
                htmlString:
                  res?.data?.proxy?.substitution_feature
                    ?.substitution_member_list_string,
                locationHeader:
                  res?.data?.proxy?.substitution_feature
                    ?.substitution_member_list_location,
              },
            });
          })
          .catch(() => {
            toast.error(process.env.REACT_APP_ADD_PROXY_ERROR);
          });
      }
    }
    editProxyDetails();
  }, []);

  return (
    <MainLayout>
      <Box width="100%">
        <ToastContainer />
        <Box
          display="flex"
          width="96%"
          px={3}
          py={5}
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid #D9D9D9"
        >
          <Typography variant="h6">{title}</Typography>
          <Box display="flex" sx={{ gap: '1rem' }}>
            <Box display="flex" sx={{ gap: '0.5rem' }}>
              {title === 'Edit Proxy' ? (
                <CircularTick />
              ) : (
                <CircularIcon number={1} step={step} />
              )}

              <Typography variant="subtitle2">{title}</Typography>
            </Box>
            <Typography variant="subtitle2">-</Typography>
            <Box display="flex" sx={{ gap: '0.5rem' }}>
              {title === 'Edit Proxy' ? (
                <CircularTick />
              ) : (
                <CircularIcon number={2} step={step} />
              )}
              <Typography variant="subtitle2">Enable Features</Typography>
            </Box>
          </Box>
        </Box>
        <Box width="100%">
          {step === 1 ? (
            <ProxyDetails
              step={step}
              setStep={setStep}
              proxyInputDetails={proxyInputDetails}
              setProxyInputDetails={setProxyInputDetails}
              type={type}
              setType={setType}
            />
          ) : (
            <EnableFeatures
              step={step}
              setStep={setStep}
              proxyInputDetails={proxyInputDetails}
              setProxyInputDetails={setProxyInputDetails}
              type={type}
            />
          )}
        </Box>
      </Box>
    </MainLayout>
  );
}

export default AddProxy;
