import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import SyncIcon from '@mui/icons-material/Sync';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MiniOrangeLogo from './MiniOrangeLogo';
import {
  getAllTenants,
  userSwitchTenant,
  getActiveTenant,
  getUser,
} from '../utils/utility';

interface MainLayoutProps {
  children: React.ReactElement;
}

export default function MainLayout({
  children,
}: MainLayoutProps): React.ReactElement {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [allTenants, setAllTenants] = useState<any[]>([]);
  const [casbSettings, setCasbSettings] = React.useState(false);
  const [selectedTenant, setSelectedTenant] = useState('');
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  const toggleCASBSettings = (): any => {
    setCasbSettings(!casbSettings);
  };

  const toggleDrawer = (open: boolean) => () => {
    setState({ ...state, left: open });
  };

  const handleTenantSwitch = (event: SelectChangeEvent) => {
    const tenantName = event.target.value as string;
    setSelectedTenant(tenantName);
    allTenants.forEach(async (tenant) => {
      if (tenantName === tenant.tenant_name) {
        const tenantID = tenant._id;
        await userSwitchTenant({ tenantID }).then(async (response) => {
          const timestamp = new Date().getTime();
          const exp = timestamp + 60 * 60 * 24 * 1000 * 7;
          document.cookie = `token=${response};expires=${exp};path=/`;
          await window.location.reload();
        });
      }
    });
  };

  useEffect(() => {
    async function getAllTenantsInfo() {
      getAllTenants().then((data: any) => {
        setAllTenants(data);
      });
    }
    async function getUserDetails() {
      const user: any = await getUser();
      setEmail(user.email);
    }
    getAllTenantsInfo();
    getUserDetails();
  }, []);

  useEffect(() => {
    async function getCurrentActiveTenant() {
      const currentActiveTenant = getActiveTenant();
      currentActiveTenant.then((result) => {
        allTenants.forEach((tenant) => {
          if (tenant._id === result) {
            setSelectedTenant(tenant.tenant_name);
          }
        });
      });
    }
    getCurrentActiveTenant();
  }, [allTenants]);

  const list = (): any => (
    <Box
      sx={{
        width: 250,
        color: '#9A9A9A',
      }}
      role="presentation"
    >
      <List sx={{ height: '100%' }}>
        <ListItem
          disablePadding
          sx={{
            display: { md: 'none' },
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
          }}
        >
          <ListItemButton sx={{ justifyContent: 'end !important' }}>
            <MenuIcon onClick={toggleDrawer(false)} />
          </ListItemButton>
        </ListItem>
        <Link
          to="/dashboard"
          style={{ textDecoration: 'none', color: '#9A9A9A' }}
        >
          <ListItem
            key="Dashboard"
            disablePadding
            sx={{
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              backgroundColor:
                window.location.pathname === '/dashboard' ? '#F7F3FE' : 'white',
              borderLeft:
                window.location.pathname === '/dashboard'
                  ? '6px solid #4339F2'
                  : '',
            }}
          >
            <ListItemButton href="/dashboard">
              <Typography variant="subtitle2" color="#505050">
                Dashboard
              </Typography>
            </ListItemButton>
          </ListItem>
        </Link>

        <ListItem
          key="CASB Settings"
          disablePadding
          sx={{
            paddingTop: '0.5rem',
            paddingBottom: '0.5rem',
            backgroundColor:
              window.location.pathname === '/configure/casb/google'
              || window.location.pathname === '/edit/casb/google'
              || window.location.pathname === '/configure/casb/shopify'
              || window.location.pathname === '/edit/casb/shopify'
              || window.location.pathname === '/configure/casb/shopifyplus'
              || window.location.pathname === '/edit/casb/shopifyplus'
                ? '#F7F3FE'
                : 'white',
            borderLeft:
              window.location.pathname === '/configure/casb/google'
              || window.location.pathname === '/edit/casb/google'
              || window.location.pathname === '/configure/casb/shopify'
              || window.location.pathname === '/edit/casb/shopify'
              || window.location.pathname === '/configure/casb/shopifyplus'
              || window.location.pathname === '/edit/casb/shopifyplus'
                ? '6px solid #4339F2'
                : '',
          }}
        >
          <ListItemButton
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            onClick={toggleCASBSettings}
          >
            <Typography variant="subtitle2" color="#505050">
              CASB Settings
            </Typography>
            {casbSettings ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </ListItemButton>
        </ListItem>
        {casbSettings && (
          <>
            <Link
              to="/configure/casb/atlassian"
              style={{ textDecoration: 'none', color: '#9A9A9A' }}
            >
              <ListItem key="Atlassian" disablePadding>
                <ListItemButton>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    color="textPrimary"
                  >
                    Atlassian
                  </Typography>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to="/configure/casb/google"
              style={{ textDecoration: 'none', color: '#9A9A9A' }}
            >
              <ListItem key="Google" disablePadding>
                <ListItemButton>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    color="textPrimary"
                  >
                    Google
                  </Typography>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to="/configure/casb/shopify"
              style={{ textDecoration: 'none', color: '#9A9A9A' }}
            >
              <ListItem key="Shopify" disablePadding>
                <ListItemButton>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    color="textPrimary"
                  >
                    Shopify
                  </Typography>
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to="/configure/casb/shopifyplus"
              style={{ textDecoration: 'none', color: '#9A9A9A' }}
            >
              <ListItem key="Shopify" disablePadding>
                <ListItemButton>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    color="textPrimary"
                  >
                    Shopify Plus
                  </Typography>
                </ListItemButton>
              </ListItem>
            </Link>
          </>
        )}
        <Link
          to="/manage-policy"
          style={{ textDecoration: 'none', color: '#9A9A9A' }}
        >
          <ListItem
            key="Manage Policy"
            disablePadding
            sx={{
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              backgroundColor:
                window.location.pathname === '/manage-policy'
                  ? '#F7F3FE'
                  : 'white',
              borderLeft:
                window.location.pathname === '/manage-policy'
                  ? '6px solid #4339F2'
                  : '',
            }}
          >
            <ListItemButton>
              <Typography variant="subtitle2" color="#505050">
                Manage Policy
              </Typography>
            </ListItemButton>
          </ListItem>
        </Link>

        <Link
          to="/configure/authentication-source"
          style={{ textDecoration: 'none', color: '#9A9A9A' }}
        >
          <ListItem
            key="Manage Authentication Source"
            disablePadding
            sx={{
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              backgroundColor:
                window.location.pathname === '/configure/authentication-source'
                  ? '#F7F3FE'
                  : 'white',
              borderLeft:
                window.location.pathname === '/configure/authentication-source'
                  ? '6px solid #4339F2'
                  : '',
            }}
          >
            <ListItemButton>
              <Typography variant="subtitle2" color="#505050">
                Authentication Source
              </Typography>
            </ListItemButton>
          </ListItem>
        </Link>

        <Link
          to="/configure/proxy"
          style={{ textDecoration: 'none', color: '#9A9A9A' }}
        >
          <ListItem
            key="Manage Policy"
            disablePadding
            sx={{
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              backgroundColor:
                window.location.pathname === '/configure/proxy'
                  ? '#F7F3FE'
                  : 'white',
              borderLeft:
                window.location.pathname === '/configure/proxy'
                  ? '6px solid #4339F2'
                  : '',
            }}
          >
            <ListItemButton>
              <Typography variant="subtitle2" color="#505050">
                Secure Cloud App
              </Typography>
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Box>
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const logoutUser = () => {
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    navigate('/login');
  };

  return (
    <Box sx={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <AppBar
        style={{
          position: 'fixed',
          background: '#ffffff',
          borderBottom: '1px solid #D9D9D9',
          boxShadow: 'none',
          paddingTop: '0.25rem',
          paddingBottom: '0.25rem',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box display={{ xs: 'flex', md: 'none' }}>
            <MenuIcon
              sx={{ color: '#777876' }}
              height={60}
              onClick={toggleDrawer(true)}
            />
            <Drawer anchor="left" open={state.left}>
              {list()}
            </Drawer>
          </Box>
          <MiniOrangeLogo />
          <Box
            display={{ xs: 'none', md: 'flex' }}
            alignItems="center"
            gap="1rem"
            px="2rem"
          >
            <FormControl sx={{ width: 220 }}>
              <Select
                value={selectedTenant !== '' ? selectedTenant : 'default'}
                renderValue={(value) => (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <SyncIcon sx={{ color: 'white' }} />
                    &nbsp;Switch Tenant :&nbsp;&nbsp;
                    {selectedTenant !== '' ? value : selectedTenant}
                  </span>
                )}
                sx={{
                  height: '2.2rem',
                  width: '120',
                  backgroundColor: '#4F46F3',
                  color: 'white',
                  border: 'none',
                  fontSize: '0.8rem',
                }}
                onChange={handleTenantSwitch}
                inputProps={{ IconComponent: () => null }}
              >
                {allTenants.map((tenant) => (
                  <MenuItem value={tenant.tenant_name} key={tenant.tenant_name}>
                    {tenant.tenant_name}
                  </MenuItem>
                ))}
                <Link to="/add/tenant" style={{ textDecoration: 'none' }}>
                  <MenuItem
                    value="Add New Tenant"
                    key="Add New Tenant"
                    sx={{ textDecoration: 'none' }}
                  >
                    View All Tenants
                  </MenuItem>
                </Link>
              </Select>
            </FormControl>
            <Button aria-describedby={id} onClick={handleClick}>
              <PersonIcon sx={{ color: '#4B475C' }} />
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box width="15rem" py={1}>
                <Box
                  width="100%"
                  py={3}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <AccountCircleIcon
                    sx={{
                      color: '#4B475C',
                      width: '10rem !important',
                      height: '10rem !important',
                    }}
                  />
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography fontSize="small">{email}</Typography>
                  <Button onClick={() => navigate('/settings')}>
                    Tenant Settings
                  </Button>
                  <Button onClick={logoutUser}>Logout</Button>
                </Box>
              </Box>
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
      <Box display="flex" marginTop="73px">
        <Box
          display={{ xs: 'none', md: 'block' }}
          height="calc(100vh - 75px)"
          sx={{ width: { md: 'auto' } }}
          borderRight="1px solid #D9D9D9"
        >
          {list()}
        </Box>
        <Box sx={{ width: { md: '100%' } }} overflow="hidden">
          {children}
        </Box>
      </Box>
    </Box>
  );
}
