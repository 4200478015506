import axios from 'axios';
import { toast } from 'react-toastify';
import jwt from 'jwt-decode';
import * as KJUR from 'jsrsasign';

export function getCookie(name: string) {
  const pair = document.cookie
    .split('; ')
    .find((x) => x.startsWith(`${name}=`));
  if (pair) return pair.split('=')[1];
  return '';
}

export async function getUser() {
  const token = getCookie('token');
  if (!token) {
    return null;
  }
  const user = jwt(token);
  return user;
}

export function isValidIPAddress(ip: string) {
  const ipRegex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
  return ipRegex.test(ip);
}

export async function getActiveTenant() {
  const user = await getUser();
  const currentActiveTenantID = user.current_active_tenant_id;
  return currentActiveTenantID;
}

export const isValidURL = (url: string): boolean => {
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return urlRegex.test(url);
};

export const isValidDomain = (domain: string): boolean => {
  const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return domainRegex.test(domain);
};
export function isPEMCertificateValid(pemCertificate: string) {
  const expectedHeader = '-----BEGIN CERTIFICATE-----';
  const expectedFooter = '-----END CERTIFICATE-----';
  const startsWithHeader = pemCertificate.startsWith(expectedHeader);
  const endsWithFooter = pemCertificate.endsWith(expectedFooter);

  return startsWithHeader && endsWithFooter;
}

export async function generateMetaData(authenticationSourceDetails: any) {
  const token = await getCookie('token');
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-sp-metadata`,
        {
          AuthenticationIdentifier: (authenticationSourceDetails as any)
            .authenticationName,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((response) => {
        resolve({ success: response.data });
      })
      .catch((err) => {
        const { message } = err.response.data;
        reject(message);
      });
  });
}

export async function addAuthenticationSource(
  AuthenticationSourceDetails: any,
) {
  const token = await getCookie('token');
  return new Promise((resolve, reject) => {
    if (
      !AuthenticationSourceDetails
      || !AuthenticationSourceDetails.authenticationName
      || !AuthenticationSourceDetails.issuer
      || !AuthenticationSourceDetails.loginURL
      || !AuthenticationSourceDetails.logoutURL
      || !AuthenticationSourceDetails.bindingType
      || !AuthenticationSourceDetails.X509Certificate
    ) {
      reject(new Error('Please fill all required fields.'));
    } else {
      const cert = new KJUR.X509();
      if (isPEMCertificateValid(AuthenticationSourceDetails.X509Certificate)) {
        try {
          cert.readCertPEM(AuthenticationSourceDetails.X509Certificate);
          const issuer = cert.getIssuerString();
          if (!issuer) {
            reject(
              new Error('Certificate may be corrupt or in an incorrect format.'),
            );
          }
        } catch (error) {
          reject(
            new Error('Certificate may be corrupt or in an incorrect format.'),
          );
        }
      } else {
        reject(new Error('Invalid or missing PEM header or footer.'));
      }
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/add-authentication-source`,
          {
            data: AuthenticationSourceDetails,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          resolve({ success: res?.data?.message });
        })
        .catch((error) => {
          reject(new Error(error?.response?.data?.message));
        });
    }
  });
}

export async function updateAuthenticationSource(
  AuthenticationSourceDetails: any,
) {
  const token = await getCookie('token');
  return new Promise((resolve, reject) => {
    if (
      !AuthenticationSourceDetails
      || !AuthenticationSourceDetails.authenticationName
      || !AuthenticationSourceDetails.issuer
      || !AuthenticationSourceDetails.loginURL
      || !AuthenticationSourceDetails.bindingType
      || !AuthenticationSourceDetails.X509Certificate
    ) {
      reject(new Error('Please fill all required fields.'));
    } else {
      const cert = new KJUR.X509();
      if (isPEMCertificateValid(AuthenticationSourceDetails.X509Certificate)) {
        try {
          cert.readCertPEM(AuthenticationSourceDetails.X509Certificate);
          const issuer = cert.getIssuerString();
          if (!issuer) {
            reject(
              new Error('Certificate may be corrupt or in an incorrect format.'),
            );
          }
        } catch (error) {
          reject(
            new Error('Certificate may be corrupt or in an incorrect format.'),
          );
        }
      } else {
        reject(new Error('Invalid or missing PEM header or footer.'));
      }
      axios
        .post(
          `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-authentication-source`,
          {
            data: AuthenticationSourceDetails,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
        .then((res) => {
          resolve({ success: res?.data?.message });
        })
        .catch((error) => {
          const message = error?.response?.data?.message || error?.data?.message;
          reject(new Error(message));
        });
    }
  });
}

export async function getDraftProxyApplication(app: any | String) {
  const url = 'get-draft-proxy-configuration';
  const token = await getCookie('token');
  return new Promise((resolve) => {
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/${url}`,
        {
          app,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((response) => {
        resolve({ success: response.data?.ProxyData });
      })
      .catch(() => {
        toast.error(process.env.REACT_APP_GET_DRAFT_APP_ERROR);
      });
  });
}

export async function getAllTenants() {
  const token = await getCookie('token');
  return new Promise((resolve, reject) => {
    const axiosConfig = {
      method: 'GET',
      url: `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-all-tenants`,
      params: {
        token,
      },
    };

    axios(axiosConfig)
      .then((response) => {
        resolve(response.data.tenant_list);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

interface userSwitchTenantProps {
  tenantID: string;
}

export async function userSwitchTenant({ tenantID }: userSwitchTenantProps) {
  const token = await getCookie('token');
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/switch-user-tenant`,
        {
          tenantID,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((response: any) => {
        resolve(response.data.token);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

interface handleTenantSwitchProps {
  tenantName: string;
}

export function handleTenantSwitch({ tenantName }: handleTenantSwitchProps) {
  return new Promise((resolve, reject) => {
    getAllTenants()
      .then((allTenants: any) => {
        allTenants.forEach(async (tenant: any) => {
          if (tenantName === tenant.tenant_name) {
            const tenantID = tenant._id;
            await userSwitchTenant({ tenantID }).then(async (response) => {
              const timestamp = new Date().getTime();
              const exp = timestamp + 60 * 60 * 24 * 1000 * 7;
              document.cookie = `token=${response};expires=${exp};path=/`;
              await window.location.reload();
            });
          }
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function currentActiveTenantDetails() {
  const token = await getCookie('token');
  return new Promise((resolve) => {
    axios
      .post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-current-tenant-details`,
        {},
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then((res) => {
        resolve(res?.data?.response);
      })
      .catch(() => {
        toast.error("Couldn't fetch current tenant details");
      });
  });
}

export function DomainDetails() {
  return new Promise((resolve, reject) => {
    getUser()
      .then(async (user) => {
        const token = await getCookie('token');
        axios
          .post(
            `${process.env.REACT_APP_NODE_BACKEND_URL}/api/get-domain-details`,
            {
              user,
              token,
            },
          )
          .then((res) => {
            resolve(res?.data?.response);
          })
          .catch((error) => {
            // handle error
            console.error('AxiosError:', error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function isUserLoggedIn() {
  return new Promise((resolve, reject) => {
    getUser()
      .then((user) => {
        axios
          .post(
            `${process.env.REACT_APP_NODE_BACKEND_URL}/api/customer-login-status`,
            {
              user,
            },
          )
          .then((res) => {
            resolve(res?.data?.flag);
          })
          .catch(() => {
            toast.error(process.env.REACT_APP_CUSTOMER_LOGIN_STATUS_ERROR);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const testSAMLConnection = async (
  authenticationID: string,
  appName: string,
) => {
  const user = await getUser();
  const url = `${process.env.REACT_APP_NODE_BACKEND_URL}/backend/${user?.customer_id}/${user?.current_active_tenant_id}/${authenticationID}/samlllogin?app=${appName}&RelayState=test_configuration`;
  window.open(url, 'sharer', 'toolbar=0,status=0,width=700,height=500');
};
