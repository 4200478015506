import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateshopifyNonPlusConfiguration } from '../../../store/features/CreateAuthSourceSlice';
import { getDraftProxyApplication } from '../../../utils/utility';
import UserOnboarding from '../../../components/CASBApp/ShopifyNonPlus/UserOnboarding';

interface UserSettingsType {
  type: null | string;
  userdetails: { group_name: string; username: string; password: string }[];
}

function ManageGroup() {
  const dispatch = useDispatch();
  const [userSettings, setUserSettings] = useState<UserSettingsType>({
    type: 'sso',
    userdetails: [
      {
        group_name: 'default',
        username: '',
        password: '',
      },
    ],
  });
  useEffect(() => {
    function updateGroupDetails() {
      dispatch(
        updateshopifyNonPlusConfiguration({
          type: 'user-group-settings',
          data: userSettings,
        }),
      );
    }
    updateGroupDetails();
  }, [userSettings]);
  useEffect(() => {
    async function getDraftApplication() {
      const draftApplication: any = await getDraftProxyApplication(
        process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY,
      );
      setUserSettings((prevSettings) => ({
        ...prevSettings,
        type:
          draftApplication?.success?.features?.sso === true
            ? 'sso'
            : 'multistaff',
      }));
    }
    getDraftApplication();
  }, []);

  return (
    <Box height="67vh" sx={{ overflow: 'scroll' }}>
      <UserOnboarding userDetails={userSettings} setUserDetails={setUserSettings} />
    </Box>
  );
}

export default ManageGroup;
