import React, { useState } from 'react';
import {
  Box, Typography, Switch, TextField, Button,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

interface Domain {
  domain: string;
  path: string;
}

interface CookieDomainRewriteProps {
  proxyInputDetails: any;
  setProxyInputDetails: Function;
}

function CookieDomainRewrite({
  proxyInputDetails,
  setProxyInputDetails,
}: CookieDomainRewriteProps) {
  const [domains, setDomains] = useState<Domain[]>(
    proxyInputDetails?.CookieDomainRewriteFeature?.CookieDomainPathList || [
      { domain: '', path: '' },
    ],
  );
  const addFormFields = () => {
    setDomains([...domains, { domain: '', path: '' }]);
  };
  const removeFields = (index: number) => {
    const data = [...domains];
    data.splice(index, 1);
    setDomains(data);
  };
  const handleChange = (index: number, event: any) => {
    const { name, value } = event.target;
    const data = [...domains];
    data[index][name as keyof Domain] = value;
    setDomains(data);
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      CookieDomainRewriteFeature: {
        ...prevValue.CookieDomainRewriteFeature,
        CookieDomainPathList: data,
      },
    }));
  };
  const handleCookieDomainToggle = (event: any) => {
    setProxyInputDetails((prevValue: any) => ({
      ...prevValue,
      CookieDomainRewriteFeature: {
        ...prevValue.CookieDomainRewriteFeature,
        enable: event.target.checked,
      },
    }));
  };
  return (
    <Box
      width="90%"
      display="flex"
      flexDirection="column"
      sx={{ gap: '0.5rem' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ gap: '0.5rem' }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">Cookie Domain Rewrite</Typography>
          <Switch
            onChange={handleCookieDomainToggle}
            checked={proxyInputDetails?.CookieDomainRewriteFeature?.enable}
          />
        </Box>
        <Typography variant="caption">
          Cookie Domain Rewrite feature is used to replace a cookie from the
          primary server domain.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="0.75rem">
        <Box>
          <Button onClick={addFormFields}>+ Add Cookie Domain</Button>
        </Box>
        <Box display="flex" flexDirection="column">
          {domains.map((element: any, index: number) => (
            <Box display="flex" sx={{ gap: '0.5rem', padding: '0.25rem' }}>
              <TextField
                id="outlined-basic"
                size="small"
                name="domain"
                label="eg. example.com"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: 200,
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                value={element.domain || ''}
                onChange={(event) => handleChange(index, event)}
              />
              <TextField
                id="outlined-basic"
                size="small"
                name="path"
                label="eg. example.com"
                variant="outlined"
                sx={{
                  backgroundColor: '#F6F6F6',
                  border: 'none !important',
                  width: 200,
                }}
                inputProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                value={element.path || ''}
                onChange={(event) => handleChange(index, event)}
              />
              <RemoveCircleIcon
                fontSize="large"
                sx={{ color: '#E34614', marginLeft: '2rem' }}
                onClick={() => removeFields(index)}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default CookieDomainRewrite;
